import React, { useState, useRef } from "react"
import { InterviewItem } from "./types"

declare global {
  interface Window {
    dataLayer: any
  }
}

type Props = {
  data: InterviewItem
  handleChange: (id, value) => void
  initialValue?: string
}

const today = new Date()
const START_YEAR = 1940
const END_YEAR = today.getFullYear()

interface date {
  year?: number
  month?: number
}

const FormDate: React.FC<Props> = (props) => {
  const [date, setDate] = useState<date>({})
  const monthInput = useRef<HTMLSelectElement>(null)

  const yearArray = Array.from(
    Array(END_YEAR - START_YEAR + 1),
    (_, i) => i + START_YEAR
  ).reverse()
  const monthArray = Array.from(Array(12), (_, i) => i + 1)
  const [inputFlg, setInputFlg] = useState(false)

  const handleChange = (e) => {
    if (!inputFlg) {
      window.dataLayer.push({
        event: "item_input",
        itemTitle: props.data.title,
      })
      setInputFlg(true)
    }
    const value = e.target.value
    const id = props.data.id
    const type = e.target.getAttribute("data-type")

    const new_date = { ...date, ...{ [type]: value } }
    setDate(new_date)

    const new_month = new_date.month ? new_date.month : ""
    const new_year = new_date.year ? new_date.year : ""
    const date_string = `${new_year}/${new_month}`
    props.handleChange(id, date_string)

    console.log("date", date_string)
  }

  return (
    <>
      <div className="WebInterview__input-row">
        <select
          className="WebInterview__input-small _year"
          data-type="year"
          inputMode="numeric"
          value={date.year}
          onChange={handleChange}
        >
          <option value="" hidden>
            --
          </option>
          {yearArray.map((year) => {
            return (
              <option value={year.toString()} key={`year_${year}`}>
                {year}
              </option>
            )
          })}
        </select>
        <span className="WebInterview__input-unit">年</span>
        <select
          className="WebInterview__input-small"
          ref={monthInput}
          data-type="month"
          inputMode="numeric"
          value={date.month}
          onChange={handleChange}
        >
          <option value="" hidden>
            --
          </option>
          {monthArray.map((month) => {
            return (
              <option value={month.toString()} key={`month_${month}`}>
                {month}
              </option>
            )
          })}
        </select>
        <span className="WebInterview__input-unit">月</span>
      </div>
    </>
  )
}

export default FormDate
