import React from "react"
import { Button, Form, Alert } from "reactstrap"

type Props = {
  onSubmitHandler: any
  errorMessage: string | null
  submitting: boolean
}

const ReservationFormButton = ({
  onSubmitHandler,
  errorMessage,
  submitting,
}: Props): JSX.Element => {
  return (
    <Form className="mt-4" onSubmit={onSubmitHandler}>
      {errorMessage && <Alert color="warning">{errorMessage}</Alert>}
      <Button
        type="submit"
        block
        className="mt-4 text-white text-white btn-theme-fci font-weight-bold border-theme-fci font-size-double height-6 box-shadow-bottom"
        disabled={submitting}
      >
        時間を確定する
      </Button>
    </Form>
  )
}

export default ReservationFormButton
