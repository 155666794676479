import React from "react"

const DateUnable: React.FC = () => {
  return (
    <div className="schedulable-calender__state--unable border-grey-top py-2">
      <i className="fa fa-times"></i>
    </div>
  )
}

export default DateUnable
