import React from "react"
import { Button } from "reactstrap"
import moment from "moment"

import { SchedulableTime as SchedulableTimeModel } from "../../types"

type Props = {
  schedulableTime: SchedulableTimeModel
  onSelectTime: () => void
}

const SchedulableTime: React.FC<Props> = (props) => {
  const { schedulableTime } = props

  const onClick = () => {
    props.onSelectTime()
  }

  const renderTitle = () => {
    const startTime = moment(schedulableTime.startAt).format("H:mm")
    return <>{startTime}</>
  }

  return schedulableTime.isSchedulable ? (
    <Button
      outline
      color="main"
      block
      className="py-3 my-1 font-weight-bold"
      onClick={onClick}
    >
      {renderTitle()}
    </Button>
  ) : (
    <Button
      outline
      color="main"
      block
      className="py-3 my-1 text-black-50"
      disabled
    >
      {renderTitle()}
    </Button>
  )
}

export default SchedulableTime
