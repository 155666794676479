import React from "react"
import { RouteComponentProps } from "react-router-dom"
import {
  CardBody,
  CardTitle,
  Col,
  Row,
  CardSubtitle,
  CardText,
} from "reactstrap"
import moment from "moment"

const ThanksCounselingAppointmentPage = (props: RouteComponentProps<{}>) => {
  const query = new URLSearchParams(props.location.search)

  const type = query.get("type")
  const startAt = moment(query.get("start_at"), "YYYYMMDDHHmm")

  // クエリ文字列にend_atがない場合は20分診療のリリース前にアクセスしたユーザーなのでend_atを10分後に設定する
  // TODO: 20分診療のリリース後、この処理は見直す
  const maybeEndAt = query.get("end_at")
  const endAt = maybeEndAt
    ? moment(maybeEndAt, "YYYYMMDDHHmm")
    : startAt.clone().add(10, "minutes")

  return (
    <Row className="mt-4">
      <Col
        sx={12}
        sm={{ size: 10, offset: 1 }}
        md={{ size: 8, offset: 2 }}
        lg={{ size: 6, offset: 3 }}
      >
        <CardBody className="text-center">
          <CardTitle tag="h5" className="font-weight-bold text-theme-blue mb-3">
            ご予約ありがとうございます
          </CardTitle>
          <CardSubtitle
            tag="h5"
            className="mb-2 font-weight-bold text-theme-blue mb-3"
          >
            【予約時間】
          </CardSubtitle>
          <CardText className="mb-3 h5">
            {startAt.format("MM月DD日 (ddd) HH:mm")}~{endAt.format("HH:mm")}
          </CardText>
          <p className="mb-4">
            ご予約に関するご連絡（03-6630-5212）をさせていただくことがございます。
          </p>
          <CardText className="mb-3 h5 text-theme-blue border-theme-blue border-content">
            ご利用の流れ
          </CardText>
          <Row className="mt-4 justify-center">
            <Col>
              <img
                src={require("assets/smartphone_touch_icon_with_background.png")}
                alt="スマートフォン"
                className="thanks-counseling-img-css"
              ></img>
            </Col>
            <Col className="mx-width-52">
              <label className="mb-3 h5 text-theme-blue">
                {type === "counseling_by_doctor"
                  ? "①無料相談"
                  : "①無料カウンセリング"}
              </label>
              <p>
                時間になりましたら、予約確定メールに送付のURLよりビデオ通話へのアクセスをお願い申し上げます。
              </p>
            </Col>
          </Row>
          <div className="mt-3">
            <img src={require("assets/arrow_down_icon.png")} alt="下へ矢印" />
          </div>
          <Row className="mt-4 justify-center">
            <Col>
              <img
                src={require("assets/user_icon_with_background.png")}
                alt="ユーザー"
                className="thanks-counseling-img-css"
              />
            </Col>
            <Col className="mx-width-52">
              <label className="mb-3 h5 text-theme-blue">②診療・処方</label>
              <p>医師と診療をしていただき、お薬を処方いたします。</p>
            </Col>
          </Row>
          <div className="mt-3">
            <img src={require("assets/arrow_down_icon.png")} alt="下へ矢印" />
          </div>
          <Row className="mt-4 justify-center">
            <Col>
              <img
                src={require("assets/smartphone_icon_with_background.png")}
                alt="スマートフォン"
                className="thanks-counseling-img-css"
              ></img>
            </Col>
            <Col className="mx-width-52">
              <label className="mb-3 h5 text-theme-blue">③購入手続き</label>
              <p>
                診療完了後、購入案内が届きますので、そちらからご購入手続きをお願い致します。
              </p>
            </Col>
          </Row>
          <div className="mt-3">
            <img src={require("assets/arrow_down_icon.png")} alt="下へ矢印" />
          </div>
          <Row className="mt-4 justify-center">
            <Col>
              <img
                src={require("assets/truck_icon_with_background.png")}
                alt="デリバリー"
                className="thanks-counseling-img-css"
              ></img>
            </Col>
            <Col className="mx-width-52">
              <label className="mb-3 h5 text-theme-blue">④お薬の到着</label>
              <p>
                お薬のご購入手続き後、クロネコヤマトのネコポス便にてポスト投函でおおよそ3日前後でのお届けとなります。
              </p>
            </Col>
          </Row>
          <div className="mt-3">
            <img src={require("assets/arrow_down_icon.png")} alt="下へ矢印" />
          </div>
          <Row className="mt-4 justify-center">
            <Col>
              <img
                src={require("assets/calendar_icon_with_background.png")}
                alt="カレンダー"
                className="thanks-counseling-img-css"
              />
            </Col>
            <Col className="mx-width-52">
              <label className="mb-3 h5 text-theme-blue">
                ⑤２ヶ月目以降について
              </label>
              <p>
                初回の処方から20日後に再診案内のメールが届きますので、診療のご予約をお願いいたします。ご予約後は再診を受けていただき、問題がなければ再度処方となります。
              </p>
            </Col>
          </Row>
        </CardBody>
      </Col>
    </Row>
  )
}

export default ThanksCounselingAppointmentPage
