import React from "react"
import ReactDOM from "react-dom"
import moment from "moment"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import App from "./App"

moment.locale("ja")

document.addEventListener("DOMContentLoaded", () => {
  const el = document.getElementById("app")
  if (el !== null) {
    const config = JSON.parse(el.dataset.config || "{}")
    ReactDOM.render(<App config={config} />, el)
  }
})

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.RAILS_ENV,
  tracesSampleRate: 0.3,
})
