import React, { useState, useEffect } from "react"
import { Col, Row } from "reactstrap"
import moment from "moment"

import SelectDate from "./SelectDate"
import type { ScheduleType } from "../../types"
import {
  SchedulableDate as SchedulableDateModel,
  SchedulableTime,
} from "../../types"
import SchedulableCalenderCounseling from "./SchedulableCalenderCounseling"

type Props = {
  type: ScheduleType
  onSelectTime: (date: Date) => void
}

type SchedulableDateResponse = {
  schedulable_times: any[]
}

const SelectSchedule: React.FC<Props> = (props) => {
  const { type } = props
  const [currentDate, setCurrentDate] = useState<Date>(
    moment().startOf("day").toDate()
  )
  const [schedulableDates, setSchedulableDates] = useState<
    SchedulableDateModel[]
  >([])

  const refresh = () => {
    console.log("---SchedulableDateResponse--")
    console.log(type)
    ;(async () => {
      const res = await fetch(
        `/api/schedulable_times?type=${type}&date=${moment(currentDate).format(
          "YYYY-MM-DD"
        )}`
      )
      const json = (await res.json()) as SchedulableDateResponse
      const newSchedulableDates = json.schedulable_times.map(
        buildSchedulableDate
      )
      setSchedulableDates(newSchedulableDates)
    })()
  }

  const buildSchedulableDate = (data: any) => {
    const times = data.times.map((time) => buildSchedulableTime(time))
    return {
      date: data.date,
      times: times,
    } as SchedulableDateModel
  }

  const buildSchedulableTime = (data: any) => {
    const startAt = moment(data.start_at).toDate()
    const endAt = moment(data.end_at).toDate()
    return {
      startAt: startAt,
      endAt: endAt,
      isSchedulable: data.is_schedulable,
    } as SchedulableTime
  }

  const onChangeDate = (date) => {
    setCurrentDate(date)
  }

  useEffect(() => {
    refresh()
  }, [currentDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderSchedulableTimesCounseling = () => {
    return (
      <Row>
        <Col xs={12}>
          <SelectDate date={currentDate} onChangeDate={onChangeDate} />
        </Col>
        <SchedulableCalenderCounseling
          schedulableDates={schedulableDates}
          refresh={refresh}
          onSelectTime={props.onSelectTime}
        />
      </Row>
    )
  }

  return renderSchedulableTimesCounseling()
}

export default SelectSchedule
