import React, { useState, useEffect } from "react"
import { Alert, Button, Form, FormGroup, Input, Label } from "reactstrap"

import validation from "./validation"
import { Reservation, ValidationError } from "./types"

type Props = {
  startAt: Date
}

const ReservationForm: React.FC<Props> = (props) => {
  const { startAt } = props
  const [reservation, setReservation] = useState<Reservation>({
    startAt: new Date(),
    name: "",
    email: "",
    phone: "",
    agreeTerms: false,
  } as Reservation)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [validateErrors, setValidateErrors] = useState<ValidationError>({})

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newReservation = {
      ...reservation,
      [e.target.name]: e.target.value,
    }
    setReservation(newReservation)
  }

  const onChangePhoneHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phone = e.target.value
      .replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
      .replace(/[^0-9]/g, "")
    const newReservation = {
      ...reservation,
      phone: phone,
    }
    setReservation(newReservation)
  }

  const onChangeAgreeTermsHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newReservation = {
      ...reservation,
      agreeTerms: e.target.checked,
    }
    setReservation(newReservation)
  }

  const createCounseling = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const headers = new Headers()
    headers.set("Accept", "application/json")
    headers.set("Content-Type", "application/json")
    headers.set("X-Requested-With", "XMLHttpRequest")

    console.log(searchParams.get("source"))

    fetch("/api/counselings", {
      method: "POST",
      body: JSON.stringify({
        reservation_at: reservation.startAt.toISOString(),
        name: reservation.name,
        email: reservation.email,
        phone: reservation.phone,
        source: searchParams.get("source"),
        is_agree_terms: true,
      }),
      headers,
    }).then((response) => {
      setSubmitting(false)

      if (response.status === 200) {
        response.json().then((json) => {
          // GTMタグを埋め込んでいるので一旦こちらにリダイレクトさせる
          const source = searchParams.get("source")
          let domain = ""
          let path = "complete"

          if (source === null) {
            domain = "ec.internet-clinic.jp"
          } else if (source === "homepage") {
            domain = "ec.internet-clinic.jp"
          } else if (source.match(/for_stg_test/)) {
            domain = "ec.internet-clinic.jp"
            path = "complete_stg"
          } else if (source.match(/for_sand_test/)) {
            domain = "ec.internet-clinic.jp"
            path = "complete_sand"
          } else {
            domain =
              source !== null ? source!.split("/")[2] : "ec.internet-clinic.jp"
          }

          // FacebookのCV確認時に警告が出るため文字列を置き換え (mailを命名に使用しない)
          const reservation_email = encodeURIComponent(
            reservation.email.replace(/@/g, "__at__")
          )

          const topWindow = window.top

          if (!topWindow) return

          topWindow.location.href = `https://${domain}/${path}?cv_tag=${reservation_email}&reservation_id=${json.id}&reservation_at=${json.reservation_at}`
          return
        })
      } else if (response.status === 409) {
        setErrorMessage("お客様は既に登録済みです")
        return
      } else if (response.status === 422) {
        response.json().then((json) => {
          setErrorMessage(`予約の作成に失敗しました。${json.messages.join("")}`)
        })
        return
      } else {
        setErrorMessage(
          "予約の作成に失敗しました。時間枠を変更して再度予約をお願いします。"
        )
        return
      }
    })
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const errors = validation(reservation)
    setValidateErrors(errors)
    if (submitting || Object.keys(errors).length > 0) {
      return
    }

    setSubmitting(true)
    createCounseling()
  }

  useEffect(() => {
    setErrorMessage(null)
    setReservation({
      startAt: startAt,
      name: "",
      email: "",
      phone: "",
    } as Reservation)
  }, [startAt])

  return (
    <Form className="mt-4" onSubmit={onSubmitHandler}>
      {errorMessage && <Alert color="warning">{errorMessage}</Alert>}
      <FormGroup>
        <Label for="name">お名前</Label>
        <Input
          value={reservation.name}
          onChange={onChangeHandler}
          id="name"
          name="name"
          className={validateErrors["reservation.name"] ? " is-invalid" : ""}
          placeholder="サンプル 太郎"
        />
        {validateErrors["reservation.name"] && (
          <p className="text-danger">{validateErrors["reservation.name"]}</p>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="phone">
          電話番号 <small>(半角)</small>
        </Label>
        <Input
          type="tel"
          value={reservation.phone}
          onChange={onChangePhoneHandler}
          id="phone"
          name="phone"
          className={validateErrors["reservation.phone"] ? " is-invalid" : ""}
          placeholder="09012345678"
        />
        {validateErrors["reservation.phone"] && (
          <p className="text-danger">{validateErrors["reservation.phone"]}</p>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="email">
          メールアドレス <small>(半角)</small>
        </Label>
        <Input
          value={reservation.email}
          onChange={onChangeHandler}
          id="email"
          name="email"
          className={validateErrors["reservation.email"] ? " is-invalid" : ""}
          placeholder="sample@example.com"
        />
        {validateErrors["reservation.email"] && (
          <p className="text-danger">{validateErrors["reservation.email"]}</p>
        )}
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            checked={reservation.agreeTerms}
            onChange={onChangeAgreeTermsHandler}
          />
          <a
            href="https://agasmartclinic.notion.site/e628364777e44ed39b65773b83c54998"
            target="_blank"
            rel="noreferrer"
          >
            利用規約
          </a>
          、
          <a
            href="https://agasmartclinic.notion.site/e7dd2be48031470fbc409a0412c9b589"
            target="_blank"
            rel="noreferrer"
          >
            プライバシーポリシー
          </a>
          及び
          <a
            href="https://agasmartclinic.notion.site/186a697e5dbf45e0a90fd1463aa71c85"
            target="_blank"
            rel="noreferrer"
          >
            診療計画書
          </a>
          に同意する
          {validateErrors["reservation.agreeTerms"] && (
            <p className="text-danger">
              {validateErrors["reservation.agreeTerms"]}
            </p>
          )}
        </Label>
      </FormGroup>
      <Button
        type="submit"
        color="main"
        block
        className="mt-4 height-6"
        disabled={submitting}
      >
        無料予約する
      </Button>
    </Form>
  )
}

export default ReservationForm
