import "jquery"
import "bootstrap"

import "public"
import "stylesheets/application"
import "stylesheets/customer"
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext) // eslint-disable-line react-hooks/rules-of-hooks
