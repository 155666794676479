import React from "react"
import { SchedulableTime } from "../../types"

type Props = {
  schedulableTime: SchedulableTime
  onSelectTime: (date: Date) => void
}

const DateVacant: React.FC<Props> = (props: Props) => {
  return (
    <div
      className="text-orange border-grey-top py-2"
      onClick={() => props.onSelectTime(props.schedulableTime.startAt)}
      data-testid={`date-vacant-${props.schedulableTime.startAt.toISOString()}`}
    >
      <i className="far fa-circle"></i>
    </div>
  )
}

export default DateVacant
