import React from "react"
import images from "../../helpers/ui-helpers/ImageSources"

const Header = () => (
  <header className="header d-flex justify-content-center mb-2 pt-5">
    <img src={images.smartClinicLogo} alt="AGAスマクリ" />
  </header>
)

export default Header
