import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps, useLocation } from "react-router-dom"
import moment from "moment"

import { Reservation } from "./types"
import ReservationFormButton from "../../layout/ReservationFormButton"
import { createRequestHeadersForCors } from "../../utils/request_headers"

type Props = {
  customerId: string
  startAt: Date
  isFirstExamination: boolean
} & RouteComponentProps<{}>

const ReservationForm: React.FC<Props> = (props) => {
  const { customerId, startAt, history, isFirstExamination } = props
  const [reservation, setReservation] = useState<Reservation>({
    startAt: new Date(),
  } as Reservation)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [examinationType, setExaminationType] = useState<String | null>(null)

  const search = useLocation().search
  const query = new URLSearchParams(search)
  const medicalExaminationId = query.get("medicalExaminationId")
  const createMedicalExamination = () => {
    const headers = new Headers()
    headers.set("Accept", "application/json")
    headers.set("Content-Type", "application/json")
    headers.set("X-Requested-With", "XMLHttpRequest")

    if (!!medicalExaminationId && medicalExaminationId !== "null") {
      fetch(
        `/api/customers/${customerId}/medical_examinations/${medicalExaminationId}`,
        {
          method: "PUT",
          body: JSON.stringify({
            reservation_at: reservation.startAt.toISOString(),
            examination_type: examinationType,
          }),
          headers,
        }
      ).then((response) => {
        setSubmitting(false)
        if (response.ok) {
          history.push({
            pathname: `/thanks_medical_examination_appointment/${moment(
              reservation.startAt.toISOString()
            ).format("YYYYMMDDHHmm")}`,
            state: { isFirstExamination: isFirstExamination },
          })
          return
        }
        if (response.status === 422) {
          response.json().then((json) => {
            setErrorMessage(
              `予約の作成に失敗しました。${json.messages.join("")}`
            )
          })
          return
        }
        setErrorMessage(
          "予約の作成に失敗しました。時間枠を変更して再度予約をお願いします。"
        )
      })
    } else {
      fetch(`/api/customers/${customerId}/medical_examinations`, {
        method: "POST",
        body: JSON.stringify({
          reservation_at: reservation.startAt.toISOString(),
          examination_type: "examination",
        }),
        headers,
      }).then((response) => {
        setSubmitting(false)
        if (response.ok) {
          history.push(
            `/thanks_medical_examination_appointment/${moment(
              reservation.startAt.toISOString()
            ).format("YYYYMMDDHHmm")}`
          )
          return
        }
        if (response.status === 422) {
          response.json().then((json) => {
            setErrorMessage(
              `予約の作成に失敗しました。${json.messages.join("")}`
            )
          })
          return
        }
        setErrorMessage(
          "予約の作成に失敗しました。時間枠を変更して再度予約をお願いします。"
        )
      })
    }
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (submitting) {
      return
    }

    setSubmitting(true)
    createMedicalExamination()
  }

  useEffect(() => {
    ;(async () => {
      // NOTE: 初診の場合
      if (medicalExaminationId === null) {
        setExaminationType("examination")
        return
      }
      const response = await fetch(
        `/api/medical_examinations/${medicalExaminationId}/medical_examination_infos`,
        {
          method: "GET",
          headers: createRequestHeadersForCors(),
        }
      )

      if (response.ok) {
        const json = await response.json()

        if (
          json.medical_examination_infos.find(
            (i) => i.title === "相談事項" && i.value === "あり"
          )
        ) {
          setExaminationType("examination")
        } else if (
          json.medical_examination_infos.find(
            (i) => i.title === "増量・変更希望" && i.value === "希望する"
          )
        ) {
          setExaminationType("increase_drug")
        } else setExaminationType("examination")
      } else {
        window.alert(
          "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
        )
      }
    })()
  }, [medicalExaminationId])

  useEffect(() => {
    setErrorMessage(null)
    setReservation({
      startAt: startAt,
    } as Reservation)
  }, [startAt])

  return (
    examinationType && (
      <ReservationFormButton
        onSubmitHandler={onSubmitHandler}
        errorMessage={errorMessage}
        submitting={submitting}
      />
    )
  )
}

export default withRouter(ReservationForm)
