import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Alert } from "reactstrap"
import { useLocation } from "react-router-dom"

type Props = {} & RouteComponentProps<{}>

function validateLength(str, length) {
  if (str.length > length) {
    return false
  }
  return true
}

function sanitize(str) {
  return str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")
}

const FirstRegisterCounselingPage: React.FC<Props> = (props) => {
  const { history } = props
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const allianceParam = searchParams.get("alliance_note") as string
  let course = "counseling"

  const register = () => {
    const headers = new Headers()
    headers.set("Accept", "application/json")
    headers.set("Content-Type", "application/json")
    headers.set("X-Requested-With", "XMLHttpRequest")
    const csrfToken = (document
      .getElementsByName("csrf-token")
      .item(0) as HTMLMetaElement).content
    headers.set("Content-Type", "application/json")
    headers.set("X-CSRF-Token", csrfToken)

    let nameTmp = ""
    let phoneTmp = ""
    let emailTmp = ""
    let allianceTmp = ""
    nameTmp = (document.getElementById("input-name") as HTMLInputElement).value
    phoneTmp = (document.getElementById("input-phone") as HTMLInputElement)
      .value
    emailTmp = (document.getElementById("input-mail") as HTMLInputElement).value

    if (allianceParam) {
      allianceTmp = (document.getElementById(
        "input-alliance"
      ) as HTMLInputElement).value
    }
    nameTmp = sanitize(nameTmp)
    phoneTmp = sanitize(phoneTmp)
    emailTmp = sanitize(emailTmp)

    if (
      !validateLength(nameTmp, 50) ||
      !validateLength(phoneTmp, 11) ||
      !validateLength(emailTmp, 100)
    ) {
      setErrorMessage(
        "お名前５０文字以内/電話番号(半角)１１文字以内/メールアドレス(半角)１００文字以内で入力してください"
      )
      setSubmitting(false)
      return
    }

    if (!nameTmp || !phoneTmp || !emailTmp) {
      setErrorMessage(
        "お名前/電話番号(半角)/メールアドレス(半角)は全て入力してください"
      )
      setSubmitting(false)
      return
    }

    const sourceVal = searchParams.get("source")

    fetch(`/api/customers`, {
      method: "POST",
      body: JSON.stringify({
        name: nameTmp,
        phone: phoneTmp,
        email: emailTmp,
        source: sourceVal,
        alliance_note: allianceTmp,
        type: "counseling",
        course: course,
      }),
      headers,
    })
      .then((response) => {
        setSubmitting(false)
        response
          .json()
          .then((res) => {
            if (res.status === 200) {
              if (res.env === "dev") {
                history.push(
                  `/customer_schedule_first_immediate_counselings/${res.id}?hidden_header=true`
                )
              } else {
                window.location.href = `/customer_schedule_first_immediate_counselings/${res.id}?hidden_header=true`
              }
              return
              // 既存客の場合はエラーメッセージを表示させるだけに変更
            } else if (res.status === 202) {
              setErrorMessage(
                "既にご登録されている内容です。マイページからご予約をお願い致します。"
              )
              return
            } else if (res.status >= 400) {
              setErrorMessage(`予約の作成に失敗しました。${res.messages}`)
              return
            }
            setErrorMessage(
              "予約の作成に失敗しました。時間枠を変更して再度予約をお願いします。"
            )
          })
          .catch((e: Error) => {
            setSubmitting(false)
            setErrorMessage(e.message)
          })
      })
      .catch((e: Error) => {
        setSubmitting(false)
        setErrorMessage(e.message)
      })
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (submitting) {
      return
    }
    setSubmitting(true)
    register()
  }

  useEffect(() => {
    if (allianceParam) {
      let alliance: HTMLElement = document.getElementById(
        "input-alliance-label"
      ) as HTMLElement
      alliance.textContent = allianceParam
    }
    setErrorMessage(null)
    window.addEventListener("message", (event) => {
      if (event.data === "get-height" && event.source !== null) {
        event.source.postMessage(
          window.document.body.scrollHeight + 20,
          // @ts-ignore
          event.origin
        )
      }
    })
  }, [])

  let submitText = "無料予約する"
  let titleText = "予約フォーム"
  const sourceVal = searchParams.get("source") as string
  const sourceUrl = new URL(sourceVal)
  if (sourceUrl.pathname === "/ali/vit/") {
    submitText = "日程確認に進む"
  }
  if (sourceUrl.pathname.split("/").includes("fci")) {
    course = "FCI"
    submitText = "申し込む"
    titleText = "申込フォーム"
  }

  return (
    <div className="background-first-examination-input">
      <section className="form-header mt-0"></section>
      <label
        className={`mr-3 display-block register-label mb-4 ${
          course === "FCI" ? "register-label-fci" : ""
        }`}
      >
        {titleText}
      </label>
      <form id="form-LP" onSubmit={onSubmitHandler}>
        <div className="term container-first-examination" id="app-term">
          <div className="m-2">
            <label className="mr-3 display-block">お名前</label>
            <input
              type="text"
              id="input-name"
              className="form-control input-text-style mb-4"
              placeholder="佐藤 太郎"
              size={50}
              maxLength={50}
              required
            ></input>
          </div>
          <div className="m-2">
            <label className="mr-3 display-block">電話番号(半角)</label>
            <input
              type="tel"
              id="input-phone"
              className="form-control input-text-style mb-4"
              placeholder="08012345678"
              size={50}
              maxLength={20}
              required
            ></input>
          </div>
          <div className="m-2">
            <label className="mr-3 display-block">メールアドレス(半角)</label>
            <input
              type="email"
              id="input-mail"
              className="form-control input-text-style mb-5"
              placeholder="agasumakuri@gmail.com"
              size={50}
              maxLength={100}
              required
            ></input>
          </div>
          {allianceParam && (
            <div className="m-2">
              <label
                className="mr-3 display-block"
                id="input-alliance-label"
              ></label>
              <input
                type="text"
                id="input-alliance"
                className="form-control input-text-style"
                size={50}
                maxLength={300}
                required
              ></input>
            </div>
          )}
          {errorMessage && <Alert color="warning">{errorMessage}</Alert>}
          <div className="m-2 text-center height-50">
            <button
              type="submit"
              className={`submit-button-LP height-50 ${
                course === "FCI" ? "submit-button-FCI" : ""
              }`}
              name="action"
              value="無料予約する"
              id="submit-button"
            >
              {submitText}
            </button>
          </div>
        </div>
      </form>
      <br />
      <br />
    </div>
  )
}

export default withRouter(FirstRegisterCounselingPage)
