import React, { useState } from "react"
import LoadingIcon from "./LoadingIcon"
import images from "../../helpers/ui-helpers/ImageSources"

declare global {
  interface Window {
    dataLayer: any
  }
}

const MAX_FILE_SIZE = 15 * 1024 * 1024

type Props = {
  title?: string
  type?: string
  imageUploadPath: string
  backgroundImagePath?: string
  setErrorMessage: (errorMessage: string) => void
}

const ImageUploader: React.FC<Props> = (props) => {
  let [imgUrl, setImgUrl] = useState()
  let [imgId, setImgId] = useState()
  let [isUploading, setIsUploading] = useState<boolean>(false)
  const [inputFlg, setInputFlg] = useState(false)

  const id = props.type
    ? "customer-test-result-image-" + props.type
    : "customer-test-result-image"

  const uploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!inputFlg) {
      window.dataLayer.push({
        event: "item_input",
        itemTitle: props.title,
      })
      setInputFlg(true)
    }
    event.preventDefault()
    const files = event.target.files
    if (!files) return

    if (files[0].size > MAX_FILE_SIZE) {
      props.setErrorMessage("画像サイズオーバーです")
      setIsUploading(false)
      event.target.value = ""

      return
    }

    setIsUploading(true)

    const file_reader = new FileReader()
    file_reader.readAsDataURL(files[0])
    const csrfToken = (document
      .getElementsByName("csrf-token")
      .item(0) as HTMLMetaElement).content
    const headers = new Headers()
    headers.set("Accept", "application/json")
    headers.set("Content-Type", "application/json")
    headers.set("X-CSRF-Token", csrfToken)
    headers.set("X-Requested-With", "XMLHttpRequest")
    file_reader.addEventListener("load", function (e) {
      const result = e.target ? e.target.result : null
      const type = props.type ? props.type : null
      const imageurl = URL.createObjectURL(files[0])
      const data = {
        file_data: result,
        file_name: files[0].name,
        file_type: type,
      }
      fetch(props.imageUploadPath, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status > 400) {
            props.setErrorMessage("画像の保存に失敗しました")
            throw Error("FETCH FAILED")
          }

          return response.json()
        })
        .then((json) => {
          if (json.errors) {
            props.setErrorMessage(json.errors.join())
            throw Error("FETCH FAILED")
          }

          // @ts-ignore
          setImgUrl(imageurl)
          if (json.image_id) {
            setImgId(json.image_id)
          }
          setIsUploading(false)
          return
        })
        .catch((error) => {
          console.log(error)
          props.setErrorMessage("画像の保存に失敗しました")
          setIsUploading(false)
          event.target.value = ""
        })
    })
  }

  const clearImage = () => {
    if (!confirm("本当に削除しますか？")) {
      return false
    }
    // FIXME: 本来は DB, S3 上からも削除したい
    setImgUrl(undefined)
    setImgId(undefined)
  }

  if ((imgUrl || isUploading) && imgId) {
    return (
      <>
        <div className="WebInterview__input-image mb-2">
          <img
            src={imgUrl}
            alt="顧客アップロード画像"
            className="WebInterview__image-preview"
          />
          <input type="hidden" className="image-id" value={imgId} />
          <div className="WebInterview__input-image_background">
            <LoadingIcon></LoadingIcon>
          </div>
        </div>
        <h6 className="font-weight-bold text-center">{props.title}</h6>
        <div className="d-flex justify-content-between">
          <label htmlFor={id} className="btn btn-secondary flex-fill">
            選び直す
          </label>
          <button onClick={clearImage} className="btn btn-danger ml-2">
            削除
          </button>
          <input
            type="file"
            className="d-none"
            id={id}
            accept=".png, .jpg, .jpeg"
            onChange={uploadImage}
          />
        </div>
      </>
    )
  } else if (imgUrl || isUploading) {
    return (
      <>
        <div className="WebInterview__input-image mb-2">
          <img
            src={imgUrl}
            alt="顧客アップロード画像"
            className="WebInterview__image-uploading"
          />
          <div className="WebInterview__input-image_background">
            <LoadingIcon></LoadingIcon>
          </div>
        </div>
        <h6 className="font-weight-bold text-center">{props.title}</h6>
      </>
    )
  } else {
    return (
      <>
        <label
          htmlFor={id}
          className="WebInterview__input-image border-dashed upload-area mb-2"
        >
          <img
            src={images.iconCamera}
            alt="カメラ"
            className="upload-area__icon"
          />
          <img src={props.backgroundImagePath} alt="顧客アップロード画像" />
        </label>
        <input
          type="file"
          className="d-none"
          id={id}
          accept=".png, .jpg, .jpeg"
          onChange={uploadImage}
        />
        <h6 className="font-weight-bold text-center">{props.title}</h6>
        <label htmlFor={id} className="btn btn-secondary d-block">
          アップロード
        </label>
      </>
    )
  }
}

export default ImageUploader
