import React from "react"
import { Card, CardBody, CardTitle, Col, Row, Alert } from "reactstrap"

import images from "../../helpers/ui-helpers/ImageSources"
import ImageUploader from "./image_uploader"

const imageHeight = { height: "160px" }

type Props = {
  imageUploadPath: string
  setErrorMessage: (errorMessage: string) => void
}

const ImageUploadSection: React.FC<Props> = (props) => {
  return (
    <Card className="border-0 py-3">
      <CardBody>
        <CardTitle className="WebInterview__Label">
          頭皮写真アップロード<span className="text-blue">任意</span>
        </CardTitle>
        <Alert color="primary mt-4">
          <div className="d-flex align-items-start">
            <img
              src={images.iconInfo}
              className="alert__icon"
              alt="インフォメーション"
            />
            <div className="ml-2">
              アップロードしていただくと、より正確な診断が行えます。
            </div>
          </div>
        </Alert>
        <Row className="mb-4">
          <Col className="pr-2">
            <ImageUploader
              title="生え際（正面）"
              type="front"
              imageUploadPath={props.imageUploadPath}
              backgroundImagePath={images.webInterviewHeadImageFront}
              setErrorMessage={props.setErrorMessage}
            ></ImageUploader>
          </Col>
          <Col className="pl-2">
            <ImageUploader
              title="頭頂部"
              type="top"
              imageUploadPath={props.imageUploadPath}
              backgroundImagePath={images.webInterviewHeadImageTop}
              setErrorMessage={props.setErrorMessage}
            ></ImageUploader>
          </Col>
        </Row>
        <Row>
          <Col className="pr-2">
            <ImageUploader
              title="生え際（右）"
              type="right"
              imageUploadPath={props.imageUploadPath}
              backgroundImagePath={images.webInterviewHeadImageRight}
              setErrorMessage={props.setErrorMessage}
            ></ImageUploader>
          </Col>
          <Col className="pl-2">
            <ImageUploader
              title="生え際（左）"
              type="left"
              imageUploadPath={props.imageUploadPath}
              backgroundImagePath={images.webInterviewHeadImageLeft}
              setErrorMessage={props.setErrorMessage}
            ></ImageUploader>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default ImageUploadSection
