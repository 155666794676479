import React, { useState, useEffect } from "react"

import { withRouter, RouteComponentProps } from "react-router-dom"
import { InterviewItem, InputInterviewAnswer } from "../types"
import IndexPresentation from "../indexPresentation"

type Props = {} & RouteComponentProps<{
  customerId: string
  medicalExaminationId: string
  datetime: string
}>

const WebInterviewPage: React.FC<Props> = (props) => {
  const {
    match: { params },
  } = props

  let [errorMessage, setErrorMessage] = useState<string | null>(null)
  let [isHideAlert, setIsHideAlert] = useState<boolean>(true)
  let [interviewItems, setInterviewItems] = useState<Array<InterviewItem>>([])
  let [interviewItemsToDisplay, setInterviewItemsToDisplay] = useState<
    Array<InterviewItem>
  >([])
  let [interviewChildItems, setInterviewChildItems] = useState<
    Array<InterviewItem>
  >([])
  let [inputInterviewAnswers, setInputInterviewAnswers] = useState<
    Array<InputInterviewAnswer>
  >([])
  const [medicalExamId, setMedicalExamId] = useState<String | null>(null)
  const [customerId, setCustomerId] = useState<string | null>(null)
  let [canAnswer, setCanAnswer] = useState<boolean>(true)
  let [isReady, setIsReady] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-Requested-With", "XMLHttpRequest")
      // すでに予約済みの診療が存在するのであれば、注意メッセージを表示させる。
      await fetch(
        `/api/customers/${params.customerId}/medical_examinations/examination_exist`,
        {
          method: "POST",
          body: JSON.stringify({
            customer_id: params.customerId,
          }),
          headers,
        }
      )
        .then((response) => {
          if (response.status === 404) {
            setCanAnswer(false)
            setIsReady(true)
            alert("URLに誤りがあります。ご確認のうえ再度アクセスお願いします。")
          } else if (!response.ok) {
            setCanAnswer(false)
            setIsReady(true)
            alert(
              "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
            )
          }
        })
        .catch((error) => {
          setCanAnswer(false)
          setIsReady(true)
          alert("通信状態をお確かめの上、再度アクセスお願いします。")
        })

      setCustomerId(params.customerId)
      if (!params.medicalExaminationId) {
        const response = await fetch(
          `/api/customers/${params.customerId}/medical_examinations/temporary_create`,
          {
            method: "POST",
            body: JSON.stringify({
              reservation_at: "9999-12-31",
            }),
            headers,
          }
        )
        const json = await response.json()
        setMedicalExamId(json.medical_examination_id)
      } else {
        setMedicalExamId(params.medicalExaminationId)
      }
    })()
  }, [])

  useEffect(() => {
    if (!medicalExamId) {
      return
    }
    let isMounted = true
    ;(async () => {
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-Requested-With", "XMLHttpRequest")
      await fetch(`/api/web_interview_items/medical_examination`, {
        method: "GET",
        headers,
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              setInterviewItems(json.medical_examination_items)
              setInterviewChildItems(json.medical_examination_child_items)
            })
          } else if (response.status === 404) {
            setCanAnswer(false)
            setIsReady(true)
            alert("URLに誤りがあります。ご確認のうえ再度アクセスお願いします。")
          } else {
            setCanAnswer(false)
            setIsReady(true)
            alert(
              "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
            )
          }
        })
        .catch((error) => {
          console.error("アンケートアイテムの取得に失敗しました: ", error)
          setCanAnswer(false)
          setIsReady(true)
          alert("URLに誤りがあります。ご確認のうえ再度アクセスお願いします。")
        })

      await fetch(
        `/api/medical_examinations/${medicalExamId}/medical_examination_infos`,
        {
          method: "GET",
          headers,
        }
      )
        .then((response) => {
          if (!isMounted) {
            // NOTE: Warning: Can't perform a React state update on an unmounted回避のため
            return
          }
          if (response.ok) {
            response.json().then((json) => {
              const result = json.medical_examination_infos.map((res) => {
                const newInfo: InputInterviewAnswer = {
                  id: res.medical_examination_item_id,
                  value: res.value,
                  title: res.title,
                  order: res.order,
                }
                return newInfo
              })

              setInputInterviewAnswers(result)
              setIsReady(true)
            })
          } else if (response.status === 404) {
            setCanAnswer(false)
            setIsReady(true)
            alert("URLに誤りがあります。ご確認のうえ再度アクセスお願いします。")
          } else {
            setCanAnswer(false)
            setIsReady(true)
            alert(
              "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
            )
          }
        })
        .catch((error) => {
          setCanAnswer(false)
          setIsReady(true)
          alert("通信状態をお確かめの上、再度アクセスお願いします。")
        })
    })()
    return () => {
      isMounted = false
    }
  }, [medicalExamId])

  useEffect(() => {
    let itemsToDisplay: InterviewItem[] = interviewItems

    inputInterviewAnswers.forEach((info) => {
      const item = itemsToDisplay.find((item) => item.id === info.id)
      if (!item) return
      if (!info.value) return
      if (!item.need_aditional_question_list) return

      const arr = item.need_aditional_question_list.split(",")
      let isMoveForward
      isMoveForward = info.value
        .toString()
        .split(",")
        .some((v) => arr.includes(v))
      if (!isMoveForward) return

      const childrenItems: InterviewItem[] = interviewChildItems
      const children = childrenItems.filter(
        (child) => child.parent_uuid === item.id
      )
      itemsToDisplay = itemsToDisplay.concat(children)
    })

    itemsToDisplay = itemsToDisplay.sort((a, b) => a.order - b.order)
    setInterviewItemsToDisplay(itemsToDisplay)
  }, [inputInterviewAnswers, interviewItems, interviewChildItems])

  const onSubmit = () => {
    ;(async () => {
      // 複数選択のvalueがArrayになってるのでjoinする
      // Arrayの方が扱いやすい部分もあるのでsubmit時に整形
      const formatted_answers = inputInterviewAnswers.map((answer) => {
        if (Array.isArray(answer.value)) {
          answer.value = answer.value.join()
          return answer
        } else {
          return answer
        }
      })
      const csrfToken = (document
        .getElementsByName("csrf-token")
        .item(0) as HTMLMetaElement).content
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-CSRF-Token", csrfToken)
      const response = await fetch(
        `/api/medical_examinations/${medicalExamId}/medical_examination_infos`,
        {
          method: "POST",
          body: JSON.stringify({
            medical_examination_infos: formatted_answers,
          }),
          headers,
        }
      )

      if (response.status === 201 || response.status === 200) {
        const json = await response.json()

        if (json.side_effect_medical_examination_needed) {
          window.location.href = `/customer_schedule_side_effect_examinations/overwrite/${medicalExamId}/${customerId}`
        } else {
          window.location.href = `/customer_schedule_medical_examinations/${customerId}?medicalExaminationId=${medicalExamId}&customerId=${customerId}`
        }
        return
      } else if (response.status >= 400) {
        setErrorMessage(`お客様は既に診療予約済みです。
        予約時間の変更は予約確定メールのURLもしくは、当院までお電話ください。
        03-6630-5214`)
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        return
      }
      const json = await response.json()
      setErrorMessage(json.errors)
    })()
  }

  const handleErrorMessage = (message) => {
    setErrorMessage(message)
    setIsHideAlert(false)

    new Promise((resolve) => {
      setTimeout(() => {
        setIsHideAlert(true)
        resolve(null)
      }, 5000)
    }).then(() => {
      return setTimeout(() => {
        // isHideAlertがtrueのままだとtransitionが働いてfadeInしてしまうのでリセット
        // ↑ に伴ってerrorMessageをnullにすることで非表示にする
        // fadeOutに1秒かかるので1秒待ってからリセット
        setErrorMessage(null)
        setIsHideAlert(false)
      }, 1000)
    })
  }

  return isReady ? (
    <>
      <IndexPresentation
        errorMessage={errorMessage}
        interviewItems={interviewItemsToDisplay}
        interviewChildItems={interviewChildItems}
        inputInterviewAnswers={inputInterviewAnswers}
        imageUploadPath={`/api/customers/${customerId}/test_result_images`}
        isHideAlert={isHideAlert}
        setErrorMessage={handleErrorMessage}
        setInputInterviewAnswers={setInputInterviewAnswers}
        setInterviewItems={setInterviewItemsToDisplay}
        onSubmit={() => onSubmit()}
        customerId={customerId}
        canAnswer={canAnswer}
      ></IndexPresentation>
    </>
  ) : null
}

export default withRouter(WebInterviewPage)
