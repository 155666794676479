import React from "react"

import { PaymentMethod, ValidationError } from "./types"

type Props = {
  paymentMethod: PaymentMethod
  onChange: (paymentMethod: PaymentMethod) => void
  validateErrors: ValidationError
}

const NewCreditCardForm: React.FC<Props> = (props) => {
  const { paymentMethod, validateErrors } = props

  const onChange = (key: string, value: string) => {
    const newPaymentMethod = {
      ...paymentMethod,
      [key]: value,
    }
    props.onChange(newPaymentMethod)
  }

  const today = new Date()

  return (
    <>
      <div className="form-group row">
        <label className="col-form-label col-md-4">
          カード番号&emsp;例）1234567890123456
        </label>
        <div className="col-md-5">
          <input
            type="tel"
            className={`form-control${
              validateErrors["paymentMethod.number"] ? " is-invalid" : ""
            }`}
            value={paymentMethod.number}
            onChange={(e) => onChange("number", e.target.value)}
            maxLength={16}
          />
          {validateErrors["paymentMethod.number"] && (
            <p className="text-danger">
              {validateErrors["paymentMethod.number"]}
            </p>
          )}
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-md-4">
          カード氏名&emsp;例）TARO&ensp;YAMADA
        </label>
        <div className="col-md-5">
          <input
            className={`form-control${
              validateErrors["paymentMethod.name"] ? " is-invalid" : ""
            }`}
            value={paymentMethod.name}
            onChange={(e) => onChange("name", e.target.value)}
          />
          {validateErrors["paymentMethod.name"] && (
            <p className="text-danger">
              {validateErrors["paymentMethod.name"]}
            </p>
          )}
          <small>
            ※&ensp;半角英字&emsp;カードに刻印されている表記の通りにご入力ください
          </small>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-md-4">有効期限（月/年）</label>
        <div className="col-md-5">
          <div className="row">
            <div className="col-6">
              <select
                className={`form-control${
                  validateErrors["paymentMethod.expMonth"] ? " is-invalid" : ""
                }`}
                onChange={(e) => onChange("expMonth", e.target.value)}
              >
                <option value={""}>--月--</option>
                {Array.from({ length: 12 }, (_, i) =>
                  `${i + 101}`.slice(-2)
                ).map((month) => (
                  <option value={month} key={month}>
                    {month}月
                  </option>
                ))}
              </select>
              {validateErrors["paymentMethod.expMonth"] && (
                <p className="text-danger">
                  {validateErrors["paymentMethod.expMonth"]}
                </p>
              )}
            </div>
            <div className="col-6">
              <select
                className={`form-control${
                  validateErrors["paymentMethod.expYear"] ? " is-invalid" : ""
                }`}
                onChange={(e) => onChange("expYear", e.target.value)}
              >
                <option value={""}>--年--</option>
                {Array.from({ length: 20 }, (_, i) =>
                  `${i + today.getFullYear()}`.slice(-2)
                ).map((year) => (
                  <option value={year} key={year}>
                    {year}年
                  </option>
                ))}
              </select>
              {validateErrors["paymentMethod.expYear"] && (
                <p className="text-danger">
                  {validateErrors["paymentMethod.expYear"]}
                </p>
              )}
            </div>
          </div>
          <small>※&ensp;カードに刻印されている表記の通りにご選択ください</small>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-md-4">セキュリティコード</label>
        <div className="col-md-5">
          <input
            type="tel"
            className={`form-control${
              validateErrors["paymentMethod.cvc"] ? " is-invalid" : ""
            }`}
            value={paymentMethod.cvc}
            onChange={(e) => onChange("cvc", e.target.value)}
            maxLength={4}
          />
          {validateErrors["paymentMethod.cvc"] && (
            <p className="text-danger">{validateErrors["paymentMethod.cvc"]}</p>
          )}
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4"></div>
        <div className="col-md-8">
          <small>
            ※&ensp;セキュリティコードとは、クレジットカードの裏面または表面に記載された3桁もしくは4桁の番号のことです。
            <br />
            カードを所有していない第三者による「なりすまし決済」等の不正利用を防止するためのコードになります。
          </small>
        </div>
      </div>
      <div>
        <p className="font-weight-bold">カード情報</p>
        <p className="small">
          ・クレジットカード情報が自動的に保存されるため次回以降お手続きの必要はございません。
          <br />
          ・支払い方法やご登録のクレジットカードはマイページより変更することができます。
          <br />
          ・クレジットカード情報は、クレジットカード決済代行会社(GMOペイメントゲートウェイ株式会社［東証１部上場：証券コード3769］）に保存され、当院には保存されません。
          <br />
          ・当注文の購入目的以外でクレジットカード情報は利用しません。
          <br />
          ・購入後の支払い回数変更に関してはクレジットカード会社様へお問い合わせください。
          <br />
          ・次回以降の支払い回数の変更はマイページより変更することができます。
          <br />
          ・分割払いの場合は別途クレジットカード会社様が定める手数料が発生します。
        </p>
      </div>
    </>
  )
}

export default NewCreditCardForm
