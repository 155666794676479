import React from "react"
import images from "../../helpers/ui-helpers/ImageSources"

const RakuRakuGuideSection = () => {
  return (
    <>
      <div className="alert alert-danger text-center">
        <h3 className="h4 font-weight-bold text-red">ラクラク再購入機能</h3>
        <p className="h5 font-weight-bold text-dark-grey">に関するお知らせ</p>
      </div>
      <p className="mt-4">
        この度、AGAスマクリでは、お客様のかねてよりのご要望にお応えして、ラクラク追加購入機能を導入するに至りました。
      </p>
      <div className="p-3 border border-2 rounded mt-4">
        <div className="d-flex align-items-center">
          <img src={images.iconInfoRed} alt="ビックリマーク" />
          <p className="mb-0 ml-2">ラクラク再購入機能とは</p>
        </div>
        <p className="mt-2 mb-0">
          前回と「<b>同じ内容の処方箋</b>
          」を医師の診察なしに購入できる機能です
        </p>
      </div>
      <p className="mt-4">
        （例）3ヶ月おまとめ便の方は、次回以降に診療無しで3ヶ月処方を2回まで再購入できます
      </p>
      <h3 className="h6 font-weight-bold h-border-left mt-4">
        ラクラク再購入機能の流れ
      </h3>
      <ol className="mt-4 ml-0 pl-0">
        <li className="d-flex align-items-center font-weight-bold p-3">
          <label className="mb-0">1.</label>
          <p className="mb-0">
            お薬が切れる20日前に届く
            <span className="text-red">経過観察のWeb問診にご回答</span>
            いただく
          </p>
        </li>
        <li className="d-flex align-items-center font-weight-bold p-3">
          <label className="mb-0">2.</label>
          <p className="mb-0">
            お薬が切れる10日前に追加購入のご案内メールより決済いただく
          </p>
        </li>
        <li className="d-flex align-items-center font-weight-bold p-3">
          <label className="mb-0">3.</label>
          <p className="mb-0">お薬をお届け</p>
        </li>
      </ol>
      <p className="mt-4">
        当院では、お客様の体調面の変化を考慮し、ラクラク追加購入機能は最初の処方から3回のみ利用可能。
        <br />
        <br />
        4回目以降もお薬をご購入されたい場合には医師との診療をお受けいただく必要がございます。
        <br />
        <br />
        なお体調に変化等があった際にはいつでも医師の診療をお受けいただけますので、ご安心くださいませ。
      </p>
      <h3 className="h6 font-weight-bold h-border-left mt-5">
        ラクラク再購入機能の注意点
      </h3>
      <ol className="mt-4 ml-0 pl-0">
        <li className="d-flex align-items-center font-weight-bold p-3">
          <label className="mb-0">1.</label>
          <p className="mb-0">
            ご体調や副作用等が発生している場合には、再購入ができない可能性がございます。
          </p>
        </li>
        <li className="d-flex align-items-center font-weight-bold p-3">
          <label className="mb-0">2.</label>
          <p className="mb-0">
            処方箋切れの3日前までに停止処理をいただけない場合、決済が行われます
          </p>
        </li>
      </ol>
      <p className="mt-4">
        追加購入・追加配送を停止したい場合には、追加購入のご案内メールに添付されている、追加購入停止フォームより停止の手続きをお願いいたします。
        <br />
        <br />
        なお停止フォームは1分ほどで入力が完了しますので、ご安心ください。
      </p>
    </>
  )
}

export default RakuRakuGuideSection
