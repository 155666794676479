import React from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  CardSubtitle,
  CardText,
  CardLink,
  Button,
} from "reactstrap"
import { RouteComponentProps } from "react-router-dom"
import { saveAs } from "file-saver"
import styles from "../PrescriptionPage/PrescriptionPage.module.scss"
const ics = require("ics")
import ScrollToTop from "../../../utils/ScrollToTop"
import LineAccountCard from "../LineAccountCard"
import RakuRakuGuideSection from "../RakuRakuGuideSection"

type Props = {
  starttime: Date
} & RouteComponentProps<{}, {}, { isFirstExamination: boolean }>

type Params = {
  starttime: string
}

const ThanksMedicalExaminationAppointmentPage: React.FC<Props> = (props) => {
  const params: Params = props.match.params as Params
  const isFirstExamination =
    props.location.state?.isFirstExamination === undefined
      ? true
      : props.location.state.isFirstExamination
  let time = params.starttime
  const YEAR = time.substring(0, 4)
  const MONTH = time.substring(4, 6)
  const DAY = time.substring(6, 8)
  const HOUR = time.substring(8, 10)
  const MINUTE = time.substring(10)
  const date = new Date(`${YEAR}/${MONTH}/${DAY} ${HOUR}:${MINUTE}`)
  const dayOfWeek = date.getDay()
  const dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek]
  let END_MINUTE: number | string = +MINUTE + 10
  let END_HOUR = +HOUR
  if (END_MINUTE === 60) {
    END_HOUR += 1
    END_MINUTE = "00"
  }
  // カレンダー機能
  const GOOGLE_CALENDAR_URL = `http://www.google.com/calendar/event?action=TEMPLATE&text=【AGAスマクリ】診療予約&dates=${YEAR}${MONTH}${DAY}T${HOUR}${MINUTE}00/${YEAR}${MONTH}${DAY}T${END_HOUR}${END_MINUTE}00`
  const CHANGE_ADDRESS_PATH = "/customer_app/customer_detail?to=address"

  // icsファイル作成とダウンロード
  const createICSfile = () => {
    ics.createEvent(
      {
        title: "【AGAスマクリ】診療予約",
        description: "",
        busyStatus: "FREE",
        start: [+YEAR, +MONTH, +DAY, +HOUR, +MINUTE],
        duration: { minutes: 10 },
      },
      (error, value) => {
        if (error) {
          console.log(error)
        }
        const blob = new Blob([value])
        saveAs(blob, "calendar.ics")
      }
    )
  }

  const ThanksReservation = () => {
    return (
      <Row className="mt-4">
        <Col
          sx={12}
          sm={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          <Card>
            <CardBody className="text-center">
              <CardTitle tag="h5" className="font-weight-bold mb-3">
                ご予約ありがとうございます
              </CardTitle>
              <CardSubtitle tag="h5" className="mb-2 font-weight-bold mb-3">
                【診療時間】
              </CardSubtitle>
              <CardText className="font-weight-bold mb-3 h5">
                {YEAR}年{MONTH}月{DAY}日 ({dayOfWeekStr}) {HOUR}:{MINUTE}~
              </CardText>
              <p className="p-1 mb-1 color-dark-blue mb-3"></p>
            </CardBody>

            <CardBody className="text-center">
              <CardSubtitle tag="h5" className="mb-2 font-weight-bold mb-3">
                【重要：お届け先変更ご希望の方】
              </CardSubtitle>
              <CardText>
                お届け先住所の変更をご希望のお客様は
                <br />
                マイページよりご変更ください
              </CardText>
            </CardBody>

            <CardBody className="text-center">
              <CardLink
                href={CHANGE_ADDRESS_PATH}
                style={{ maxWidth: "224px" }}
                className="mx-auto d-block color-dark-blue text-white square rounded p-3"
              >
                お届先を変更する
              </CardLink>
            </CardBody>

            <CardBody className="text-center">
              <CardText>
                下記よりご自身のカレンダーに
                <br />
                予約を登録することが可能です
              </CardText>
            </CardBody>

            <CardBody className="text-center mb-3">
              <CardLink
                href={GOOGLE_CALENDAR_URL}
                className="color-dark-blue text-white square rounded p-3"
              >
                Googleカレンダーへ登録
              </CardLink>
            </CardBody>

            <CardBody className="text-center mb-3">
              <Button
                onClick={createICSfile}
                className="color-dark-blue text-white square rounded p-3"
              >
                iPhoneカレンダーへ登録
              </Button>
            </CardBody>

            <CardBody className="text-center mb-3">
              <CardText>
                当日は、スタッフ一同、心よりお待ちしております
                <br />
                何卒よろしくお願いいたします。
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }

  const ThanksReReservation = () => {
    return (
      <>
        <ScrollToTop />
        <section
          className={[styles.thanksPurchase, "container-nallow"].join(" ")}
          data-testid="thanks-split-dispending"
        >
          <h3 className="h4 text-center font-weight-bold mb-4 pb-4">
            再診のご予約
            <br />
            ありがとうございます
          </h3>
          <RakuRakuGuideSection />
          <LineAccountCard />
        </section>
      </>
    )
  }

  return !isFirstExamination ? ThanksReReservation() : ThanksReservation()
}

export default ThanksMedicalExaminationAppointmentPage
