const ImageSources = {
  smartClinicLogo: require("../../../../assets/images/smart_clinic_logo.svg"),
  smartClinicLogoWhite: require("../../../../assets/images/smart_clinic_logo_white.svg"),
  webInterviewSampleImage: require("../../../../assets/images/web_interview_sample_image.png"),
  webInterviewHeadImageFront: require("../../../../assets/images/web_interview_head_image_front.png"),
  webInterviewHeadImageTop: require("../../../../assets/images/web_interview_head_image_top.png"),
  webInterviewHeadImageRight: require("../../../../assets/images/web_interview_head_image_right.png"),
  webInterviewHeadImageLeft: require("../../../../assets/images/web_interview_head_image_left.png"),
  webInterviewIcon: require("../../../../assets/images/web_interview_icon_title.svg"),
  iconTel: require("../../../../assets/images/web_interview_icon_tel.svg"),
  iconLine: require("../../../../assets/images/web_interview_icon_line.svg"),
  telephone: require("../../../../assets/images/telephone_icon.png"),
  timeBlue: require("../../../../assets/images/time_blue_icon.png"),
  walletBlue: require("../../../../assets/images/wallet_blue_icon.png"),
  good: require("../../../../assets/images/good_blue_icon.png"),
  iconInfo: require("../../../../assets/images/icn_info.svg"),
  iconInfoRed: require("../../../../assets/images/icn_info_red.svg"),
  iconCamera: require("../../../../assets/images/icn_camera.svg"),
  iconDelivery: require("../../../../assets/images/icn_delivery.svg"),
  btnSurvey: require("../../../../assets/images/btn_survey.svg"),
  imgSurvey: require("../../../../assets/images/img_survey.png"),
} as const

export default ImageSources
