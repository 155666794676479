import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import moment from "moment"

import ReservationForm from "./ReservationForm"
import { Customer, ScheduleType } from "../../types"
import ReservationConfirm from "../../layout/ReservationConfirm"
import { createRequestHeadersForCors } from "../../utils/request_headers"
import ScrollToTop from "../../../utils/ScrollToTop"

type Props = {} & RouteComponentProps<{ customerId: string; datetime: string }>

const NewCustomerMedicalExaminationPage: React.FC<Props> = (props) => {
  const {
    history,
    location,
    match: { params },
  } = props
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [isFirstExamination, setIsFirstExamination] = useState<boolean>(false)

  const query = new URLSearchParams(location.search)
  const medicalExaminationId = query.get("medicalExaminationId")

  // FIXME: 本来 endAt は 前のページから props で渡したい
  const [endAt, setEndAt] = useState<Date | null>(null)

  const startAt = moment(params.datetime, "YYYYMMDDHHmm")

  const backSelectSchedule = () => {
    if (
      window.location.pathname.split("/")[1] ===
      "customer_schedule_first_medical_examinations"
    ) {
      history.push(
        `/customer_schedule_first_medical_examinations/${params.customerId}`
      )
    } else {
      history.push(
        `/customer_schedule_medical_examinations/${params.customerId}${window.location.search}`
      )
    }
  }

  useEffect(() => {
    ;(async () => {
      const response = await fetch(`/api/customers/${params.customerId}`)
      const customer = await response.json()
      const is_first_examination = await fetch(
        `/api/is_first_examination/${params.customerId}`
      )
      const res = await is_first_examination.json()

      setIsFirstExamination(res.data)
      setCustomer(customer)
    })()
  }, [params])

  useEffect(() => {
    ;(async () => {
      let scheduleType = ScheduleType.medicalExamination.toString()

      // NOTE: 初診の場合
      if (medicalExaminationId === null) {
        scheduleType = ScheduleType.medicalExamination.toString()
      } else {
        const response = await fetch(
          `/api/medical_examinations/${medicalExaminationId}/medical_examination_infos`,
          {
            method: "GET",
            headers: createRequestHeadersForCors(),
          }
        )

        if (response.ok) {
          const json = await response.json()

          if (
            json.medical_examination_infos.find(
              (i) => i.title === "相談事項" && i.value === "あり"
            )
          ) {
            scheduleType = ScheduleType.medicalExamination.toString()
          } else if (
            json.medical_examination_infos.find(
              (i) => i.title === "増量・変更希望" && i.value === "希望する"
            )
          ) {
            scheduleType = ScheduleType.increaseDrugExamination.toString()
          } else scheduleType = ScheduleType.medicalExamination.toString()
        } else {
          window.alert(
            "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
          )
        }
      }

      const searchParams = new URLSearchParams({
        type: scheduleType,
        target_date: startAt.toISOString(),
        customer_id: params.customerId,
      })
      const res = await fetch(
        `/api/schedulable_times_one_days?${searchParams.toString()}`
      )
      const schedule = await res.json()
      const selectedTime = schedule.schedulable_times.times.find((time) => {
        return new Date(time.start_at).toISOString() === startAt.toISOString()
      })
      if (selectedTime) {
        setEndAt(new Date(selectedTime.end_at))
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const searchParams = new URLSearchParams({
        type: "medical_examination",
        target_date: startAt.toISOString(),
        customer_id: params.customerId,
      })
      const res = await fetch(
        `/api/schedulable_times_one_days?${searchParams.toString()}`
      )
      const schedule = await res.json()
      const selectedTime = schedule.schedulable_times.times.find((time) => {
        return new Date(time.start_at).toISOString() === startAt.toISOString()
      })
      if (selectedTime) {
        setEndAt(new Date(selectedTime.end_at))
      }
    })()
  }, [])

  return (
    customer &&
    endAt && (
      <>
        <ScrollToTop />
        <ReservationConfirm
          displayMessage={`${customer.name}様 診療予約`}
          startAt={startAt.toDate()}
          endAt={endAt}
          backSelectSchedule={backSelectSchedule}
          ReservationForm={
            <ReservationForm
              customerId={params.customerId}
              startAt={startAt.toDate()}
              isFirstExamination={isFirstExamination}
            />
          }
        />
      </>
    )
  )
}

export default withRouter(NewCustomerMedicalExaminationPage)
