import React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import moment from "moment"

import SelectSchedule from "../SelectSchedule"
import { ScheduleType } from "../../types"

type Props = {} & RouteComponentProps<{}>

const ScheduleCounselingPage: React.FC<Props> = (props) => {
  const { history } = props

  const onSelectTimeHandler = (date) => {
    history.push(
      `/schedule_counselings/${moment(date).format("YYYYMMDDHHmm")}${
        window.location.search
      }`
    )
  }

  return (
    <>
      <div className="term" id="app-term">
        <div className="text-center mt-4">
          ご希望のカウンセリング開始時刻をクリックすると、お客様情報の入力画面に切り替わります。
        </div>
      </div>
      <br />
      <br />
      <SelectSchedule
        type={ScheduleType.counseling}
        onSelectTime={onSelectTimeHandler}
      />
    </>
  )
}

export default withRouter(ScheduleCounselingPage)
