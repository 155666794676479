import React, { useState, useEffect, useMemo } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row,
  Alert,
  Label,
} from "reactstrap"
import { Center, Image } from "@chakra-ui/react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { SurveyItem, SurveyInfo, SurveyItemResponse } from "./types"

import images from "../../helpers/ui-helpers/ImageSources"

type Props = {} & RouteComponentProps<{
  medicalExaminationId: string
  datetime: string
}>

const redStyle = { color: "red" }

const MedicalExamSurveyPage: React.FC<Props> = (props) => {
  const {
    history,
    match: { params },
  } = props
  let [errorMessage, setErrorMessage] = useState<string | null>(null)
  let [
    surveyItemResponse,
    setSurveyItemResponse,
  ] = useState<SurveyItemResponse>({
    status: 0,
    message: "",
    data: [],
  } as SurveyItemResponse)
  let [surveyItems, setSurveyItems] = useState<Array<SurveyItem>>([])
  let [surveyInfos, setSurveyInfos] = useState<Array<SurveyInfo>>([])
  let [displayChildItemIds, setDisplayChildItemIds] = useState<Array<string>>(
    []
  )

  useEffect(() => {
    setSurveyItems(
      surveyItemResponse.data
        .filter((item) => {
          // 親質問or表示する子質問IDに指定されているもののみ表示
          return !item.parent_uuid || displayChildItemIds.includes(item.id)
        })
        .sort((a, b) => a.order - b.order)
    )
  }, [displayChildItemIds, surveyItemResponse.data])

  useMemo(() => {
    ;(async () => {
      const csrfToken = (document
        .getElementsByName("csrf-token")
        .item(0) as HTMLMetaElement).content
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-CSRF-Token", csrfToken)
      await fetch(
        `/api/medical_exam_survey_items?medical_examination_id=${params.medicalExaminationId}`,
        {
          method: "GET",
          headers,
        }
      )
        .then((response) => {
          if (response.status >= 400) {
            throw Error("FETCH FAILED")
          }
          response.json().then((json) => {
            setSurveyItemResponse(json)
          })
          return
        })
        .catch(() => {
          setErrorMessage(
            "アンケートアイテムの取得に失敗しました。お手数ですがサポートまで問い合わせお願い致します。"
          )
        })
    })()
  }, [])

  const initialValue = (id: string) => {
    const result = surveyInfos.find(
      (info) => info.medical_exam_survey_item_id === id
    )

    if (!result) return

    return result.value
  }

  // 表示する子質問のIDを取得する
  const updateDisplayChildItemIds = () => {
    let shouldDisplayChildItemIds: string[] = []
    for (let i = 0; i < elements.length; i++) {
      document
        .querySelectorAll(
          `[name='item[${i}][value]'], [name='item[${i}][value][]']`
        )
        .forEach((radio: HTMLInputElement) => {
          const parent = surveyItems.find(
            (item) => item.id === radio.dataset.id
          ) as SurveyItem
          if (
            !parent ||
            !radio.checked ||
            !parent.need_additional_question_list
          ) {
            return
          }

          const additionalQuestionsList = parent.need_additional_question_list.split(
            ","
          )
          // チェックが入っている質問に紐づく子質問のIDを取得
          const childIds = surveyItemResponse.data
            .filter(
              (item) =>
                item.parent_uuid == parent.id &&
                additionalQuestionsList.includes(radio.value)
            )
            .map((item) => item.id)
          shouldDisplayChildItemIds = shouldDisplayChildItemIds.concat(childIds)
        })
    }
    setDisplayChildItemIds(shouldDisplayChildItemIds)
  }

  const handleChange = (e) => {
    const { dataset, value, name } = e.target
    const surveyItem = surveyItems.find(
      (item) => item.id === dataset.id
    ) as SurveyItem
    let newInfos: SurveyInfo[] = surveyInfos.filter(
      (info) => info.medical_exam_survey_item_id !== surveyItem.id
    )
    document
      .querySelectorAll(`[name='${name}']`)
      .forEach((elem: HTMLInputElement) => {
        const parentItem = surveyItems.find(
          (item) => item.id === elem.dataset.id
        ) as SurveyItem
        if (!elem.checked || !parentItem) {
          return
        }
        newInfos.push({
          medical_exam_survey_item_id: parentItem.id,
          value: elem.value,
          title: parentItem.title,
          order: parentItem.order,
        })
      })

    // 親質問がチェックされていない場合はそれに紐づく子質問は除外する
    if (
      surveyItem.need_additional_question_list &&
      surveyItem.need_additional_question_list !== value
    ) {
      const childItem = surveyItems.find(
        (item) => item.parent_uuid === surveyItem.id
      ) as SurveyItem
      if (childItem) {
        newInfos = newInfos.filter(
          (info) => info.medical_exam_survey_item_id !== childItem.id
        )
      }
    }
    setSurveyInfos(newInfos)
    updateDisplayChildItemIds()
  }

  const handleTextareaChange = (e) => {
    const { dataset, value } = e.target
    const surveyItem = surveyItems.find(
      (item) => item.id === dataset.id
    ) as SurveyItem
    const newInfos = surveyInfos.filter(
      (info) => info.medical_exam_survey_item_id !== surveyItem.id
    )
    newInfos.push({
      medical_exam_survey_item_id: surveyItem.id,
      value: value,
      title: surveyItem.title,
      order: surveyItem.order,
    })
    setSurveyInfos(newInfos)
  }

  const emptyRequiredItems = () => {
    const requiredItems = surveyItems.filter((item) => item.required)

    const emptyRequiredItems = requiredItems.filter((item) => {
      const input = surveyInfos.find(
        (info) => info.medical_exam_survey_item_id === item.id
      )

      if (!input) return true
      if (!input.value || input.value === "") return true

      return false
    })

    return emptyRequiredItems
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (emptyRequiredItems().length > 0) {
      const errorMessage = emptyRequiredItems().reduce(
        (previous, current) => previous.concat(`\n・${current.title}`),
        "以下の項目が未入力です"
      )
      setErrorMessage(errorMessage)

      const firstRequiredItem = emptyRequiredItems().sort(
        (a, b) => a.order - b.order
      )[0]

      const item = document.getElementById(firstRequiredItem.id) as HTMLElement
      const offsetTop = item.offsetTop
      // ヘッダーの高さ = 56px
      window.scrollTo({ top: offsetTop - 56, left: 0, behavior: "smooth" })

      return false
    }
    window.dataLayer.push({
      event: "item_submit",
    })
    ;(async () => {
      const formattedSurveyInfos: SurveyInfo[] = []
      surveyInfos.forEach((surveyInfo) => {
        const index = formattedSurveyInfos.findIndex(
          (info) =>
            info.medical_exam_survey_item_id ===
            surveyInfo.medical_exam_survey_item_id
        )
        if (index >= 0) {
          const values = [formattedSurveyInfos[index].value, surveyInfo.value]
          formattedSurveyInfos[index].value = values.join(",")
        } else {
          formattedSurveyInfos.push(surveyInfo)
        }
      })
      const csrfToken = (document
        .getElementsByName("csrf-token")
        .item(0) as HTMLMetaElement).content
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-CSRF-Token", csrfToken)
      const response = await fetch(`/api/medical_exam_survey_infos`, {
        method: "POST",
        body: JSON.stringify({
          medical_examination_id: params.medicalExaminationId,
          medical_exam_survey_infos: formattedSurveyInfos,
        }),
        headers,
      })

      if (response.ok) {
        history.push(`/thanks_survey`)
      } else {
        const json = await response.json()

        setErrorMessage(
          (json.messages && json.messages.join("\n")) ||
            "予期せぬエラーが発生しました。サポートまでお問い合わせください。"
        )
      }
    })()
  }

  // アンケートアイテムをelementに設定。order昇順にソートして表示
  let elements: object[] = []
  if (surveyItems.length > 0) {
    surveyItems.forEach((d: SurveyItem, itemIndex) => {
      // アンケートの選択肢は別elementで作成する
      let optionItem: object[] = []
      d.option_list.split(",").forEach((option, index) => {
        if (d.question_type == "text") {
          optionItem.push(
            <div key={index}>
              <textarea
                name={`item[${itemIndex}][value]`}
                className="form-control"
                data-id={d.id}
                rows={4}
                placeholder="こちらにご記入ください"
                onChange={handleTextareaChange}
                defaultValue={initialValue(d.id)}
              ></textarea>
            </div>
          )
        } else if (d.question_type == "multi_select") {
          optionItem.push(
            <div className="form-check" key={index}>
              <Label className="form-check-label">
                <input
                  type="checkbox"
                  name={`item[${itemIndex}][value][]`}
                  value={option}
                  className="form-check-input"
                  data-id={d.id}
                  checked={surveyInfos.some(
                    (info) =>
                      info.medical_exam_survey_item_id === d.id &&
                      info.value === option
                  )}
                  id={`${d.id}_${index}`}
                  onChange={handleChange}
                />
                {option}
              </Label>
            </div>
          )
        } else {
          optionItem.push(
            <div className="form-check" key={index}>
              <Label className="form-check-label">
                <input
                  type="radio"
                  name={`item[${itemIndex}][value]`}
                  value={option}
                  className="form-check-input"
                  data-id={d.id}
                  checked={surveyInfos.some(
                    (info) =>
                      info.medical_exam_survey_item_id === d.id &&
                      info.value === option
                  )}
                  id={`${d.id}_${index}`}
                  onChange={handleChange}
                />
                {option}
              </Label>
            </div>
          )
        }
      })
      elements.push(
        <Row
          className="mt-4"
          key={`${d.id}_${itemIndex}`}
          data-testid="medical-exam-survey-item"
          id={d.id}
        >
          <Col
            sx={12}
            sm={{ size: 10, offset: 1 }}
            md={{ size: 8, offset: 2 }}
            lg={{ size: 6, offset: 3 }}
          >
            <Card className="rounded">
              <CardBody>
                <CardTitle className="h6">
                  {d.title}
                  <span
                    style={redStyle}
                    className={!d.required ? "d-none" : ""}
                  >
                    *
                  </span>
                </CardTitle>
                {optionItem}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )
    })
  }

  return (
    <>
      {errorMessage && (
        <Alert
          color="warning"
          className="sticky-top w-100"
          style={{
            top: "56px",
            transition: "1s",
          }}
        >
          <pre style={{ wordBreak: "break-all", whiteSpace: "pre-wrap" }}>
            {errorMessage}
          </pre>
        </Alert>
      )}
      <Center h="159px">
        <Image src={images.smartClinicLogo} w="278px" alt="AGAスマクリ" />
      </Center>
      <h3 className="text-center mb-5"> 診療に関するアンケートのご協力依頼</h3>
      <p className="text-center">
        本日はお忙しい中、AGAスマクリの診療をお受けくださりありがとうございました。
      </p>
      <p className="text-center mb-5">
        皆様へより良い診療を提供するために、30秒で終わるアンケートにお答えいただけますと幸いです。
      </p>
      <h3 className="mb-5 text-danger mt-4 offset-sm-1 offset-md-2 offset-lg-3">
        {" "}
        *必須
      </h3>

      <form
        action="/api/medical_exam_survey_infos"
        method="post"
        onSubmit={onSubmit}
      >
        <Row className="mt-4">
          <Col
            sx={12}
            sm={{ size: 10, offset: 1 }}
            md={{ size: 8, offset: 2 }}
            lg={{ size: 6, offset: 3 }}
          >
            <Card>
              <CardBody>
                <CardTitle className="h6">
                  診療ID（自動挿入されています）<span style={redStyle}>*</span>
                </CardTitle>
                <CardText>
                  <u>{params.medicalExaminationId}</u>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {elements}
        <button
          type="submit"
          className="btn btn-primary mt-4 offset-sm-1 offset-md-2 offset-lg-3"
        >
          送信
        </button>
      </form>
    </>
  )
}

export default withRouter(MedicalExamSurveyPage)
