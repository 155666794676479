import React, { useState } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Alert, Button, Form } from "reactstrap"
import moment from "moment"

import { createRequestHeadersForCors } from "../../utils/request_headers"
import { Customer } from "../../types"

type Props = {
  startAt: Date
  endAt: Date
  customerId: string
  customerCourse: Customer["course"]
  counselingId: string
} & RouteComponentProps<{}>

const ReservationForm: React.FC<Props> = (props) => {
  const {
    counselingId,
    customerId,
    customerCourse,
    startAt,
    endAt,
    history,
    location,
  } = props

  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const overwriteCounseling = async () => {
    const headers = createRequestHeadersForCors()
    try {
      const response = await fetch(`/api/counselings/${counselingId}`, {
        method: "PATCH",
        body: JSON.stringify({
          customer_id: customerId,
          reservation_at: startAt.toISOString(),
        }),
        headers,
      })
      const json = await response.json()

      if (!response.ok) {
        setErrorMessage(`予約の作成に失敗しました。${json.messages.join("")}`)
        setSubmitting(false)
        return
      }
    } catch (e) {
      setErrorMessage(
        "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
      )
      setSubmitting(false)
      return
    }

    const query = new URLSearchParams(location.search)
    query.set("start_at", moment(startAt).format("YYYYMMDDHHmm"))
    query.set("end_at", moment(endAt).format("YYYYMMDDHHmm"))

    // customerCourseがFCIやimmediateの場合、完了ページでは通常カウンセリング扱いなので、
    // counseling_by_doctorの場合だけ明示的にtypeを設定する
    if (customerCourse === "counseling_by_doctor") {
      query.set("type", customerCourse)
    }

    history.push(
      `/thanks_counseling_appointment/${counselingId}?${query.toString()}`
    )
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (submitting) {
      return
    }

    setSubmitting(true)
    overwriteCounseling()
  }

  return (
    <Form className="mt-4" onSubmit={onSubmitHandler}>
      {errorMessage && <Alert color="warning">{errorMessage}</Alert>}
      <Button
        type="submit"
        color="main"
        block
        className="mt-4"
        disabled={submitting}
      >
        予約変更確定
      </Button>
    </Form>
  )
}

export default withRouter(ReservationForm)
