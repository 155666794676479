import React from "react"
import moment from "moment"
import { Card, CardBody } from "reactstrap"
import images from "../../../helpers/ui-helpers/ImageSources"
import LineAccountCard from "../../LineAccountCard"
import RakuRakuGuideSection from "../../RakuRakuGuideSection"
import styles from "../PrescriptionPage.module.scss"
import ScrollToTop from "../../../../utils/ScrollToTop"
import { Prescription } from "../types"

const ThanksSplitDispensing: React.FC<{ prescription: Prescription }> = (
  props
) => {
  return (
    <>
      <ScrollToTop />
      <section
        className={[styles.thanksPurchase, "container-nallow"].join(" ")}
        data-testid="thanks-split-dispending"
      >
        <h3 className="h4 text-center font-weight-bold mb-4">
          ご購入
          <br />
          ありがとうございます
        </h3>
        <p className="text-center small mb-4">
          決済内容の確認メールをお送りしています。
          <br />
          ご確認よろしくお願いいたします。
        </p>
        <Card className="mb-4">
          <CardBody>
            <div className="d-flex justify-content-center align-items-center">
              <img src={images.iconDelivery} alt="デリバリー" />
              <p className="mb-0 ml-2">お薬到着予定日</p>
            </div>
            <hr />
            <p className="mb-0 h5 font-weight-normal">
              {moment(props.prescription.purchased_at)
                .add(2, "days")
                .format("MM月DD日(ddd)")}
              ~
              {moment(props.prescription.purchased_at)
                .add(5, "days")
                .format("MM月DD日(ddd)")}
            </p>
          </CardBody>
        </Card>
        <hr className="mt-4 mb-4" />
        <RakuRakuGuideSection />
        <LineAccountCard />
      </section>
    </>
  )
}

export default ThanksSplitDispensing
