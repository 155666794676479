import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import moment from "moment"

import { Reservation } from "./types"
import ReservationFormButton from "../../layout/ReservationFormButton"
import { COUNSELING_MINUTES } from "../../../constants"

type Props = {
  customerId: string
  startAt: Date
} & RouteComponentProps<{}>

const ReservationForm: React.FC<Props> = (props) => {
  const { customerId, startAt, history, location } = props
  const [reservation, setReservation] = useState<Reservation>({
    startAt: new Date(),
  } as Reservation)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  let counselingId = ""
  let responseStatus = 0

  const createCounseling = () => {
    const headers = new Headers()
    headers.set("Accept", "application/json")
    headers.set("Content-Type", "application/json")
    headers.set("X-Requested-With", "XMLHttpRequest")
    fetch(`/api/customers/${customerId}/counselings`, {
      method: "POST",
      body: JSON.stringify({
        reservation_at: reservation.startAt.toISOString(),
      }),
      headers,
    })
      .then((response) => {
        responseStatus = response.status
        if (response.status === 422 || response.status > 400) {
          response.json().then((json) => {
            setErrorMessage(
              `予約の作成に失敗しました。${json.messages.join("")}`
            )
          })
          return
        }
        return response.json()
        // TODO: 他のユーザーのIDを受け取らないように、先ほど作成したcounselingのIDをここで改めて取得する
      })
      .then((json) => {
        fetch(`/api/customers/${customerId}/counselings/latest`, {
          method: "POST",
          headers,
        })
          .then((response) => {
            responseStatus = response.status
            return response.json()
          })
          .then((json) => {
            counselingId = json.id

            setSubmitting(false)
            if (responseStatus === 204 || responseStatus === 200) {
              const query = new URLSearchParams(location.search)
              query.set(
                "start_at",
                moment(reservation.startAt).format("YYYYMMDDHHmm")
              )
              // 20分診療の場合はこのページにアクセスすることはないので、
              // カウンセリング時間は必ず10分
              query.set(
                "end_at",
                moment(reservation.startAt)
                  .add(COUNSELING_MINUTES, "minutes")
                  .format("YYYYMMDDHHmm")
              )

              history.push(
                `/thanks_counseling_appointment/${counselingId}?${query.toString()}`
              )
              return
            }

            setErrorMessage(
              "予約の作成に失敗しました。時間枠を変更して再度予約をお願いします。"
            )
          })
      })
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (submitting) {
      return
    }

    setSubmitting(true)
    createCounseling()
  }

  useEffect(() => {
    setErrorMessage(null)
    setReservation({
      startAt: startAt,
    } as Reservation)
  }, [startAt])

  return (
    <ReservationFormButton
      onSubmitHandler={onSubmitHandler}
      errorMessage={errorMessage}
      submitting={submitting}
    />
  )
}

export default withRouter(ReservationForm)
