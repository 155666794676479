import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import moment from "moment"

import ScheduleCalendar from "../ScheduleCalendar"
import { Customer, ScheduleType } from "../../types"

type Props = {} & RouteComponentProps<{ customerId: string }>

const ScheduleCustomerImmediateMedicalExaminationPage: React.FC<Props> = (
  props
) => {
  const {
    history,
    match: { params },
  } = props
  const [customer, setCustomer] = useState<Customer | null>(null)

  const onSelectTimeHandler = (date) => {
    history.push(
      `/customer_schedule_first_immediate_medical_examinations/${
        params.customerId
      }/${moment(date).format("YYYYMMDDHHmm")}${window.location.search}`
    )
  }

  useEffect(() => {
    ;(async () => {
      const response = await fetch(`/api/customers/${params.customerId}`)
      const customer = await response.json()
      setCustomer(customer)
    })()
  }, [params])

  if (customer === null) {
    return null
  }

  let type: ScheduleType
  if (customer.course === "counseling_by_doctor") {
    type = ScheduleType.counselingByDoctor
  } else {
    type = ScheduleType.immediateExamination
  }

  return (
    <>
      <div className="text-center reservation-title">予約フォーム</div>
      <div className="text-center">ご希望の日時をご選択ください</div>
      <ScheduleCalendar type={type} onSelectTime={onSelectTimeHandler} />
    </>
  )
}

export default withRouter(ScheduleCustomerImmediateMedicalExaminationPage)
