import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps, useLocation } from "react-router-dom"
import moment from "moment"

import { createRequestHeadersForCors } from "../../utils/request_headers"
import ScheduleCalendar from "../ScheduleCalendar"
import { Customer, ScheduleType } from "../../types"

type Props = {} & RouteComponentProps<{
  customerId: string
  medicalExaminationId: string
}>

const ScheduleCustomerMedicalExaminationPage: React.FC<Props> = (props) => {
  const {
    history,
    match: { params },
  } = props
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [isFirstExamination, setIsFirstExamination] = useState<boolean>(false)
  const [sheduleType, setScheduleType] = useState<ScheduleType>(null)

  const search = useLocation().search
  const query = new URLSearchParams(search)
  const medicalExaminationId = query.get("medicalExaminationId")

  const onSelectTimeHandler = (date) => {
    let formattedTime = moment(date).format("YYYYMMDDHHmm")
    if (
      window.location.pathname.split("/")[1] ===
      "customer_schedule_first_medical_examinations"
    ) {
      history.push(
        `/customer_schedule_first_medical_examinations/${params.customerId}/${formattedTime}${window.location.search}`
      )
    } else if (
      window.location.pathname.split("/")[1] ===
      "customer_schedule_unable_to_prescribe_medical_examinations"
    ) {
      history.push(
        `/customer_schedule_unable_to_prescribe_medical_examinations/${params.customerId}/${formattedTime}${window.location.search}`
      )
    } else {
      let searchParams = new URLSearchParams(window.location.search)
      // 前の画面から戻ってきた際にパラメータが重複するため
      if (medicalExaminationId && !searchParams.has("medicalExaminationId")) {
        searchParams.append("medicalExaminationId", medicalExaminationId)
      }
      history.push(
        `/customer_schedule_medical_examinations/${
          params.customerId
        }/${formattedTime}?${searchParams.toString()}`
      )
    }
  }

  useEffect(() => {
    ;(async () => {
      const response = await fetch(`/api/customers/${params.customerId}`)
      const customer = await response.json()
      setCustomer(customer)
      const is_first_examination = await fetch(
        `/api/is_first_examination/${params.customerId}`
      )
      const res = await is_first_examination.json()
      setIsFirstExamination(res.data)
    })()
  }, [params])

  useEffect(() => {
    ;(async () => {
      // NOTE: 初診の場合
      if (medicalExaminationId === null) {
        return setScheduleType(ScheduleType.medicalExamination)
      }
      const response = await fetch(
        `/api/medical_examinations/${medicalExaminationId}/medical_examination_infos`,
        {
          method: "GET",
          headers: createRequestHeadersForCors(),
        }
      )

      if (response.ok) {
        const json = await response.json()

        if (
          json.medical_examination_infos.find(
            (i) => i.title === "相談事項" && i.value === "あり"
          )
        ) {
          setScheduleType(ScheduleType.medicalExamination)
        } else if (
          json.medical_examination_infos.find(
            (i) => i.title === "増量・変更希望" && i.value === "希望する"
          )
        ) {
          setScheduleType(ScheduleType.increaseDrugExamination)
        } else setScheduleType(ScheduleType.medicalExamination)
      } else {
        window.alert(
          "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
        )
      }
    })()
  }, [medicalExaminationId])

  if (customer === null) {
    return null
  }

  return (
    sheduleType && (
      <>
        <div className="text-center reservation-title">予約フォーム</div>
        <div className="text-center">ご希望の日時をご選択ください</div>
        <ScheduleCalendar
          type={sheduleType}
          onSelectTime={onSelectTimeHandler}
        />
      </>
    )
  )
}

export default withRouter(ScheduleCustomerMedicalExaminationPage)
