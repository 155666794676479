import React, { ReactNode } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row,
} from "reactstrap"

type Props = {
  startAt: Date
  endAt: Date
  backSelectSchedule: VoidFunction
  ReservationForm: ReactNode
  displayMessage: string | null
}

const ReservationConfirm = ({
  startAt,
  endAt,
  backSelectSchedule,
  ReservationForm,
  displayMessage,
}: Props): JSX.Element => {
  const dayOfWeek = startAt.getDay()
  const dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek]

  return (
    <div>
      <h3 className="margin-tp-5 text-center text-color-fci">
        {displayMessage}
      </h3>
      <div className="mt-5 p-3 text-center mx-auto text-accent-red border-accent-red bg-accent-red rounded mx-width-540 font-weight-bold font-size-bigger">
        まだ確定しておりません
      </div>
      <Row className="mt-4">
        <Col
          sx={12}
          sm={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          <Card>
            <CardBody>
              <CardTitle className="h5 font-weight-bold">
                {startAt.getMonth() + 1}月 {startAt.getDate()}日（{dayOfWeekStr}
                ）
              </CardTitle>
              <CardText className="h4">
                {startAt.getHours().toString().padStart(2, "0")}:
                {startAt.getMinutes().toString().padStart(2, "0")}-
                {endAt.getHours().toString().padStart(2, "0")}:
                {endAt.getMinutes().toString().padStart(2, "0")}
              </CardText>
              <CardText className="h6">
                <Button onClick={backSelectSchedule}>別の日時を選ぶ</Button>
              </CardText>
            </CardBody>
          </Card>
          {ReservationForm}
        </Col>
      </Row>
    </div>
  )
}

export default ReservationConfirm
