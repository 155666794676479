import React, { useState, useEffect } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { Customer, ScheduleType } from "../../types"
import ScheduleCalendar from "../ScheduleCalendar"
import moment from "moment"
import { createRequestHeadersForCors } from "../../utils/request_headers"

type Props = {} & RouteComponentProps<{
  medicalExaminationId: string
  customerId: string
}>

const OverwriteMedicalExaminationPage: React.FC<Props> = (props) => {
  const {
    history,
    match: { params },
  } = props
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [scheduleType, setScheduleType] = useState<ScheduleType>(null)

  const onSelectTimeHandler = (date: Date) => {
    history.push(
      `/customer_schedule_medical_examinations/overwrite/${
        params.medicalExaminationId
      }/${params.customerId}/${moment(date).format("YYYYMMDDHHmm")}${
        window.location.search
      }`
    )
  }

  useEffect(() => {
    ;(async () => {
      const response = await fetch(`/api/customers/${params.customerId}`)
      const customer = await response.json()
      setCustomer(customer)
    })()
  }, [params])

  useEffect(() => {
    ;(async () => {
      const response = await fetch(
        `/api/medical_examinations/${params.medicalExaminationId}/medical_examination_infos`,
        {
          method: "GET",
          headers: createRequestHeadersForCors(),
        }
      )

      if (response.ok) {
        const json = await response.json()

        // 問診の回答結果について副作用と増薬希望どちらも該当する場合は副作用を優先する
        if (json.side_effect_medical_examination_needed) {
          setScheduleType(ScheduleType.sideEffectExamination)
        } else if (
          json.medical_examination_infos.find(
            (i) => i.title === "相談事項" && i.value === "あり"
          )
        ) {
          setScheduleType(ScheduleType.medicalExamination)
        } else if (
          json.medical_examination_infos.find(
            (i) => i.title === "増量・変更希望" && i.value === "希望する"
          )
        ) {
          setScheduleType(ScheduleType.increaseDrugExamination)
        } else setScheduleType(ScheduleType.medicalExamination)
      } else {
        window.alert(
          "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
        )
      }
    })()
  }, [params.medicalExaminationId])

  if (customer === null) {
    return null
  }

  return (
    scheduleType && (
      <>
        <div className="text-center reservation-title">予約フォーム</div>
        <div className="text-center">ご希望の日時をご選択ください</div>
        <ScheduleCalendar
          type={scheduleType}
          onSelectTime={onSelectTimeHandler}
        />
      </>
    )
  )
}

export default withRouter(OverwriteMedicalExaminationPage)
