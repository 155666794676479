import React from "react"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { RouteComponentProps } from "react-router-dom"
import ScrollToTop from "../../../utils/ScrollToTop"

type Props = {
  starttime: Date
} & RouteComponentProps<{}>

type Params = {
  starttime: string
}

const ThanksSurveyPage: React.FC<Props> = (props) => {
  return (
    <>
      <ScrollToTop />
      <Row className="mt-4">
        <Col
          sx={12}
          sm={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          <Card>
            <CardBody className="text-center">
              <CardTitle tag="h5" className="font-weight-bold text-blue mb-3">
                アンケートへご協力いただきありがとうございます
              </CardTitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ThanksSurveyPage
