import React, { useState, useRef, useEffect } from "react"
import { InterviewItem } from "./types"

declare global {
  interface Window {
    dataLayer: any
  }
}

type Props = {
  data: InterviewItem
  handleChange: (id, value) => void
  initialValue?: string
}

interface birthday {
  year?: string
  month?: string
  day?: string
}

const START_YEAR = 1940
const END_YEAR = 2010

const FormBirthday: React.FC<Props> = (props) => {
  const [birthday, setBirthday] = useState<birthday>({})
  const monthInput = useRef<HTMLSelectElement>(null)
  const dayInput = useRef<HTMLSelectElement>(null)

  const yearArray = Array.from(
    Array(END_YEAR - START_YEAR + 1),
    (_, i) => i + START_YEAR
  )
  const monthArray = Array.from(Array(12), (_, i) => i + 1)
  const dayArray = Array.from(Array(31), (_, i) => i + 1)

  useEffect(() => {
    if (props.initialValue) {
      const arr = props.initialValue.split("/")
      setBirthday({
        ...birthday,
        ...{ year: arr[0], month: arr[1], day: arr[2] },
      })
    }
  }, [props.initialValue])

  const [inputFlg, setInputFlg] = useState(false)
  const handleChange = (e) => {
    if (!inputFlg) {
      window.dataLayer.push({
        event: "item_input",
        itemTitle: props.data.title,
      })
      setInputFlg(true)
    }
    const value = e.target.value
    const id = props.data.id
    const type = e.target.getAttribute("data-type")

    const new_birthday = { ...birthday, ...{ [type]: value } }
    setBirthday(new_birthday)

    const new_day = new_birthday.day ? new_birthday.day : ""
    const new_month = new_birthday.month ? new_birthday.month : ""
    const new_year = new_birthday.year ? new_birthday.year : ""
    const birthday_string = `${new_year}/${new_month}/${new_day}`
    props.handleChange(id, birthday_string)
  }

  return (
    <>
      <div className="WebInterview__input-row">
        <select
          className="WebInterview__input-small _year"
          data-type="year"
          inputMode="numeric"
          value={birthday.year}
          onChange={handleChange}
        >
          <option value="" hidden>
            --
          </option>
          {yearArray.map((year) => {
            return (
              <option value={year.toString()} key={`year_${year}`}>
                {year}
              </option>
            )
          })}
        </select>
        <span className="WebInterview__input-unit">年</span>
        <select
          className="WebInterview__input-small"
          ref={monthInput}
          data-type="month"
          inputMode="numeric"
          value={birthday.month}
          onChange={handleChange}
        >
          <option value="" hidden>
            --
          </option>
          {monthArray.map((month) => {
            return (
              <option value={month.toString()} key={`month_${month}`}>
                {month}
              </option>
            )
          })}
        </select>
        <span className="WebInterview__input-unit">月</span>
        <select
          className="WebInterview__input-small"
          ref={dayInput}
          data-type="day"
          inputMode="numeric"
          value={birthday.day}
          onChange={handleChange}
        >
          <option value="" hidden>
            --
          </option>
          {dayArray.map((day) => {
            return (
              <option value={day.toString()} key={`day_${day}`}>
                {day}
              </option>
            )
          })}
        </select>
        <span className="WebInterview__input-unit">日</span>
      </div>
    </>
  )
}

export default FormBirthday
