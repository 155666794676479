import React from "react"

const LoadingIcon: React.FC = () => {
  return (
    <>
      <div className="LoadingIcon">
        <div className="LoadingIcon__Icon">
          <span className="LoadingIcon__Ball _ball-1"></span>
          <span className="LoadingIcon__Ball _ball-2"></span>
          <span className="LoadingIcon__Ball _ball-3"></span>
          <span className="LoadingIcon__Ball _ball-4"></span>
          <span className="LoadingIcon__Ball _ball-5"></span>
          <span className="LoadingIcon__Ball _ball-6"></span>
          <span className="LoadingIcon__Ball _ball-7"></span>
          <span className="LoadingIcon__Ball _ball-8"></span>
        </div>
      </div>
    </>
  )
}

export default LoadingIcon
