import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import moment from "moment"

import ReservationForm from "./ReservationForm"
import { Customer } from "../../types"
import ReservationConfirm from "../../layout/ReservationConfirm"
import { COUNSELING_MINUTES } from "../../../constants"

type Props = {} & RouteComponentProps<{ customerId: string; datetime: string }>

const NewCustomerCounselingPage: React.FC<Props> = (props) => {
  const {
    history,
    match: { params },
  } = props
  const [customer, setCustomer] = useState<Customer | null>(null)

  const startAt = moment(params.datetime, "YYYYMMDDHHmm")
  const endAt = startAt.clone().add(COUNSELING_MINUTES, "minutes")

  const strFormat = String(startAt.format("YYYYMMDDHHmm"))
  const endFormat = String(endAt.format("YYYYMMDDHHmm").toString())
  const startAtYear = parseInt(strFormat.substr(0, 4))
  const startAtMonth = parseInt(strFormat.substr(4, 2))
  const startAtDay = parseInt(strFormat.substr(6, 2))
  const startAtHour = parseInt(strFormat.substr(8, 2))
  const startAtMinute = parseInt(strFormat.substr(10, 2))
  const endAtYear = parseInt(endFormat.substr(0, 4))
  const endAtMonth = parseInt(endFormat.substr(4, 2))
  const endAtDay = parseInt(endFormat.substr(6, 2))
  const endAtHour = parseInt(endFormat.substr(8, 2))
  const endAtMinute = parseInt(endFormat.substr(10, 2))

  const backSelectSchedule = () => {
    history.push(
      `/customer_schedule_counselings/${params.customerId}${window.location.search}`
    )
  }

  useEffect(() => {
    ;(async () => {
      const response = await fetch(`/api/customers/${params.customerId}`)
      const customer = await response.json()
      setCustomer(customer)
    })()
  }, [params])

  if (customer === null) {
    return null
  }

  return (
    <>
      <ReservationConfirm
        displayMessage={`${customer.name}様 カウンセリング予約`}
        startAt={
          new Date(
            startAtYear,
            startAtMonth - 1,
            startAtDay,
            startAtHour,
            startAtMinute
          )
        }
        endAt={
          new Date(endAtYear, endAtMonth, endAtDay, endAtHour, endAtMinute)
        }
        backSelectSchedule={backSelectSchedule}
        ReservationForm={
          <ReservationForm
            customerId={params.customerId}
            startAt={startAt.toDate()}
          />
        }
      />
    </>
  )
}

export default withRouter(NewCustomerCounselingPage)
