import React, { useState, useEffect } from "react"

import { withRouter, RouteComponentProps, useHistory } from "react-router-dom"
import { InterviewItem, InputInterviewAnswer } from "../types"
import IndexPresentation from "../indexPresentation"

type Props = {
  itemsURL: string
} & RouteComponentProps<{ counselingId: string }>
type ItemResponse = {
  counseling_items: Array<InterviewItem>
  counseling_child_items: Array<InterviewItem>
}

export const BaseWebInterviewPage: React.FC<Props> = (props) => {
  const {
    match: { params },
  } = props
  const history = useHistory()
  let [errorMessage, setErrorMessage] = useState<string | null>(null)
  let [isHideAlert, setIsHideAlert] = useState<boolean>(true)
  let [interviewItems, setInterviewItems] = useState<Array<InterviewItem>>([])
  let [interviewItemsToDisplay, setInterviewItemsToDisplay] = useState<
    Array<InterviewItem>
  >([])
  let [interviewChildItems, setInterviewChildItems] = useState<
    Array<InterviewItem>
  >([])
  let [inputInterviewAnswers, setInputInterviewAnswers] = useState<
    Array<InputInterviewAnswer>
  >([])
  let [canAnswer, setCanAnswer] = useState<boolean>(true)

  useEffect(() => {
    let isMounted = true
    ;(async () => {
      const csrfToken = (document
        .getElementsByName("csrf-token")
        .item(0) as HTMLMetaElement).content
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-CSRF-Token", csrfToken)
      await fetch(props.itemsURL, {
        method: "GET",
        headers,
      })
        .then((response) => {
          if (response.status >= 400) {
            throw Error("FETCH FAILED")
          }
          response.json().then((json: ItemResponse) => {
            setInterviewItems(json.counseling_items)
            setInterviewChildItems(json.counseling_child_items)
          })
        })
        .catch((error) => {
          console.error("アンケートアイテムの取得に失敗しました: ", error)
        })

      await fetch(`/api/counselings/${params.counselingId}/counseling_infos/`, {
        method: "GET",
        headers,
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              if (!json.counseling_infos) return
              const result = json.counseling_infos.map((res) => {
                const newInfo: InputInterviewAnswer = {
                  id: res.counseling_item_id,
                  value: res.value,
                  title: res.title,
                  order: res.order,
                }
                return newInfo
              })
              if (isMounted) {
                setInputInterviewAnswers(result)
              }
            })
          } else if (response.status === 404) {
            setCanAnswer(false)
            alert("URLに誤りがあります。ご確認のうえ再度アクセスお願いします。")
          } else {
            setCanAnswer(false)
            alert(
              "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
            )
          }
        })
        .catch((error) => {
          setCanAnswer(false)
          alert("通信状態をお確かめの上、再度アクセスお願いします。")
        })
    })()
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let itemsToDisplay: InterviewItem[] = interviewItems
    inputInterviewAnswers.forEach((info) => {
      const item = itemsToDisplay.find((item) => item.id === info.id)
      if (!item) return
      if (!info.value) return
      if (!item.need_aditional_question_list) return

      const arr = item.need_aditional_question_list.split(",")
      let isMoveForward
      isMoveForward = info.value
        .toString()
        .split(",")
        .some((v) => arr.includes(v))
      if (!isMoveForward) return

      const childrenItems: InterviewItem[] = interviewChildItems
      const children = childrenItems.filter(
        (child) => child.parent_uuid === item.id
      )
      itemsToDisplay = itemsToDisplay.concat(children)
    })

    itemsToDisplay = itemsToDisplay.sort((a, b) => a.order - b.order)
    setInterviewItemsToDisplay(itemsToDisplay)
  }, [inputInterviewAnswers, interviewItems, interviewChildItems])

  const onSubmit = () => {
    ;(async () => {
      // 複数選択のvalueがArrayになってるのでjoinする
      // Arrayの方が扱いやすい部分もあるのでsubmit時に整形
      const formatted_answers = inputInterviewAnswers.map((answer) => {
        if (Array.isArray(answer.value)) {
          answer.value = answer.value.join()
          return answer
        } else {
          return answer
        }
      })

      const csrfToken = (document
        .getElementsByName("csrf-token")
        .item(0) as HTMLMetaElement).content
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-CSRF-Token", csrfToken)
      headers.set("X-Requested-With", "XMLHttpRequest")
      const response = await fetch(`/api/counseling_infos`, {
        method: "POST",
        body: JSON.stringify({
          counseling_id: params.counselingId,
          counseling_infos: formatted_answers,
        }),
        headers,
      })

      const image_ids = Array.from(
        document.querySelectorAll("input.image-id")
      ).map((v) => v.getAttribute("value"))
      const image_response = await fetch(`/api/followup_images`, {
        method: "POST",
        body: JSON.stringify({
          counseling_id: params.counselingId,
          image_ids: image_ids,
        }),
        headers,
      })

      if (
        (response.status === 201 || response.status === 200) &&
        (image_response.status === 201 || image_response.status === 200)
      ) {
        let sex
        const sexAnswer = inputInterviewAnswers.find(
          (answer) => answer.title === "性別"
        )?.value
        if (sexAnswer) {
          sex = sexAnswer === "男性" ? "male" : "female"
        }
        history.replace("/thanks_web_interview", { sex: sex })
        return
      }
      const json = await response.json()
      handleErrorMessage(json.errors)
    })()
  }

  const handleErrorMessage = (message) => {
    setErrorMessage(message)
    setIsHideAlert(false)

    new Promise((resolve) => {
      setTimeout(() => {
        setIsHideAlert(true)
        resolve(null)
      }, 5000)
    }).then(() => {
      return setTimeout(() => {
        // isHideAlertがtrueのままだとtransitionが働いてfadeInしてしまうのでリセット
        // ↑ に伴ってerrorMessageをnullにすることで非表示にする
        // fadeOutに1秒かかるので1秒待ってからリセット
        setErrorMessage(null)
        setIsHideAlert(false)
      }, 1000)
    })
  }

  return (
    <>
      <IndexPresentation
        errorMessage={errorMessage}
        interviewItems={interviewItemsToDisplay}
        interviewChildItems={interviewChildItems}
        inputInterviewAnswers={inputInterviewAnswers}
        imageUploadPath={`/api/followup_image_items`}
        isHideAlert={isHideAlert}
        setErrorMessage={handleErrorMessage}
        setInputInterviewAnswers={setInputInterviewAnswers}
        setInterviewItems={setInterviewItemsToDisplay}
        onSubmit={onSubmit}
        canAnswer={canAnswer}
      ></IndexPresentation>
    </>
  )
}
const WebInterviewPage: React.FC<
  RouteComponentProps<{ counselingId: string }>
> = (props) => {
  return <BaseWebInterviewPage itemsURL="/api/web_interview_items" {...props} />
}

export default withRouter(WebInterviewPage)
