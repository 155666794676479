import React from "react"
import { Text, Box, Image } from "@chakra-ui/react"

type Props = {
  name: string
  amount: string
  effect: string
  sideEffect: string
  imagePath: string
}

const MedicineCard: React.FC<Props> = (props) => {
  return (
    <Box backgroundColor="#FFF" p="16px" m="16px" borderRadius="16px">
      <Box textAlign="center">
        <Image src={props.imagePath} alt={props.name} h="103px" />
      </Box>
      <Text textAlign="center" fontSize="18px" fontWeight="600" mb="0">
        {props.name}
      </Text>
      <Text textAlign="center" fontSize="12px" fontWeight="300">
        {props.amount}
      </Text>
      <Box backgroundColor="#4D79B1" p="4px" mb="16px">
        <Text
          textAlign="center"
          fontSize="16px"
          fontWeight="600"
          color="#FFF"
          m="0"
        >
          効果
        </Text>
      </Box>
      <Text textAlign="center" fontSize="14px" fontWeight="300">
        {props.effect}
      </Text>
      <Box backgroundColor="#4D79B1" p="4" mb="16px">
        <Text
          textAlign="center"
          fontSize="16px"
          fontWeight="600"
          color="#FFF"
          m="0"
        >
          副作用
        </Text>
      </Box>
      <Text textAlign="center" fontSize="14px" fontWeight="300">
        {props.sideEffect}
      </Text>
    </Box>
  )
}

export default MedicineCard
