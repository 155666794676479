import React from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row,
} from "reactstrap"
import { withRouter, RouteComponentProps } from "react-router-dom"
import moment from "moment"

import ReservationForm from "./ReservationForm"
import { COUNSELING_MINUTES } from "../../../constants"

type Props = {} & RouteComponentProps<{ datetime: string }>

const NewCounselingPage: React.FC<Props> = (props) => {
  const {
    history,
    match: { params },
  } = props

  const startAt = moment(params.datetime, "YYYYMMDDHHmm")
  const endAt = startAt.clone().add(COUNSELING_MINUTES, "minutes")

  const backSelectSchedule = () => {
    history.push(`/schedule_counselings${window.location.search}`)
  }

  return (
    <>
      <h3 className="text-center">無料カウンセリング予約</h3>
      <p className="text-center">
        予約完了後、メール・SMSで
        <br />
        無料カウンセリングのご案内をお送りいたします。
      </p>
      <Row className="mt-4">
        <Col
          sx={12}
          sm={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          <Card>
            <CardBody>
              <CardTitle className="text-muted">
                {startAt.format("YYYY/MM/DD")}
              </CardTitle>
              <CardText className="h6">
                {startAt.format("HH:mm")} 〜 {endAt.format("HH:mm")}
              </CardText>
              <CardText className="h6">
                <Button color="main" onClick={() => backSelectSchedule()}>
                  別の日時で予約する
                </Button>
              </CardText>
            </CardBody>
          </Card>
          <ReservationForm startAt={startAt.toDate()} />
        </Col>
      </Row>
    </>
  )
}

export default withRouter(NewCounselingPage)
