import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"

import { Reservation } from "./types"
import ReservationFormButton from "../../layout/ReservationFormButton"
import ReservationFormButtonFci from "../../layout/ReservationFormButtonFci"

type Props = {
  customerId: string
  startAt: Date
  customerEmail: string
  customerPhone: string
  type: string
} & RouteComponentProps<{}>

const ReservationForm: React.FC<Props> = (props) => {
  const {
    customerId,
    startAt,
    customerEmail,
    customerPhone,
    type,
    history,
  } = props
  const [reservation, setReservation] = useState<Reservation>({
    startAt: new Date(),
  } as Reservation)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  let responseStatus = 0

  const createCounseling = () => {
    const headers = new Headers()
    headers.set("Accept", "application/json")
    headers.set("Content-Type", "application/json")
    headers.set("X-Requested-With", "XMLHttpRequest")
    const csrfToken = (document
      .getElementsByName("csrf-token")
      .item(0) as HTMLMetaElement).content
    headers.set("X-CSRF-Token", csrfToken)

    fetch(`/api/customers/${customerId}/counselings`, {
      method: "POST",
      body: JSON.stringify({
        reservation_at: reservation.startAt.toISOString(),
      }),
      headers,
    })
      .then((response) => {
        console.log(response.status)
        responseStatus = response.status
        if (response.status === 422 || response.status > 400) {
          response.json().then((json) => {
            setErrorMessage(
              `予約の作成に失敗しました。${json.messages.join("")}`
            )
          })
          return
        }
        return response.json()
        // TODO: 他のユーザーのIDを受け取らないように、先ほど作成したcounselingのIDをここで改めて取得する
      })
      .then((json) => {
        fetch(`/api/customers/${customerId}/counselings/latest`, {
          method: "POST",
          headers,
        })
          .then((latest_response) => {
            return latest_response.json()
          })
          .then((latest_json) => {
            setSubmitting(false)
            if (responseStatus === 204 || responseStatus === 200) {
              fetch(`/api/immediate_complete_url/${customerId}`, {
                method: "GET",
                headers,
              })
                .then((complete_url_response) => {
                  return complete_url_response.json()
                })
                .then((complete_url_json) => {
                  // FacebookのCV確認時に警告が出るため文字列を置き換え (mailを命名に使用しない)
                  const reservation_email = encodeURIComponent(
                    customerEmail.replace(/@/g, "__at__")
                  )
                  const reservation_phone = encodeURIComponent(
                    customerPhone.replace(/^0/g, "")
                  )
                  const url = complete_url_json.url
                  const topWindow = window.top

                  if (url === "") {
                    if (!topWindow) return

                    topWindow.location.href = `http://lvh.me:3000/web_interview/${latest_json.id}`
                    return
                  }
                  // note_to_doctorにfciを記載
                  if (type === "FCI") {
                    fetch(`/api/customers/${customerId}`, {
                      method: "PUT",
                      body: JSON.stringify({
                        note_to_doctor: "fci",
                      }),
                      headers,
                    })
                  }

                  if (!topWindow) return
                  topWindow.location.href = `${url}?cv_tag=${reservation_email}&phone=${reservation_phone}&reservation_id=${latest_json.id}&reservation_at=${latest_json.reservation_at}&customerId=${customerId}&type=counseling`

                  return
                })
              return
            }

            setErrorMessage(
              "予約の作成に失敗しました。時間枠を変更して再度予約をお願いします。"
            )
          })
      })
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (submitting) {
      return
    }

    setSubmitting(true)
    createCounseling()
  }

  useEffect(() => {
    setErrorMessage(null)
    setReservation({
      startAt: startAt,
    } as Reservation)
  }, [startAt])

  if (type === "FCI") {
    return (
      <ReservationFormButtonFci
        onSubmitHandler={onSubmitHandler}
        errorMessage={errorMessage}
        submitting={submitting}
      />
    )
  } else {
    return (
      <ReservationFormButton
        onSubmitHandler={onSubmitHandler}
        errorMessage={errorMessage}
        submitting={submitting}
      />
    )
  }
}

export default withRouter(ReservationForm)
