import React from "react"

import { withRouter, RouteComponentProps } from "react-router-dom"
import { BaseWebInterviewPage } from "../Counseling"

const WebInterviewPage: React.FC<
  RouteComponentProps<{ counselingId: string }>
> = (props) => {
  return (
    <BaseWebInterviewPage itemsURL="/api/web_interview_items/fci" {...props} />
  )
}

export default withRouter(WebInterviewPage)
