import React, { useState, useEffect } from "react"

import { withRouter, RouteComponentProps } from "react-router-dom"
import { InterviewItem, InputInterviewAnswer } from "../types"
import IndexPresentation from "../indexPresentation"

type Props = {} & RouteComponentProps<{ prescriptionId: string }>

const MonthlySurveyPage: React.FC<Props> = (props) => {
  const {
    history,
    match: { params },
  } = props

  let [errorMessage, setErrorMessage] = useState<string | null>(null)
  let [isHideAlert, setIsHideAlert] = useState<boolean>(true)
  let [interviewItems, setInterviewItems] = useState<Array<InterviewItem>>([])
  let [inputInterviewAnswers, setInputInterviewAnswers] = useState<
    Array<InputInterviewAnswer>
  >([])
  let [canAnswer, setCanAnswer] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-Requested-With", "XMLHttpRequest")

      // アンケート項目の取得
      await fetch("/api/monthly_survey_items", {
        method: "GET",
        headers,
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              setInterviewItems(json.data)
            })
          } else if (response.status === 404) {
            setCanAnswer(false)
            alert("URLに誤りがあります。ご確認のうえ再度アクセスお願いします。")
          } else {
            setCanAnswer(false)
            alert(
              "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
            )
          }
        })
        .catch((error) => {
          console.error("アンケートアイテムの取得に失敗しました: ", error)
          setCanAnswer(false)
          alert("URLに誤りがあります。ご確認のうえ再度アクセスお願いします。")
        })
    })()
  }, [])

  const onSubmit = () => {
    ;(async () => {
      // 複数選択のvalueがArrayになってるのでjoinする
      // Arrayの方が扱いやすい部分もあるのでsubmit時に整形
      const formatted_answers = inputInterviewAnswers.map((answer) => {
        if (Array.isArray(answer.value)) {
          answer.value = answer.value.join()
          return answer
        } else {
          return answer
        }
      })
      const csrfToken = (document
        .getElementsByName("csrf-token")
        .item(0) as HTMLMetaElement).content
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-CSRF-Token", csrfToken)
      const response = await fetch(`/api/monthly_survey_infos`, {
        method: "POST",
        body: JSON.stringify({
          prescription_id: params.prescriptionId,
          monthly_survey_infos: formatted_answers,
        }),
        headers,
      })

      if (response.ok) {
        history.push(`/thanks_survey`)
      } else {
        const json = await response.json()
        setErrorMessage(json.errors)
      }
    })()
  }

  const handleErrorMessage = (message) => {
    setErrorMessage(message)
    setIsHideAlert(false)

    new Promise((resolve) => {
      setTimeout(() => {
        setIsHideAlert(true)
        resolve(null)
      }, 5000)
    }).then(() => {
      return setTimeout(() => {
        // isHideAlertがtrueのままだとtransitionが働いてfadeInしてしまうのでリセット
        // ↑ に伴ってerrorMessageをnullにすることで非表示にする
        // fadeOutに1秒かかるので1秒待ってからリセット
        setErrorMessage(null)
        setIsHideAlert(false)
      }, 1000)
    })
  }

  const TopDescription = (
    <>
      <h3 className="text-center mb-5"> アンケートのご協力依頼</h3>
      <p className="text-center mb-5">
        皆様へより良いサービスを提供するために、30秒で終わるアンケートにお答えいただけますと幸いです。
      </p>
    </>
  )

  return (
    <>
      <IndexPresentation
        errorMessage={errorMessage}
        buttonText="回答を送信する"
        interviewItems={interviewItems}
        interviewChildItems={[]}
        inputInterviewAnswers={inputInterviewAnswers}
        TopDescription={TopDescription}
        imageUploadPath={""}
        isHideAlert={isHideAlert}
        setErrorMessage={handleErrorMessage}
        setInputInterviewAnswers={setInputInterviewAnswers}
        setInterviewItems={setInterviewItems}
        onSubmit={() => onSubmit()}
        canAnswer={canAnswer}
      ></IndexPresentation>
    </>
  )
}

export default withRouter(MonthlySurveyPage)
