import React, { useState, useEffect } from "react"
import { InterviewItem } from "./types"

declare global {
  interface Window {
    dataLayer: any
  }
}

type Props = {
  data: InterviewItem
  handleChange: (id, value) => void
  initialValue?: string | number
}

const FormMultiSelect: React.FC<Props> = (props) => {
  const [selectedItems, setSelectedItems] = useState<Array<String>>([])
  const [inputFlg, setInputFlg] = useState(false)
  const handleChange = (e) => {
    if (!inputFlg) {
      window.dataLayer.push({
        event: "item_input",
        itemTitle: props.data.title,
      })
      setInputFlg(true)
    }
    // チェック済みのアイテムの配列
    let value: Array<String>
    if (selectedItems.includes(e.target.value)) {
      value = selectedItems.filter((item) => item !== e.target.value)
    } else {
      value = [...selectedItems, e.target.value]
    }

    value = value.filter(Boolean)

    setSelectedItems(value)
    const id = props.data.id
    props.handleChange(id, value)
  }

  useEffect(() => {
    setSelectedItems(props.initialValue?.toString().split(",") || [])
  }, [props.initialValue])

  return (
    <>
      {props.data.option_list &&
        props.data.option_list.split(",").map((option, index) => {
          return (
            <div key={index}>
              <input
                type="checkbox"
                value={option}
                checked={selectedItems?.includes(option)}
                name={`${props.data.id}`}
                id={`${props.data.id}_${index}`}
                className="WebInterview__input-radio"
                onChange={handleChange}
              />
              <label
                htmlFor={`${props.data.id}_${index}`}
                className="WebInterview__input-radio_label w-100"
              >
                {option}
              </label>
            </div>
          )
        })}
    </>
  )
}

export default FormMultiSelect
