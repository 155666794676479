import React from "react"

import { SchedulableTime } from "../../types"

type Props = {
  times: SchedulableTime[]
  currentDay: Date
  onSelectTime: (date: Date) => void
}

/* 初期表示times = 0, スケジュール❌になる。非同期表示 */
const IntraDayMinutes: React.FC<Props> = (props) => {
  const { times, currentDay } = props
  const schedulableTimes = times.filter((time) => time.isSchedulable === true)

  const onClick = (e) => {
    const currentMinute = parseInt(
      e.currentTarget.childNodes[0].innerText.split(":")[1]
    )
    currentDay.setMinutes(currentMinute)
    props.onSelectTime(currentDay)
  }

  return (
    <section id="intra-day-minutes" className={"hour-close"}>
      <ul id="intra-day-minutes-list">
        {schedulableTimes.map((list, index) => {
          return (
            <li key={index} className="minutes-list">
              <div className="minutes" onClick={onClick}>
                <div className="minute">
                  {/* NOTE: getMinutes()だと0表示なので00表示になるように0埋めしている */}
                  {list.startAt.getHours() +
                    ":" +
                    list.startAt.getMinutes().toString().padStart(2, "0") +
                    "~"}
                </div>
                <button className="reservation"></button>
              </div>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default IntraDayMinutes
