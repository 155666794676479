import React, { useState } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import ImageUploadSection from "../image_upload_section"
import { Alert } from "reactstrap"
import { createRequestHeadersForCors } from "../../../utils/request_headers"

type Props = {} & RouteComponentProps<{ customerId: string; datetime: string }>

const WebInterviewPage: React.FC<Props> = (props) => {
  const {
    match: { params },
  } = props

  let [errorMessage, setErrorMessage] = useState<string | null>(null)
  let [isHideAlert, setIsHideAlert] = useState<boolean>(true)

  const onSubmit = () => {
    if (!validate()) return
    ;(async () => {
      const response = await fetch(`/api/followup_images`, {
        method: "POST",
        body: JSON.stringify({
          customer_id: params.customerId,
          image_ids: imageIds(),
        }),
        headers: createRequestHeadersForCors({ withCsrfToken: true }),
      })

      if (response.status === 201 || response.status === 200) {
        window.location.href = `/thanks_followup`
        return
      }
      const json = await response.json()
      handleErrorMessage(json.errors)
    })()
  }

  const imageIds = () => {
    return Array.from(document.querySelectorAll("input.image-id")).map((v) =>
      v.getAttribute("value")
    )
  }

  const validate = () => {
    if (imageIds().length === 0) {
      handleErrorMessage("画像を選択してください")
      return false
    }

    return true
  }

  const handleErrorMessage = (message) => {
    setErrorMessage(message)
    setIsHideAlert(false)

    new Promise((resolve) => {
      setTimeout(() => {
        setIsHideAlert(true)
        resolve(null)
      }, 5000)
    }).then(() => {
      return setTimeout(() => {
        // isHideAlertがtrueのままだとtransitionが働いてfadeInしてしまうのでリセット
        // ↑ に伴ってerrorMessageをnullにすることで非表示にする
        // fadeOutに1秒かかるので1秒待ってからリセット
        setErrorMessage(null)
        setIsHideAlert(false)
      }, 1000)
    })
  }
  return (
    <>
      <div className="container-nallow">
        {errorMessage && (
          <Alert
            color="warning"
            className="sticky-top w-100"
            style={{
              top: "56px",
              transition: "1s",
              opacity: isHideAlert ? 0 : 1,
            }}
          >
            <pre style={{ wordBreak: "break-all", whiteSpace: "pre-wrap" }}>
              {errorMessage}
            </pre>
          </Alert>
        )}
        <ImageUploadSection
          imageUploadPath={"/api/followup_image_items"}
          setErrorMessage={handleErrorMessage}
        ></ImageUploadSection>
        <div className="container-center">
          <p className="my-4 pb-1">
            ご回答ありがとうございました。送信ボタンを押してください。
          </p>
          <button onClick={onSubmit} className="WebInterview__Button mb-5">
            写真を投稿する
          </button>
        </div>
      </div>
    </>
  )
}

export default withRouter(WebInterviewPage)
