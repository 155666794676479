import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import moment from "moment"

import SelectScheduleCounseling from "../SelectScheduleCounseling"
import { Customer, ScheduleType } from "../../types"

type Props = {} & RouteComponentProps<{ customerId: string }>

const ScheduleCustomerImmediateCounselingPage: React.FC<Props> = (props) => {
  const {
    history,
    match: { params },
  } = props
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [eventOrigin, setEventOrigin] = useState("")
  let type: ScheduleType = ScheduleType.counseling

  const onSelectTimeHandler = (date) => {
    history.push({
      pathname: `/customer_schedule_first_immediate_counselings/${
        params.customerId
      }/${moment(date).format("YYYYMMDDHHmm")}${window.location.search}`,
      state: { eventOrigin: eventOrigin },
    })
  }

  useEffect(() => {
    ;(async () => {
      const response = await fetch(`/api/customers/${params.customerId}`)
      const customer = await response.json()
      setCustomer(customer)
    })()
    window.addEventListener("message", (event) => {
      if (event.data === "get-height" && event.source !== null) {
        setEventOrigin(event.origin)
        event.source.postMessage(
          window.document.body.scrollHeight,
          // @ts-ignore
          event.origin
        )
      }
    })
  }, [params])

  if (customer === null) {
    return null
  }
  let titleText = "カウンセリング予約フォーム"
  let fci = customer.course && customer.course === "FCI" ? true : false

  if (fci) {
    type = ScheduleType.fci
    titleText = "希望お電話時間"
  }

  return (
    <>
      <div className="text-center term" id="app-term">
        <h1
          className={`h4 mt-4 text-blue font-weight-bold ${
            fci ? "text-color-fci" : ""
          }`}
        >
          {titleText}
        </h1>
        {fci && (
          <p className="mt-4 mb-1">
            本商品は
            <span className="font-size-bigger font-bold">第一類医薬品</span>
            のため
            <br />
            <span className="font-size-bigger color-red font-bold">
              医師とお電話
            </span>
            が必要です。(10分程度)
          </p>
        )}
        <p className="mt-4 mb-1">ご希望の日時をご選択ください</p>
      </div>
      <SelectScheduleCounseling
        type={type}
        onSelectTime={onSelectTimeHandler}
      />
    </>
  )
}

export default withRouter(ScheduleCustomerImmediateCounselingPage)
