import React, { useState } from "react"
import { InterviewItem } from "./types"

declare global {
  interface Window {
    dataLayer: any
  }
}

type Props = {
  data: InterviewItem
  handleChange: (id, value) => void
  initialValue?: string | number
}
const FormSelectBox: React.FC<Props> = (props) => {
  const [inputFlg, setInputFlg] = useState(false)
  const handleChange = (e) => {
    if (!inputFlg) {
      window.dataLayer.push({
        event: "item_input",
        itemTitle: props.data.title,
      })
      setInputFlg(true)
    }
    const value = e.target.value
    const id = props.data.id
    props.handleChange(id, value)
  }
  return (
    <>
      <div className="WebInterview__input-select">
        <select
          className="form-select form-select-lg w-100"
          defaultValue={props.initialValue}
          onChange={handleChange}
        >
          <option value="" hidden>
            選択してください
          </option>
          {props.data.option_list?.split(",").map((option, index) => {
            return (
              <option value={option} key={index}>
                {option}
              </option>
            )
          })}
        </select>
      </div>
    </>
  )
}

export default FormSelectBox
