import React, { useState } from "react"
import { InterviewItem } from "./types"

declare global {
  interface Window {
    dataLayer: any
  }
}

type Props = {
  data: InterviewItem
  initialValue?: string | number
  handleChange: (id, value) => void
}

const FormOneLiner: React.FC<Props> = (props) => {
  const [inputFlg, setInputFlg] = useState(false)
  const handleChange = (e) => {
    if (!inputFlg) {
      window.dataLayer.push({
        event: "item_input",
        itemTitle: props.data.title,
      })
      setInputFlg(true)
    }
    const value = e.target.value
    const id = props.data.id
    props.handleChange(id, value)
  }
  return (
    <>
      <input
        className="WebInterview__input-oneLiner"
        defaultValue={props.initialValue}
        placeholder={
          !!props.data.placeholder
            ? props.data.placeholder
            : "こちらにご記入ください"
        }
        onChange={handleChange}
      ></input>
    </>
  )
}

export default FormOneLiner
