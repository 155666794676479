declare global {
  interface Window {
    amazon: any
  }
}

class AmazonPay {
  static renderButton(config: any, cb: () => Promise<boolean>) {
    if (document.getElementById("AmazonPayButton")) {
      const { createCheckoutSessionConfig, ...others } = config
      const button = window.amazon.Pay.renderButton("#AmazonPayButton", others)
      button.onClick(async () => {
        if (await cb()) {
          button.initCheckout({ createCheckoutSessionConfig })
        }
      })
    }
  }

  static bindChangeAction(checkoutSessionId) {
    if (document.getElementById("AmazonPayChangeAddress")) {
      window.amazon.Pay.bindChangeAction("#AmazonPayChangeAddress", {
        amazonCheckoutSessionId: checkoutSessionId,
        changeAction: "changeAddress",
      })
    }

    if (document.getElementById("AmazonPayChangePayment")) {
      window.amazon.Pay.bindChangeAction("#AmazonPayChangePayment", {
        amazonCheckoutSessionId: checkoutSessionId,
        changeAction: "changePayment",
      })
    }
  }
}

export default AmazonPay
