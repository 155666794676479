import React from "react"

const PrescriptionImagesPage = (toggleImagesView) => (
  <div>
    <span
      className="lead text-primary"
      role="button"
      onClick={toggleImagesView}
    >
      ←戻る
    </span>
    <div className="row mt-2">
      <div className="col-sm-6 text-center">
        <div className="mt-5">フィナステリド</div>
        <img
          className="w-50 mt-2"
          src={require("assets/finasteride.png")}
          alt="フィナステリド"
        />
      </div>
      <div className="col-sm-6 text-center">
        <div className="mt-5">ミノキシジル</div>
        <img
          className="w-50 mt-2"
          src={require("assets/minoxidil.png")}
          alt="ミノキシジル"
        />
      </div>
    </div>
  </div>
)

export default PrescriptionImagesPage
