import React, { useState, useEffect } from "react"
import { Row } from "reactstrap"
import moment from "moment"

import ScheduleIntraDay from "../ScheduleIntraDay"
import type { ScheduleType } from "../../types"
import {
  SchedulableFlag as SchedulableFlagModel,
  SchedulableDate as SchedulableDateModel,
  SchedulableTime,
} from "../../types"

type Props = {
  type: ScheduleType
  onSelectTime: (date: Date) => void
}

type SchedulableMonthResponse = {
  schedulable_dates: any[]
}

type SchedulableDateResponse = {
  schedulable_times: any[]
}

const ScheduleCalendar: React.FC<Props> = (props) => {
  const { type } = props
  const [currentDate, setCurrentDate] = useState<Date>(
    moment().startOf("day").toDate()
  )
  const [currentMonth, setCurrentMonth] = useState<number>(
    new Date().getMonth()
  )
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear()
  )
  const [schedulableMonth, setSchedulableMonth] = useState<
    SchedulableFlagModel[]
  >([])
  const [schedulableDate, setSchedulableDate] = useState<SchedulableDateModel>({
    date: "",
    times: [],
  })

  const [crtDate, setCrtDate] = useState<Date>(moment().startOf("day").toDate())

  // 1日分スケジュールデータ取得
  const refresh = () => {
    ;(async () => {
      const month_res = await fetch(
        `/api/schedulable_times_one_months?type=${type}&target_date=${new Date(
          currentYear,
          currentMonth,
          currentDate.getDate()
        )}&date=${moment(crtDate).format("YYYY-MM-DD")}`
      )
      const month_json = (await month_res.json()) as SchedulableMonthResponse
      const newSchedulableMonth = month_json.schedulable_dates
      setSchedulableMonth(newSchedulableMonth)
      const day_res = await fetch(
        `/api/schedulable_times_one_days?type=${type}&target_date=${new Date(
          currentYear,
          currentMonth,
          currentDate.getDate()
        )}&date=${moment(crtDate).format("YYYY-MM-DD")}`
      )
      const day_json = (await day_res.json()) as SchedulableDateResponse
      const newSchedulableDate = buildSchedulableDate(
        day_json.schedulable_times
      )
      setSchedulableDate(newSchedulableDate)
    })()
  }

  const buildSchedulableDate = (data: any) => {
    const times = data.times.map((time) => buildSchedulableTime(time))
    return {
      date: data.date,
      times: times,
    } as SchedulableDateModel
  }

  const buildSchedulableTime = (data: any) => {
    const startAt = moment(data.start_at).toDate()
    const endAt = moment(data.end_at).toDate()
    return {
      startAt: startAt,
      endAt: endAt,
      isSchedulable: data.is_schedulable,
    } as SchedulableTime
  }

  useEffect(() => {
    refresh()
  }, [currentDate])

  const week = ["日", "月", "火", "水", "木", "金", "土"]
  // 前の月表示
  const prev = () => {
    const tmpYear = currentMonth < 1 ? currentYear - 1 : currentYear
    const tmpMonth = currentMonth > 0 ? currentMonth - 1 : 11
    setCurrentYear(tmpYear)
    setCurrentMonth(tmpMonth)
    setCurrentDate(new Date(tmpYear, tmpMonth, 1))
    setSchedulableMonth([])
    setSchedulableDate({
      date: "",
      times: [],
    })
  }

  // 次の月表示
  const next = () => {
    const tmpYear = currentMonth === 11 ? currentYear + 1 : currentYear
    const tmpMonth = currentMonth < 11 ? currentMonth + 1 : 0
    setCurrentYear(tmpYear)
    setCurrentMonth(tmpMonth)
    setCurrentDate(new Date(tmpYear, tmpMonth, 1))
    setSchedulableMonth([])
    setSchedulableDate({
      date: "",
      times: [],
    })
  }

  // 日付クリックイベント
  const changeDay = (e) => {
    currentDate.setFullYear(
      currentYear,
      currentMonth,
      parseInt(e.currentTarget.innerText)
    )
    setCurrentDate(
      new Date(currentYear, currentMonth, parseInt(e.currentTarget.innerText))
    )
    window.scrollTo({
      top: 1000,
      left: 0,
      behavior: "smooth",
    })
  }

  const renderSchedulableTimes = () => {
    let year = currentDate.getFullYear()
    let month = currentDate.getMonth()

    // 曜日
    const calendar = week.map((weekItem) => <th key={weekItem}>{weekItem}</th>)

    let count = 0
    let startDayOfWeek = new Date(year, month, 1).getDay()
    let endDate = new Date(year, month + 1, 0).getDate()
    let lastMonthEndDate = new Date(year, month, 0).getDate()
    let row = Math.ceil((startDayOfWeek + endDate) / week.length)
    let calendarBody: JSX.Element[][] = []

    // row
    for (let i = 0; i < row; i++) {
      let calendarBodyRow: JSX.Element[] = []
      // column
      for (let j = 0; j < week.length; j++) {
        if (i === 0 && j < startDayOfWeek) {
          // 先月の日付分。日にち非表示
          calendarBodyRow.push(
            <td key={`${year}-${month}-${i}-${j}`} className="disabled"></td>
          )
        } else if (count >= endDate) {
          // 翌月の日付分。日にち非表示
          count++
          calendarBodyRow.push(
            <td key={`${year}-${month}-${i}-${j}`} className="disabled"></td>
          )
        } else {
          let flg = ""
          if (schedulableMonth[count]) {
            flg = schedulableMonth[count]["flag"]
              ? "far fa-circle"
              : "fas fa-times"
          }
          // 曜日に合わせて設定
          count++
          if (flg === "") {
            calendarBodyRow.push(
              <td key={`${year}-${month}-${i}-${j}`} className="disabled">
                {count}
                <br />
              </td>
            )
          } else if (
            year === currentDate.getFullYear() &&
            month === currentDate.getMonth() &&
            count === currentDate.getDate()
          ) {
            calendarBodyRow.push(
              <td
                key={`${year}-${month}-${i}-${j}`}
                className="today table-day"
                onClick={changeDay}
              >
                {count}
                <br />
                <i className={flg}></i>
              </td>
            )
          } else {
            calendarBodyRow.push(
              <td
                key={`${year}-${month}-${i}-${j}`}
                className="table-day"
                onClick={changeDay}
              >
                {count}
                <br />
                <i className={flg}></i>
              </td>
            )
          }
        }
      }
      calendarBody.push(calendarBodyRow)
    }

    /*TODO: カレンダーの日付クリック時に既存currentDateをクリック日付へ変更 */
    return (
      <div className="schedule-calendar-wrapper">
        <Row id="schedule-calendar-header">
          <button id="prev" onClick={prev}>
            ‹
          </button>
          {currentDate.getFullYear() +
            "年 " +
            (currentDate.getMonth() + 1) +
            "月"}
          <button id="next" onClick={next}>
            ›
          </button>
        </Row>
        <Row id="schedule-calendar">
          <table className="schedule-calendar-table">
            <thead>
              <tr className="dayOfWeek">{calendar}</tr>
            </thead>
            <tbody>
              {calendarBody.map((row, i) => (
                <tr key={i}>{row.map((col, j) => col)}</tr>
              ))}
            </tbody>
          </table>
        </Row>

        <ScheduleIntraDay
          key={0}
          type={type}
          currentDay={currentDate}
          times={schedulableDate}
          onSelectTime={props.onSelectTime}
        />
      </div>
    )
  }

  return renderSchedulableTimes()
}

export default ScheduleCalendar
