import React from "react"
import { RouteComponentProps } from "react-router-dom"

type Props = {} & RouteComponentProps<{}>

const FciExistingCustomerPage: React.FC<Props> = (props) => {
  const params = new URL(window.location.href).searchParams
  const name = params.get("name")
  const my_tmp = params.get("my_page")
  const myPage: string = my_tmp ? my_tmp : "#"

  return (
    <div className="container-center">
      <h1 className="text-color-fci mb-5">{name}様</h1>
      <label>すでに当院でご利用履歴がございます。</label>
      <label>ミノキシジル外用薬処方をご希望される場合は、</label>
      <label>下記までお電話いただくか</label>
      <p className="mb-5">マイページより再診のご予約をお取りくださいませ。</p>
      <p className="font-bold">AGAスマクリカスタマーサポート</p>
      <p className="font-bold mb-5">03-6630-5214</p>
      <form className="m-2 text-center height-50 width-100" action={myPage}>
        <button
          type="submit"
          className={`height-50 link-button-FCI`}
          name="action"
        >
          マイページはこちら
        </button>
      </form>
      <br />
      <br />
    </div>
  )
}

export default FciExistingCustomerPage
