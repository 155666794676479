import React, { useState } from "react"
import { InterviewItem } from "./types"

declare global {
  interface Window {
    dataLayer: any
  }
}

type Props = {
  data: InterviewItem
  initialValue?: string | number
  value?: number
  handleChange: (id, value) => void
}

const FormAge: React.FC<Props> = (props) => {
  const [inputFlg, setInputFlg] = useState(false)
  const handleChange = (e) => {
    if (!inputFlg) {
      window.dataLayer.push({
        event: "item_input",
        itemTitle: props.data.title,
      })
      setInputFlg(true)
    }
    const value = e.target.value
    const id = props.data.id
    props.handleChange(id, value)
  }

  return (
    <>
      <div className="WebInterview__input-row">
        <input
          className="WebInterview__input-small"
          inputMode="numeric"
          value={props.initialValue || props.value || 0}
          placeholder={!!props.data.placeholder ? props.data.placeholder : "00"}
          onChange={handleChange}
        ></input>
        <span className="WebInterview__input-unit">歳</span>
      </div>
    </>
  )
}

export default FormAge
