import React from "react"
import images from "../../helpers/ui-helpers/ImageSources"

const BUSINESS_HOURS = "年中無休（年末年始を除く）9:00〜22:00"

const Footer = () => (
  <footer className="footer mt-4">
    <div className="container-nallow">
      <img src={images.smartClinicLogoWhite} alt="AGAスマクリ" />
      <nav className="mt-4">
        <ul>
          <li>
            <a
              href="https://agasmartclinic.notion.site/4d083ab842444cc6bdd205844d2fe9d6"
              className="footer-link"
            >
              特定商取引法に基づく表記
            </a>
          </li>
          <li>
            <a
              href="https://agasmartclinic.notion.site/e628364777e44ed39b65773b83c54998"
              className="footer-link"
            >
              利用規約
            </a>
          </li>
          <li>
            <a
              href="https://agasmartclinic.notion.site/e7dd2be48031470fbc409a0412c9b589"
              className="footer-link"
            >
              プライバシーポリシー
            </a>
          </li>
          <li>
            <a
              className="footer-link"
              href="https://aga-sumakuri.studio.site/hp/contact"
            >
              お問い合わせ
            </a>
          </li>
        </ul>
      </nav>
      <a href="tel:03-6630-5214" className="footer-tel d-block">
        <div className="d-flex align-items-center">
          <img src={images.iconTel} alt="受話器" />
          <div className="d-flex flex-column justify-content-center ml-3">
            <p className="text-blue pt-1">03-6630-5214</p>
            <small>{BUSINESS_HOURS}</small>
          </div>
        </div>
      </a>
      <a
        href="https://lin.ee/QdPkRmI"
        className="footer-line-link d-block mt-3"
      >
        <div className="d-flex align-items-center">
          <img src={images.iconLine} alt="LINE" />
          <p className="ml-3">LINEでかんたん問い合わせ</p>
        </div>
      </a>
      <p className="footer-copy">
        ©︎ 2020 医療法人社団ウェルパートナー All Rights Reserved.
      </p>
    </div>
  </footer>
)

export default Footer
