import React, { useMemo } from "react"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { RouteComponentProps, useLocation } from "react-router-dom"
import ScrollToTop from "../../../utils/ScrollToTop"

type Props = {
  starttime: Date
} & RouteComponentProps<{}>

const ThanksFirstPurchaseSurveyPage: React.FC<Props> = (props) => {
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])

  return (
    <>
      <ScrollToTop />
      <Row className="mt-4">
        <Col
          sx={12}
          sm={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          <Card>
            <CardBody className="text-center">
              <CardTitle tag="h5" className="font-weight-bold text-blue mb-3">
                ご回答ありがとうございました
              </CardTitle>
              {query.get("action") === "cancelPrescription" && (
                <CardTitle tag="h5" className="font-weight-bold text-blue mb-3">
                  停止処理は問題なく完了しました。
                </CardTitle>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ThanksFirstPurchaseSurveyPage
