import React, { useEffect } from "react"
import queryString from "query-string"
import formatExpire from "../../../utils/formatCardExpire"

import {
  PaymentMethod,
  PaymentMethodType,
  ValidationError,
  Card,
} from "./types"
import AmazonPay from "../../utils/amazon_pay"

import NewCreditCardForm from "./NewCreditCardForm"

type Props = {
  paymentMethod: PaymentMethod
  validateErrors: ValidationError
  cards: Card[]
  onChange: (paymentMethod: PaymentMethod) => void
  usingNewCard: boolean
  setUsingNewCard: (use: boolean) => void
  amazonPaymentInfo: string | null
  amazonPayButtonConfig: object
  updatePrescription: () => Promise<boolean>
  isSplitDispensing: boolean
  onChangePaymentTime: (value: number | null) => void
}

const AmazonPayComponent: React.FC<{
  buttonConfig: object
  paymentInfo: string | null
  updatePrescription: Props["updatePrescription"]
}> = (props) => {
  const checkoutSessionId = queryString.parse(window.location.search)[
    "amazonCheckoutSessionId"
  ]

  useEffect(() => {
    AmazonPay.renderButton(props.buttonConfig, props.updatePrescription)
    if (checkoutSessionId) {
      AmazonPay.bindChangeAction(checkoutSessionId)
    }
  }, [])
  return (
    <>
      {!checkoutSessionId && (
        <div className="d-flex justify-content-center">
          <div
            style={{ width: "350px", height: "60px" }}
            id="AmazonPayButton"
          ></div>
        </div>
      )}
      {props.paymentInfo && (
        <div className="mb-2">
          <div className="col-form-label font-weight-bold">お支払い情報</div>
          <div className="card-number">{props.paymentInfo}</div>
        </div>
      )}
      {checkoutSessionId && (
        <div role="button" className="mb-2" id="AmazonPayChangePayment">
          <u>お支払い情報の変更</u>
        </div>
      )}
    </>
  )
}

const PaymentMethodForm: React.FC<Props> = (props) => {
  const {
    usingNewCard,
    setUsingNewCard,
    cards,
    paymentMethod,
    validateErrors,
  } = props

  const onChangePaymentMethodType = (paymentMethodType: PaymentMethodType) => {
    const newPaymentMethod = {
      ...paymentMethod,
      paymentMethod: paymentMethodType,
    }
    props.onChange(newPaymentMethod)
  }

  const onChange = (key: string, value: string) => {
    const newPaymentMethod = {
      ...paymentMethod,
      [key]: value,
    }
    props.onChange(newPaymentMethod)
  }

  const onChangePaymentMethod = (paymentMethod: PaymentMethod) => {
    props.onChange(paymentMethod)
  }

  const renderNewCreditCardForm = () => {
    return (
      <>
        <NewCreditCardForm
          paymentMethod={paymentMethod}
          onChange={onChangePaymentMethod}
          validateErrors={validateErrors}
        />
      </>
    )
  }

  const paymentTimes = [
    { label: "一括", value: "" },
    { label: "3", value: 3 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "10", value: 10 },
    { label: "12", value: 12 },
    { label: "15", value: 15 },
    { label: "18", value: 18 },
    { label: "20", value: 20 },
    { label: "24", value: 24 },
  ]

  const onChangePaymentTime = (event) => {
    const value = event.target.value
    props.onChangePaymentTime(value === "" ? null : value)
  }

  const renderCreditCardForm = () => {
    return (
      <>
        <div className="row">
          <div className="col">
            <p>クレジットカード情報</p>
            <p>※Visa・Mastercard・Amex・JCB・Dinersがご利用になれます</p>
          </div>
        </div>

        {!usingNewCard && !!cards.length && (
          <div>
            <div className="d-flex align-items-center card-info-header">
              <div className="col-6">カード番号</div>
              <div className="col-3">カード氏名</div>
              <div className="col-3">有効期限</div>
            </div>
            {cards.map((card) => {
              return (
                <div key={card.sequence}>
                  <div className="d-flex align-items-center p-3">
                    <input
                      type="radio"
                      name="card"
                      id={`sequence_${card.sequence}`}
                      onChange={() => onChange("sequence", card.sequence)}
                    />
                    <label
                      htmlFor={`sequence_${card.sequence}`}
                      className="card-info-body d-flex ml-3 row w-100 mb-0"
                    >
                      <div className="card-number col-6">
                        {card.card_number}
                      </div>
                      <div className="card-holder-name col-3">
                        {card.holder_name}
                      </div>
                      <div className="card-expire col-3">
                        {formatExpire(card.expire)}
                      </div>
                    </label>
                  </div>
                </div>
              )
            })}
            <div className="d-flex align-items-center p-3">
              <input
                type="radio"
                name="card"
                id="new_card"
                onChange={() => setUsingNewCard(true)}
              />
              <label
                htmlFor="new_card"
                className="card-info-body d-flex ml-3 row w-100 mb-0"
              >
                新しいカードを追加する
              </label>
            </div>
          </div>
        )}

        {(!cards.length || usingNewCard) && renderNewCreditCardForm()}

        <div className="row my-4">
          <label className="col-form-label col-md-2">お支払い回数</label>
          <div className="col-md-2">
            <select className="form-control" onChange={onChangePaymentTime}>
              {paymentTimes.map((time) => (
                <option key={time.value} value={time.value}>
                  {time.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </>
    )
  }

  return (
    <section>
      <div className="form-group row mt-4">
        <div className="w-100">
          <img
            className="w-100 mt-2"
            src={require("assets/recommend_card_payment.png")}
            alt="毎月の購入手続きが不要！簡単！便利！クレジット決済が断然お得！"
          />
        </div>
        <label className="col-form-label col-md-2">お支払い方法</label>
        <div className="col-md-10">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="deliveryTypeHome"
              name="payment_method"
              checked={
                paymentMethod.paymentMethod === PaymentMethodType.CreditCard
              }
              onChange={() =>
                onChangePaymentMethodType(PaymentMethodType.CreditCard)
              }
            />
            <label className="form-check-label" htmlFor="deliveryTypeHome">
              クレジットカード
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="deliveryTypeBankTransfer"
              name="payment_method"
              checked={
                paymentMethod.paymentMethod === PaymentMethodType.BankTransfer
              }
              onChange={() =>
                onChangePaymentMethodType(PaymentMethodType.BankTransfer)
              }
            />
            <label
              className="form-check-label"
              htmlFor="deliveryTypeBankTransfer"
            >
              銀行振込 (別途手数料がかかります)
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              id="deliveryTypeDebitCard"
              type="radio"
              name="payment_method"
              checked={
                paymentMethod.paymentMethod === PaymentMethodType.DebitCard
              }
              onChange={() =>
                onChangePaymentMethodType(PaymentMethodType.DebitCard)
              }
            />
            <label className="form-check-label" htmlFor="deliveryTypeDebitCard">
              デビットカード
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              id="deliveryTypePrepaidCard"
              type="radio"
              name="payment_method"
              checked={
                paymentMethod.paymentMethod === PaymentMethodType.PrepaidCard
              }
              onChange={() =>
                onChangePaymentMethodType(PaymentMethodType.PrepaidCard)
              }
            />
            <label
              className="form-check-label"
              htmlFor="deliveryTypePrepaidCard"
            >
              プリペイドカード
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="deliveryTypeAmazonPay"
              name="payment_method"
              checked={
                paymentMethod.paymentMethod === PaymentMethodType.AmazonPay
              }
              onChange={() =>
                onChangePaymentMethodType(PaymentMethodType.AmazonPay)
              }
            />
            <label className="form-check-label" htmlFor="deliveryTypeAmazonPay">
              Amazon Pay
            </label>
          </div>
        </div>
      </div>
      {paymentMethod.paymentMethod === PaymentMethodType.CreditCard &&
        renderCreditCardForm()}
      {[PaymentMethodType.DebitCard, PaymentMethodType.PrepaidCard].includes(
        paymentMethod.paymentMethod
      ) && renderNewCreditCardForm()}
      {paymentMethod.paymentMethod === PaymentMethodType.AmazonPay && (
        <AmazonPayComponent
          buttonConfig={props.amazonPayButtonConfig}
          paymentInfo={props.amazonPaymentInfo}
          updatePrescription={props.updatePrescription}
        />
      )}
    </section>
  )
}

export default PaymentMethodForm
