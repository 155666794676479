import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import moment from "moment"

import ReservationForm from "./ReservationForm"
import { Customer } from "../../types"
import ReservationConfirm from "../../layout/ReservationConfirm"
import ScrollToTop from "../../../utils/ScrollToTop"

type Props = {} & RouteComponentProps<{ customerId: string; datetime: string }>

const NewCustomerMedicalExaminationPage: React.FC<Props> = (props) => {
  const {
    history,
    match: { params },
  } = props
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [isFirstExamination, setIsFirstExamination] = useState<boolean>(false)
  const [isReExaminationShort, setIsReExaminationShort] = useState<boolean>(
    false
  )
  const [isImmediateExamination, setIsImmediateExamination] = useState<boolean>(
    false
  )

  const backSelectSchedule = () => {
    history.push(
      `/customer_schedule_first_immediate_medical_examinations/${params.customerId}${window.location.search}`
    )
  }

  useEffect(() => {
    ;(async () => {
      const urls = [
        `/api/customers/${params.customerId}`,
        `/api/is_first_examination/${params.customerId}`,
        `/api/is_re_examination_short/${params.customerId}`,
        `/api/is_immediate_examination/${params.customerId}`,
      ]
      const responses = await Promise.all(urls.map((x) => fetch(x)))
      const [customer, first, re, immediate] = await Promise.all(
        responses.map((x) => x.json())
      )

      setCustomer(customer)
      setIsFirstExamination(first.data)
      setIsReExaminationShort(re.data)
      setIsImmediateExamination(immediate.data)
    })()
  }, [params.customerId])

  if (customer === null) {
    return null
  }

  const startAt = moment(params.datetime, "YYYYMMDDHHmm")

  let minutes: number
  if (customer.course == "counseling_by_doctor") {
    // 20分診療（医師によるカウンセリング）ならば20分
    minutes = 20
  } else {
    if (isFirstExamination) {
      // 初診：即時診療ならば10分、それ以外ならば5分
      minutes = isImmediateExamination ? 10 : 5
    } else if (isReExaminationShort) {
      // 再診：5分再診ならば5分
      minutes = 5
    } else {
      // それ以外は10分
      minutes = 10
    }
  }
  const endAt = startAt.clone().add(minutes, "minutes")

  return (
    <>
      <ScrollToTop />
      <ReservationConfirm
        displayMessage={`${customer.name}様 診療予約`}
        startAt={startAt.toDate()}
        endAt={endAt.toDate()}
        backSelectSchedule={backSelectSchedule}
        ReservationForm={
          <ReservationForm
            startAt={startAt.toDate()}
            customerId={params.customerId}
            customerCourse={customer.course}
            customerEmail={customer.email}
          />
        }
      />
    </>
  )
}

export default withRouter(NewCustomerMedicalExaminationPage)
