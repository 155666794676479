import React, { useState } from "react"
import { InterviewItem } from "./types"
import { CardTitle } from "reactstrap"

declare global {
  interface Window {
    dataLayer: any
  }
}

type Props = {
  data: InterviewItem
  handleChange: (id, value) => void
}

const FormAdrress: React.FC<Props> = (props) => {
  const [inputFlg, setInputFlg] = useState(false)
  const [isChangeAddress, setIsChangeAddress] = useState<boolean>(false)
  const [isUpdateAllAfter, setIsUpdateAllAfter] = useState<boolean>(false)
  const [addressInfo, setAddressInfo] = useState<object>({})

  const receivingWayOption = [
    {
      value: "at_home",
      text: "ご自宅",
    },
    {
      value: "delivery_center_pickup",
      text: "ヤマト運輸営業所",
    },
  ]

  const shipFromOption = [
    {
      value: "aga_smart_clinic",
      text: "AGAスマクリ（医療法人社団ウェルパートナー）",
    },
    {
      value: "dr_tashiro",
      text: "医療法人社団ウェルパートナー",
    },
    {
      value: "well_partner",
      text: "田代友里子",
    },
  ]

  const handleChange = (e) => {
    if (!inputFlg) {
      window.dataLayer.push({
        event: "item_input",
        itemTitle: props.data.title,
      })
      setInputFlg(true)
    }
    const newInfo = { ...addressInfo, ...{ [e.target.name]: e.target.value } }
    setAddressInfo(newInfo)

    const id = props.data.id
    props.handleChange(id, newInfo)
  }

  const handleIsChangeAddress = (isChange) => {
    setIsChangeAddress(isChange)
    const newInfo = { ...addressInfo, ...{ is_change: isChange } }
    setAddressInfo(newInfo)

    props.handleChange(props.data.id, newInfo)
  }

  const handleIsupisUpdateAllAfter = (isUpdate) => {
    setIsUpdateAllAfter(isUpdate)
    const newInfo = {
      ...addressInfo,
      ...{ is_update_all_after: isUpdateAllAfter },
    }
    setAddressInfo(newInfo)

    props.handleChange(props.data.id, newInfo)
  }

  return (
    <>
      <div>
        <input
          type="radio"
          value="true"
          checked={isChangeAddress}
          id="web-interview-address-change-yes"
          className="WebInterview__input-radio"
          onChange={() => handleIsChangeAddress(true)}
        />
        <label
          htmlFor="web-interview-address-change-yes"
          className="WebInterview__input-radio_label w-100"
        >
          はい
        </label>
        <input
          type="radio"
          value="false"
          checked={!isChangeAddress}
          id="web-interview-address-change-no"
          className="WebInterview__input-radio"
          onChange={() => handleIsChangeAddress(false)}
        />
        <label
          htmlFor="web-interview-address-change-no"
          className="WebInterview__input-radio_label w-100"
        >
          いいえ
        </label>
      </div>
      {isChangeAddress && (
        <div>
          <CardTitle className="WebInterview__Label mt-3">
            住所を入力してください
          </CardTitle>
          <div>
            <label className="font-weight-bold">
              郵便番号
              <span className="text-red ml-2 font-weight-normal">※必須</span>
            </label>
            <input
              className="WebInterview__input-oneLiner mb-2"
              name="zip"
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <label className="font-weight-bold">
              都道府県
              <span className="text-red ml-2 font-weight-normal">※必須</span>
            </label>
            <input
              className="WebInterview__input-oneLiner mb-2"
              name="state"
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <label className="font-weight-bold">
              市区町村
              <span className="text-red ml-2 font-weight-normal">※必須</span>
            </label>
            <input
              className="WebInterview__input-oneLiner mb-2"
              name="city"
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <label className="font-weight-bold">
              番地
              <span className="text-red ml-2 font-weight-normal">※必須</span>
            </label>
            <input
              className="WebInterview__input-oneLiner mb-2"
              name="line1"
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <label className="font-weight-bold">建物名・部屋番号</label>
            <input
              className="WebInterview__input-oneLiner mb-2"
              name="line2"
              onChange={handleChange}
            ></input>
          </div>
          <div>
            <CardTitle className="WebInterview__Label mt-3">
              お受け取り方法<span className="text-red">※必須</span>
            </CardTitle>
            {receivingWayOption.map((option) => {
              return (
                <div key={option.value}>
                  <input
                    type="radio"
                    value={option.value}
                    id={option.value}
                    name="receiving_way"
                    checked={addressInfo["receiving_way"] === option.value}
                    className="WebInterview__input-radio"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor={option.value}
                    className="WebInterview__input-radio_label w-100"
                  >
                    {option.text}
                  </label>
                </div>
              )
            })}
          </div>
          <div>
            <CardTitle className="WebInterview__Label mt-3">
              発送元名<span className="text-red">※必須</span>
            </CardTitle>
            {shipFromOption.map((option) => {
              return (
                <div key={option.value}>
                  <input
                    type="radio"
                    value={option.value}
                    id={option.value}
                    name="ship_from"
                    checked={addressInfo["ship_from"] === option.value}
                    className="WebInterview__input-radio"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor={option.value}
                    className="WebInterview__input-radio_label w-100"
                  >
                    {option.text}
                  </label>
                </div>
              )
            })}
          </div>
          <div>
            <CardTitle className="WebInterview__Label mt-3">
              次回以降もこの配送先にお届けしますか？
            </CardTitle>
            <input
              type="radio"
              value="true"
              checked={isUpdateAllAfter}
              id="web-interview-update-all-yes"
              className="WebInterview__input-radio"
              onChange={() => handleIsupisUpdateAllAfter(true)}
            />
            <label
              htmlFor="web-interview-update-all-yes"
              className="WebInterview__input-radio_label w-100"
            >
              はい
            </label>
            <input
              type="radio"
              value="false"
              checked={!isUpdateAllAfter}
              id="web-interview-update-all-no"
              className="WebInterview__input-radio"
              onChange={() => handleIsupisUpdateAllAfter(false)}
            />
            <label
              htmlFor="web-interview-update-all-no"
              className="WebInterview__input-radio_label w-100"
            >
              いいえ
            </label>
          </div>
        </div>
      )}
    </>
  )
}

export default FormAdrress
