import React from "react"
import { Text, Box, Image } from "@chakra-ui/react"
import { Col, Row } from "reactstrap"
import MedicineCard from "./MedicineCard"
import { Sex } from "../WebInterviewPage/types"

type Props = {
  sex: Sex
}

const ThanksWebInterviewPage: React.FC<Props> = (props) => {
  const images = {
    minoxidil: require("assets/minoxidil_2.png"),
    finasteride: require("assets/finasteride_after.png"),
    price_card_for_male: require("./images/price_card_for_male.png"),
    price_card_for_female: require("./images/price_card_for_female.png"),
    plus: require("./images/plus.png"),
  }

  return (
    <>
      <Row>
        <Col
          sx={12}
          sm={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          <Text textAlign="center" fontSize="24px" fontWeight="600">
            送信完了しました
          </Text>
          <Text textAlign="center" fontSize="14px" fontWeight="300">
            事前WEB問診にご協力ありがとうございました。
          </Text>
          <Text textAlign="center" fontSize="14px" fontWeight="300">
            当日のカウンセリングと診療を正確に行わせていただきます。
          </Text>
        </Col>
      </Row>
      <Row>
        <Col
          sx={12}
          sm={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
          className="p-0"
        >
          <Box backgroundColor="#F2F5F9" pt="24px" pb="16px">
            <Text
              textAlign="center"
              fontSize="14px"
              fontWeight="600"
              color="#003F8F"
              mb="0"
            >
              ＼ 医師が処方する ／
            </Text>

            <Text textAlign="center" fontSize="20px" fontWeight="600">
              発毛治療薬の効果について
            </Text>

            {props.sex === "male" || props.sex === null ? (
              <>
                <MedicineCard
                  name="フィナステリド"
                  amount="1.3mg × 1錠"
                  effect="AGAの原因のジヒドロテストステロンの産生を抑制することで、髪の成長サイクルを正常化する"
                  sideEffect="性欲減退・精液減少・勃起不全"
                  imagePath={images.finasteride}
                />
                <Box textAlign="center">
                  <Image src={images.plus} alt="プラスマーク" />
                </Box>
                <MedicineCard
                  name="ミノキシジル"
                  amount="5mg"
                  effect="血管を拡張させて栄養を送ることで、新しい髪の毛を生やす"
                  sideEffect="初期脱毛、多毛症状、動悸、息切れ、むくみ、火照り（稀に生じる症状）"
                  imagePath={images.minoxidil}
                />
              </>
            ) : (
              <MedicineCard
                name="ミノキシジル"
                amount="2.5mg"
                effect="血管を拡張させて栄養を送ることで、新しい髪の毛を生やす効果を持つ。"
                sideEffect="初期脱毛、多毛症状、動悸、息切れ、むくみ、火照り（稀に生じる症状）"
                imagePath={images.minoxidil}
              />
            )}

            <Text textAlign="center" fontSize="20px" fontWeight="600">
              価格について
            </Text>
            {props.sex === "male" || props.sex === null ? (
              <Box
                textAlign="center"
                backgroundColor="#FFF"
                m="16px"
                borderRadius="16px"
              >
                <Image
                  src={images.price_card_for_male}
                  alt={props.sex}
                  h="121px"
                  w="343px"
                />
              </Box>
            ) : (
              <Box
                textAlign="center"
                backgroundColor="#FFF"
                m="16px"
                borderRadius="16px"
              >
                <Image
                  src={images.price_card_for_female}
                  alt={props.sex}
                  h="121px"
                  w="343px"
                />
              </Box>
            )}
          </Box>
        </Col>
      </Row>
    </>
  )
}

export default ThanksWebInterviewPage
