import {
  Delivery,
  PaymentMethod,
  PaymentMethodType,
  ValidationError,
} from "./types"

const deliveryValidation = (delivery: Delivery) => {
  let errMessages: ValidationError = {}
  if (delivery.addressZip.length === 0) {
    errMessages["delivery.addressZip"] = "郵便番号を入力してください"
  }
  if (delivery.addressState.length === 0) {
    errMessages["delivery.addressState"] = "都道府県を入力してください"
  }
  if (delivery.addressCity.length === 0) {
    errMessages["delivery.addressCity"] = "市区町村を入力してください"
  }
  if (delivery.addressLine1.length === 0) {
    errMessages["delivery.addressLine1"] = "番地を入力してください"
  }

  return errMessages
}

const paymentMethodValidation = (paymentMethod: PaymentMethod) => {
  let errMessages: ValidationError = {}
  if (paymentMethod.paymentMethod !== PaymentMethodType.CreditCard) {
    return errMessages
  }
  if (!!paymentMethod.sequence.length) {
    return errMessages
  }
  if (paymentMethod.number.length === 0) {
    errMessages["paymentMethod.number"] =
      "クレジットカード番号を入力してください"
  }
  if (paymentMethod.name.length === 0) {
    errMessages["paymentMethod.name"] =
      "クレジットカードの名前を入力してください"
  }
  if (paymentMethod.expMonth.length === 0) {
    errMessages["paymentMethod.expMonth"] =
      "クレジットカードの有効期限 (月) を入力してください"
  }
  if (paymentMethod.expYear.length === 0) {
    errMessages["paymentMethod.expYear"] =
      "クレジットカードの有効期限 (年) を入力してください"
  }
  if (paymentMethod.cvc.length === 0) {
    errMessages["paymentMethod.cvc"] = "クレジットカードのCVCを入力してください"
  }

  return errMessages
}

const validation = (
  delivery: Delivery,
  paymentMethod: PaymentMethod,
  addAnotherAddress: boolean
) => {
  if (addAnotherAddress) {
    return {
      ...deliveryValidation(delivery),
      ...paymentMethodValidation(paymentMethod),
    } as ValidationError
  } else {
    return {
      ...paymentMethodValidation(paymentMethod),
    } as ValidationError
  }
}

export default validation
