import React, { useState, useEffect } from "react"
import { Card, CardTitle, CardBody, Table } from "reactstrap"
import { InterviewItem } from "../types"
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/accordion"

declare global {
  interface Window {
    dataLayer: any
  }
}

type Props = {
  data: InterviewItem
  handleChange: (id, value) => void
  initialValue?: string | number
  customerId?: string | null
}

const FormPrescriptionMonth: React.FC<Props> = (props) => {
  const [value, setValue] = useState<string | number>()
  const [inputFlg, setInputFlg] = useState(false)
  const handleChange = (e) => {
    if (!inputFlg) {
      window.dataLayer.push({
        event: "item_input",
        itemTitle: props.data.title,
      })
      setInputFlg(true)
    }
    const value = e.target.value
    const id = props.data.id
    setValue(value)
    props.handleChange(id, value)
  }

  useEffect(() => {
    setValue(props.initialValue || "")
  }, [props.initialValue])

  return (
    <div>
      <div key="12">
        <input
          type="radio"
          value="12ヶ月"
          checked={value === "12ヶ月"}
          id={`${props.data.id}_12`}
          className="WebInterview__input-radio"
          onChange={handleChange}
        />
        <label
          htmlFor={`${props.data.id}_12`}
          className="WebInterview__input-radio_label pl-0 flex-column position-relative"
        >
          <p className="w-100 mb-0 font-weight-bold text-center">
            <span className="h3">12</span>
            ヶ月おまとめ便
          </p>
          <p className="w-100 mb-0 text-center font-weight-bold">
            <span className="h4 text-red">7</span>
            <span className="text-red">% OFF</span>
            <span className="ml-3 p-2 badge badge-red">分割可 ○</span>
          </p>
        </label>
      </div>
      <div key="6">
        <input
          type="radio"
          value="6ヶ月"
          checked={value === "6ヶ月"}
          id={`${props.data.id}_6`}
          className="WebInterview__input-radio"
          onChange={handleChange}
        />
        <label
          htmlFor={`${props.data.id}_6`}
          className="WebInterview__input-radio_label pl-0 flex-column position-relative"
        >
          <span className="WebInterview__input-budge">
            <p className="mb-1">
              <span className="h4">一番</span>
            </p>
            <p>
              <span className="h4">人気</span>
            </p>
          </span>
          <p className="w-100 mb-0 font-weight-bold text-center">
            <span className="h3">6</span>
            ヶ月おまとめ便
          </p>
          <p className="w-100 mb-0 text-center font-weight-bold">
            <span className="h4 text-red">5</span>
            <span className="text-red">% OFF</span>
            <span className="text-blue ml-3 p-2 badge badge-red">分割可 ○</span>
          </p>
        </label>
      </div>
      <div key="3" className="mb-4">
        <input
          type="radio"
          value="3ヶ月"
          checked={value === "3ヶ月"}
          id={`${props.data.id}_3`}
          className="WebInterview__input-radio"
          onChange={handleChange}
        />
        <label
          htmlFor={`${props.data.id}_3`}
          className="WebInterview__input-radio_label pl-0 flex-column position-relative"
        >
          <p className="w-100 mb-0 font-weight-bold text-center">
            <span className="h3">3</span>
            ヶ月おまとめ便
          </p>
          <p className="w-100 mb-0 text-center font-weight-bold">
            <span className="h4 text-red">2</span>
            <span className="text-red">% OFF</span>
            <span className="text-blue ml-3 p-2 badge badge-red">分割可 ○</span>
          </p>
        </label>
      </div>

      <Accordion allowToggle className="mb-5 pb-5">
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton className="bg-white border-0 h5">
                  {isExpanded ? "▼" : "▶︎"} その他の支払い方法
                </AccordionButton>
              </h2>
              <AccordionPanel>
                <div key={2}>
                  <input
                    type="radio"
                    value="2ヶ月"
                    checked={value === "2ヶ月"}
                    id={`${props.data.id}_2`}
                    className="WebInterview__input-radio"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor={`${props.data.id}_2`}
                    className="WebInterview__input-radio_label pl-0 flex-column position-relative"
                  >
                    <p className="w-100 mb-0 font-weight-bold text-center">
                      <span className="h3">2</span>
                      ヶ月おまとめ便
                    </p>
                    <p className="w-100 mb-0 text-center font-weight-bold">
                      <span className="h4 text-red">100</span>
                      <span className="text-red">円 OFF</span>
                      <span className="ml-3 p-2 badge badge-red">分割可 ○</span>
                    </p>
                  </label>
                </div>
                <div key={1}>
                  <input
                    type="radio"
                    value="1ヶ月"
                    checked={value === "1ヶ月"}
                    id={`${props.data.id}_1`}
                    className="WebInterview__input-radio"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor={`${props.data.id}_1`}
                    className="WebInterview__input-radio_label pl-0 flex-column position-relative"
                  >
                    <p className="w-100 mb-0 font-weight-bold text-center">
                      <span className="h4">1</span>
                      ヶ月おまとめ便
                    </p>
                    <p className="w-100 mb-0 text-center">
                      <span className="font-weight-bold text-red">
                        割引なし
                      </span>
                      <span className="ml-3 p-2 badge badge-red">分割可 ○</span>
                    </p>
                  </label>
                </div>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>

      <div className="PrescriptionPriceList">
        <div className="h4 mb-3 font-weight-bold text-center">お薬価格表</div>
        <small>
          定番のプランに加えてミノキ増量（7.5mg、10.0mg）プランの３種類の価格表です。
        </small>
        <div className="row flex-nowrap overflow-y-hidden">
          <Card className="mt-3 ml-5 py-3 mr-3 w-75 min-w-75 border border-dark shadow-none">
            <CardBody>
              <CardTitle className="text-center h5 font-weight-bold mb-5">
                <span className="pb-4 border-primary border-bottom-dotted">
                  定番プラン
                </span>
              </CardTitle>
              <Table className="w-75 m-auto">
                <tbody>
                  <tr>
                    <td className="border-0 pb-0 text-nowrap">ミノキシジル</td>
                    <td className="border-0 pb-0">5.0mg</td>
                  </tr>
                  <tr>
                    <td className="border-0 pt-0 text-nowrap">
                      フィナステリド
                    </td>
                    <td className="border-0 pt-0">1.3mg</td>
                  </tr>
                </tbody>
              </Table>
              <Table>
                <tbody className="text-center">
                  <tr>
                    <th
                      scope="row"
                      className="border border-primary text-primary bg-light-blue"
                    >
                      12ヶ月便
                    </th>
                    <td className="border border-primary">¥4,632</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="border border-primary text-primary bg-light-blue"
                    >
                      6ヶ月便
                    </th>
                    <td className="border border-primary">¥4,731</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="border border-primary text-primary bg-light-blue"
                    >
                      3ヶ月便
                    </th>
                    <td className="border border-primary">¥4,881</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="border border-primary text-primary bg-light-blue"
                    >
                      1ヶ月便
                    </th>
                    <td className="border border-primary">¥4,980</td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
          <Card className="mt-3 mr-3 py-3 w-75 min-w-75 border border-dark shadow-none">
            <CardBody>
              <CardTitle className="text-center h5 font-weight-bold mb-4">
                <span className="pb-3">ミノキ増量プラン</span>
                <p className="border-primary border-bottom-dotted">7.5mg</p>
              </CardTitle>
              <Table className="w-75 m-auto">
                <tbody>
                  <tr>
                    <td className="border-0 pb-0 text-nowrap">ミノキシジル</td>
                    <td className="border-0 pb-0">7.5mg</td>
                  </tr>
                  <tr>
                    <td className="border-0 pt-0 text-nowrap">
                      フィナステリド
                    </td>
                    <td className="border-0 pt-0">1.3mg</td>
                  </tr>
                </tbody>
              </Table>
              <Table>
                <tbody className="text-center">
                  <tr>
                    <th
                      scope="row"
                      className="border border-primary text-primary bg-light-blue"
                    >
                      12ヶ月便
                    </th>
                    <td className="border border-primary">¥5,561</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="border border-primary text-primary bg-light-blue"
                    >
                      6ヶ月便
                    </th>
                    <td className="border border-primary">¥5,681</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="border border-primary text-primary bg-light-blue"
                    >
                      3ヶ月便
                    </th>
                    <td className="border border-primary">¥5,860</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="border border-primary text-primary bg-light-blue"
                    >
                      1ヶ月便
                    </th>
                    <td className="border border-primary">¥5,980</td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
          <Card className="mt-3 mr-3 py-3 w-75 min-w-75 border border-dark shadow-none">
            <CardBody>
              <CardTitle className="text-center h5 font-weight-bold mb-4">
                <span className="pb-3">ミノキ増量プラン</span>
                <p className="border-primary border-bottom-dotted">10.0mg</p>
              </CardTitle>
              <Table className="w-75 m-auto">
                <tbody>
                  <tr>
                    <td className="border-0 pb-0 text-nowrap">ミノキシジル</td>
                    <td className="border-0 pb-0">10.0mg</td>
                  </tr>
                  <tr>
                    <td className="border-0 pt-0 text-nowrap">
                      フィナステリド
                    </td>
                    <td className="border-0 pt-0">1.3mg</td>
                  </tr>
                </tbody>
              </Table>
              <Table>
                <tbody className="text-center">
                  <tr>
                    <th
                      scope="row"
                      className="border border-primary text-primary bg-light-blue"
                    >
                      12ヶ月便
                    </th>
                    <td className="border border-primary">¥6,491</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="border border-primary text-primary bg-light-blue"
                    >
                      6ヶ月便
                    </th>
                    <td className="border border-primary">¥6,646</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="border border-primary text-primary bg-light-blue"
                    >
                      3ヶ月便
                    </th>
                    <td className="border border-primary">¥6,840</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="border border-primary text-primary bg-light-blue"
                    >
                      1ヶ月便
                    </th>
                    <td className="border border-primary">¥6,980</td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
      </div>

      <p className="h1 mt-3 mb-3 text-center text-black-50">＋</p>

      <div className="row mb-5">
        <Card className="ml-5 w-75 min-w-75 border border-dark shadow-none">
          <CardBody className="text-center">
            <CardTitle className="h5 font-weight-bold">
              <span className="pb-3">オプション</span>
            </CardTitle>
            <p className="mb-1">ミノキシジル外用薬　5%</p>
            <p className="mb-1">＋ ¥3,980</p>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default FormPrescriptionMonth
