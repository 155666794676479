import React from "react"

import { SchedulableDate as SchedulableDateModel } from "../../types"
import SchedulableDateCounseling from "./SchedulableDateCounseling"
import moment from "moment"

type Props = {
  schedulableDates: SchedulableDateModel[]
  refresh: () => void
  onSelectTime: (date: Date) => void
}

const SchedulableCalenderCounseling: React.FC<Props> = (props) => {
  const { schedulableDates } = props
  const timeArray = schedulableDates[0]
    ? schedulableDates[0].times.map((time) => moment(time.startAt).format("LT"))
    : []
  const month = moment(schedulableDates[0]?.date).format("YYYY年M月")

  const renderTitle = (date: string) => {
    const md = moment(date)
    return (
      <div
        className={
          "text-center d-flex flex-column py-1 " +
          (md.format("dd") === "土" ? "schedulable-calender__date--fri" : "") +
          (md.format("dd") === "日" ? "schedulable-calender__date--sun" : "")
        }
      >
        <div>{md.format("D日")}</div>
        <div
          className={
            (md.format("dd") === "土"
              ? "schedulable-calender__date--fri__text"
              : "") +
            (md.format("dd") === "日"
              ? "schedulable-calender__date--sun__text"
              : "")
          }
        >
          {md.format("dd")}
        </div>
      </div>
    )
  }

  return (
    <div className="schedulable-calender mt-4 mb-4 d-flex flex-column w-100 border-grey-top border-grey-bottom">
      <div className="schedulable-calender__header d-flex">
        <div className="schedulable-calender__header__name py-3 bg-light-grey d-flex align-items-center justify-content-center border-grey-top">
          日時
        </div>
        <div className="schedulable-calender__header__title d-flex flex-column">
          <div className="text-center bg-light-grey border-grey-top">
            {month}
          </div>
          <div className="d-flex">
            {schedulableDates.map((date) => {
              return (
                <div
                  className="schedulable-calender__column border-grey-top"
                  key={date.date}
                >
                  {renderTitle(date.date)}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="schedulable-calender__time text-center d-flex flex-column">
          {timeArray?.map((time) => {
            return (
              <div className="bg-light-grey border-grey-top py-2" key={time}>
                {time}
              </div>
            )
          })}
        </div>
        <div className="schedulable-calender__content d-flex">
          {schedulableDates?.map((schedulableDate) => {
            return (
              <div
                className="schedulable-calender__column"
                key={schedulableDate.date}
              >
                <SchedulableDateCounseling
                  schedulableDate={schedulableDate}
                  onChange={props.refresh}
                  onSelectTime={props.onSelectTime}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SchedulableCalenderCounseling
