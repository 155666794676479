import React, { useState, useEffect } from "react"

import { withRouter, RouteComponentProps } from "react-router-dom"
import { InterviewItem, InputInterviewAnswer } from "../types"
import IndexPresentation from "../indexPresentation"

type Props = {} & RouteComponentProps<{ customerId: string; datetime: string }>

const FirstPurchaseSurveyPage: React.FC<Props> = (props) => {
  const {
    match: { params },
  } = props

  let [errorMessage, setErrorMessage] = useState<string | null>(null)
  let [isHideAlert, setIsHideAlert] = useState<boolean>(true)
  let [interviewItems, setInterviewItems] = useState<Array<InterviewItem>>([])
  let [interviewChildItems, setInterviewChildItems] = useState<
    Array<InterviewItem>
  >([])
  let [inputInterviewAnswers, setInputInterviewAnswers] = useState<
    Array<InputInterviewAnswer>
  >([])
  const [customerId, setCustomerId] = useState<String | null>(null)
  let [canAnswer, setCanAnswer] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-Requested-With", "XMLHttpRequest")

      setCustomerId(params.customerId)

      // アンケート項目の取得
      await fetch(`/api/web_interview_items/first_purchase_survey`, {
        method: "GET",
        headers,
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              setInterviewItems(json.first_purchase_survey_items)
              setInterviewChildItems(json.first_purchase_survey_child_items)
            })
          } else if (response.status === 404) {
            setCanAnswer(false)
            alert("URLに誤りがあります。ご確認のうえ再度アクセスお願いします。")
          } else {
            setCanAnswer(false)
            alert(
              "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
            )
          }
        })
        .catch((error) => {
          console.error("アンケートアイテムの取得に失敗しました: ", error)
          setCanAnswer(false)
          alert("URLに誤りがあります。ご確認のうえ再度アクセスお願いします。")
        })
    })()
  }, [])

  const onSubmit = () => {
    ;(async () => {
      // 複数選択のvalueがArrayになってるのでjoinする
      // Arrayの方が扱いやすい部分もあるのでsubmit時に整形
      const formatted_answers = inputInterviewAnswers.map((answer) => {
        if (Array.isArray(answer.value)) {
          answer.value = answer.value.join()
          return answer
        } else {
          return answer
        }
      })
      const csrfToken = (document
        .getElementsByName("csrf-token")
        .item(0) as HTMLMetaElement).content
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-CSRF-Token", csrfToken)
      const response = await fetch(
        `/api/customers/${customerId}/first_purchase_survey_infos`,
        {
          method: "POST",
          body: JSON.stringify({
            first_purchase_survey_infos: formatted_answers,
          }),
          headers,
        }
      )

      const json = await response.json()
      if (!json.errors) {
        window.location.href = `/thanks_first_purchase_survey`
      } else {
        setErrorMessage(json.errors)
      }
    })()
  }

  const handleErrorMessage = (message) => {
    setErrorMessage(message)
    setIsHideAlert(false)

    new Promise((resolve) => {
      setTimeout(() => {
        setIsHideAlert(true)
        resolve(null)
      }, 5000)
    }).then(() => {
      return setTimeout(() => {
        // isHideAlertがtrueのままだとtransitionが働いてfadeInしてしまうのでリセット
        // ↑ に伴ってerrorMessageをnullにすることで非表示にする
        // fadeOutに1秒かかるので1秒待ってからリセット
        setErrorMessage(null)
        setIsHideAlert(false)
      }, 1000)
    })
  }

  const TopDescription = (
    <>
      <h3 className="WebInterview__Title mb-5">
        {" "}
        サービス改善に関するアンケート
      </h3>
      <p className="mb-2">
        この度はAGAスマクリのご利用を開始いただき誠にありがとうございます。
      </p>
      <p className="mb-4">
        お客様に最適なサービスをご提供するために、ご要望についてアンケートを実施しております。
      </p>
      <p className="mb-2">お忙しい中大変恐縮ではございますが、</p>
      <p className="mb-4">
        3分ほどで終わるアンケートへのご協力をいただけますと幸いです。
      </p>
      <p className="mb-4">
        回答していただいた方の中から抽選で30名様にAmazonギフト券を1000円分をプレゼント致します。
      </p>
      <p className="mb-2">
        *回答内容は今後のサービス改善に役立てさせていただき、それ以外の目的には使用致しません。
      </p>
      <p className="mb-5">
        *利用再開のための無理な勧誘や電話等は一切行いませんのでご安心ください。
      </p>
    </>
  )

  return (
    <>
      <IndexPresentation
        errorMessage={errorMessage}
        buttonText="回答を送信する"
        interviewItems={interviewItems}
        interviewChildItems={interviewChildItems}
        inputInterviewAnswers={inputInterviewAnswers}
        TopDescription={TopDescription}
        imageUploadPath={`/api/customers/${customerId}/test_result_images`}
        isHideAlert={isHideAlert}
        setErrorMessage={handleErrorMessage}
        setInputInterviewAnswers={setInputInterviewAnswers}
        setInterviewItems={setInterviewItems}
        onSubmit={() => onSubmit()}
        canAnswer={canAnswer}
      ></IndexPresentation>
    </>
  )
}

export default withRouter(FirstPurchaseSurveyPage)
