import React, { useEffect, useState } from "react"
import ZipCode from "../../../utils/zipcode"

import {
  Delivery,
  ValidationError,
  ReceivingWay,
  ShipFrom,
  Address,
  Japanese,
} from "./types"

type Props = {
  delivery: Delivery
  validateErrors: ValidationError
  isTrial?: Boolean
  prescriptionKey: string
  addAnotherAddress: boolean
  setAsDefault: boolean
  setSetAsDefault: (setAsDefault: boolean) => void
  setAddAnotherAddress: (addAnotherAddress: boolean) => void
  onChange: (delivery: Delivery) => void
}

const DeliveryForm: React.FC<Props> = (props) => {
  const { delivery, validateErrors } = props
  const [addresses, setAddresses] = useState<Address[]>([])
  const [isFirstPrescription, setIsFirstPrescription] = useState<string>()
  const isTrial = props.isTrial ? true : false

  useEffect(() => {
    fetchAddress()
    fetchIsFirst()
  }, [])

  const fetchAddress = async () => {
    const headers = new Headers()
    headers.set("Accept", "application/json")
    headers.set("Content-Type", "application/json")
    headers.set("X-Requested-With", "XMLHttpRequest")

    const addressList = await fetch("/api/addresses", {
      method: "GET",
      headers,
    }).then((response) => {
      return response.json()
    })
    setAddresses(addressList.addresses)
    // 登録済みのcustomer_addressがなければ新しく登録
    if (delivery.addressZip.length) {
      props.setAddAnotherAddress(true)
    } else {
      props.setAddAnotherAddress(addressList.addresses.length === 0)
    }
  }

  const fetchIsFirst = async () => {
    const headers = new Headers()
    headers.set("Accept", "application/json")
    headers.set("Content-Type", "application/json")
    headers.set("X-Requested-With", "XMLHttpRequest")
    const res = await fetch(
      `/api/prescriptions/${props.prescriptionKey}/is_first_prescription`,
      {
        method: "GET",
        headers,
      }
    ).then((response) => {
      return response.json()
    })
    setIsFirstPrescription(res.is_first_prescription)
  }

  const defaultAddress = addresses.find((address) => address.default)

  const onChangeReceivingWay = (receivingWay: ReceivingWay) => {
    const newReceivingWay = {
      ...delivery,
      receivingWay: receivingWay,
    }
    props.onChange(newReceivingWay)
  }

  const onChangeShipFrom = (shipFrom: ShipFrom) => {
    const newShipFrom = {
      ...delivery,
      shipFrom: shipFrom,
    }
    props.onChange(newShipFrom)
  }

  const onChangeZip = (zip: string) => {
    ZipCode.search(zip).then((addr) => {
      const newDelivery = {
        ...delivery,
        addressZip: addr.zip,
        addressState: addr.region,
        addressCity: addr.locality + addr.street,
        addressLine1: "",
        addressLine2: "",
      }
      props.onChange(newDelivery)
    })
    const newDelivery = {
      ...delivery,
      addressZip: zip,
    }
    props.onChange(newDelivery)
  }

  const onChange = (key: string, value: string) => {
    const newDelivery = {
      ...delivery,
      [key]: value,
    }
    props.onChange(newDelivery)
  }

  return (
    <section>
      <h3 className="mt-5">{isTrial ? "配送先情報" : "購入情報"}</h3>
      {defaultAddress && (
        <div>
          <p className="ml-3">
            {defaultAddress.address_zip}
            <br />
            {defaultAddress.address_state}
            {defaultAddress.address_city}
            <br />
            {defaultAddress.address_line1}
            {defaultAddress.address_line2}
            <br />
            お届け先：{Japanese[defaultAddress.receiving_way]}
            <br />
            発送元名：{Japanese[defaultAddress.ship_from]}
          </p>
          <label htmlFor="addAnotherAddress" className="d-flex">
            <input
              checked={props.addAnotherAddress}
              name="addAnotherAddress"
              type="checkbox"
              id="addAnotherAddress"
              style={{ height: "20px", width: "20px" }}
              onChange={() =>
                props.setAddAnotherAddress(!props.addAnotherAddress)
              }
            />
            <span
              className="font-weight-bold ml-2"
              style={{ lineHeight: "20px" }}
            >
              配送先を変更する
            </span>
          </label>
        </div>
      )}
      {props.addAnotherAddress && (
        <div>
          <div className="form-group row mt-4">
            <label className="col-form-label col-md-2 font-weight-bold">
              お受け取り方法
            </label>
            <div className="col-md-10">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="receivingTypeHome"
                  name="receiving_way"
                  checked={delivery.receivingWay === ReceivingWay.AtHome}
                  onChange={() => onChangeReceivingWay(ReceivingWay.AtHome)}
                />
                <label className="form-check-label" htmlFor="receivingTypeHome">
                  ご自宅
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="receivingTypeCenter"
                  name="receiving_way"
                  checked={
                    delivery.receivingWay === ReceivingWay.DeliveryCenterPickup
                  }
                  onChange={() =>
                    onChangeReceivingWay(ReceivingWay.DeliveryCenterPickup)
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor="receivingTypeCenter"
                >
                  ヤマト運輸営業所
                </label>
              </div>
            </div>
            <div className="col">
              <p>
                ※営業所の場合は、ご自宅近くの営業所へ発送し、配送メールにて営業所のご案内をいたします
              </p>
            </div>
          </div>
          <div className="form-group row mt-4">
            <label className="col-form-label col-md-2 font-weight-bold">
              発送元名
            </label>
            <div className="col-md-10">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="shipFromAgaSmartClinic"
                  name="ship_from"
                  checked={delivery.shipFrom === ShipFrom.AgaSmartClinic}
                  onChange={() => onChangeShipFrom(ShipFrom.AgaSmartClinic)}
                />
                <label
                  className="form-check-label"
                  htmlFor="shipFromAgaSmartClinic"
                >
                  AGAスマクリ（医療法人社団ウェルパートナー）
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="shipFromWellPartner"
                  name="ship_from"
                  checked={delivery.shipFrom === ShipFrom.WellPartner}
                  onChange={() => onChangeShipFrom(ShipFrom.WellPartner)}
                />
                <label
                  className="form-check-label"
                  htmlFor="shipFromWellPartner"
                >
                  医療法人社団ウェルパートナー
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="shipFromDrTashiro"
                  name="ship_from"
                  checked={delivery.shipFrom === ShipFrom.DrTashiro}
                  onChange={() => onChangeShipFrom(ShipFrom.DrTashiro)}
                />
                <label className="form-check-label" htmlFor="shipFromDrTashiro">
                  田代友里子
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="font-weight-bold">ご自宅の住所</p>
              <p>※営業所をご希望される方も、ご自宅の住所をご記入ください</p>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-md-5">
              郵便番号 (例：100-0005)
            </label>
            <div className="col-md-2">
              <input
                className={`form-control${
                  validateErrors["delivery.addressZip"] ? " is-invalid" : ""
                }`}
                value={delivery.addressZip}
                onChange={(e) => onChangeZip(e.target.value)}
                required
              />
              {validateErrors["delivery.addressZip"] && (
                <p className="text-danger">
                  {validateErrors["delivery.addressZip"]}
                </p>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-md-5">
              都道府県 (例：東京都)
            </label>
            <div className="col-md-7">
              <input
                className={`form-control${
                  validateErrors["delivery.addressState"] ? " is-invalid" : ""
                }`}
                value={delivery.addressState}
                onChange={(e) => onChange("addressState", e.target.value)}
                required
              />
              {validateErrors["delivery.addressState"] && (
                <p className="text-danger">
                  {validateErrors["delivery.addressState"]}
                </p>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-md-5">
              市区町村 町名 (例：千代田区丸の内)
            </label>
            <div className="col-md-7">
              <input
                className={`form-control${
                  validateErrors["delivery.addressCity"] ? " is-invalid" : ""
                }`}
                value={delivery.addressCity}
                onChange={(e) => onChange("addressCity", e.target.value)}
                required
              />
              {validateErrors["delivery.addressCity"] && (
                <p className="text-danger">
                  {validateErrors["delivery.addressCity"]}
                </p>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-md-5">番地 (例：1-1)</label>
            <div className="col-md-7">
              <input
                className={`form-control${
                  validateErrors["delivery.addressLine1"] ? " is-invalid" : ""
                }`}
                value={delivery.addressLine1}
                onChange={(e) => onChange("addressLine1", e.target.value)}
              />
              {validateErrors["delivery.addressLine1"] && (
                <p className="text-danger">
                  {validateErrors["delivery.addressLine1"]}
                </p>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-md-5">
              マンション・部屋番号 (例：スマクリマンション 101)
            </label>
            <div className="col-md-7">
              <input
                className={`form-control${
                  validateErrors["delivery.addressLine2"] ? " is-invalid" : ""
                }`}
                value={delivery.addressLine2}
                onChange={(e) => onChange("addressLine2", e.target.value)}
              />
              {validateErrors["delivery.addressLine2"] && (
                <p className="text-danger">
                  {validateErrors["delivery.addressLine2"]}
                </p>
              )}
            </div>
          </div>
          {!isFirstPrescription && (
            <label htmlFor="setAsDefault" className="d-flex">
              <input
                checked={props.setAsDefault}
                name="setAsDefault"
                type="checkbox"
                id="setAsDefault"
                style={{ height: "20px", width: "20px" }}
                onChange={() => props.setSetAsDefault(!props.setAsDefault)}
              />
              <span
                className="font-weight-bold ml-2"
                style={{ lineHeight: "20px" }}
              >
                次回以降も全てこの住所へ届ける
              </span>
            </label>
          )}
        </div>
      )}
    </section>
  )
}

export default DeliveryForm
