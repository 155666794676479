import React, { useState, useEffect } from "react"

import { withRouter, RouteComponentProps } from "react-router-dom"
import { InterviewItem, InputInterviewAnswer } from "../types"
import IndexPresentation from "../indexPresentation"

type Props = {} & RouteComponentProps<{
  customerId: string
  intermediateMedicalExaminationId: string
  datetime: string
}>

const WebInterviewPage: React.FC<Props> = (props) => {
  const {
    match: { params },
  } = props

  let [errorMessage, setErrorMessage] = useState<string | null>(null)
  let [isHideAlert, setIsHideAlert] = useState<boolean>(true)
  let [interviewItems, setInterviewItems] = useState<Array<InterviewItem>>([])
  let [interviewChildItems, setInterviewChildItems] = useState<
    Array<InterviewItem>
  >([])
  let [inputInterviewAnswers, setInputInterviewAnswers] = useState<
    Array<InputInterviewAnswer>
  >([])
  const [addressInfo, setAddressInfo] = useState<object>({})
  const [customerId, setCustomerId] = useState<String | null>(null)
  const [
    intermediateMedicalExamId,
    setIntermediateMedicalExamId,
  ] = useState<String | null>(null)
  let [canAnswer, setCanAnswer] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-Requested-With", "XMLHttpRequest")

      setCustomerId(params.customerId)

      // 中間診療の一時データを作成
      if (!params.intermediateMedicalExaminationId) {
        const response = await fetch(
          `/api/customers/${params.customerId}/intermediate_medical_examinations/temporary_create`,
          {
            method: "POST",
            headers,
          }
        )
        const json = await response.json()

        setIntermediateMedicalExamId(json.intermediate_medical_examination_id)
      } else {
        setIntermediateMedicalExamId(params.intermediateMedicalExaminationId)
      }

      // アンケート項目の取得
      await fetch(`/api/web_interview_items/intermediate_medical_examination`, {
        method: "GET",
        headers,
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              setInterviewItems(json.intermediate_examination_items)
              setInterviewChildItems(json.intermediate_examination_child_items)
            })
          } else if (response.status === 404) {
            setCanAnswer(false)
            alert("URLに誤りがあります。ご確認のうえ再度アクセスお願いします。")
          } else {
            setCanAnswer(false)
            alert(
              "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
            )
          }
        })
        .catch((error) => {
          setCanAnswer(false)
          alert("通信状態をお確かめの上、再度アクセスお願いします。")
        })
    })()
  }, [])

  const onSubmit = () => {
    ;(async () => {
      // 複数選択のvalueがArrayになってるのでjoinする
      // Arrayの方が扱いやすい部分もあるのでsubmit時に整形
      const formatted_answers = inputInterviewAnswers.map((answer) => {
        if (Array.isArray(answer.value)) {
          answer.value = answer.value.join()
          return answer
        } else {
          return answer
        }
      })

      const csrfToken = (document
        .getElementsByName("csrf-token")
        .item(0) as HTMLMetaElement).content
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-CSRF-Token", csrfToken)
      const response = await fetch(
        `/api/intermediate_medical_examinations/${intermediateMedicalExamId}/intermediate_medical_examination_infos`,
        {
          method: "POST",
          body: JSON.stringify({
            intermediate_medical_examination_infos: formatted_answers,
            address_info: addressInfo,
          }),
          headers,
        }
      )

      if (response.status === 201 || response.status === 200) {
        const json = await response.json()

        if (json.side_effect_medical_examination_needed) {
          window.location.href = `/customer_schedule_intermediate_medical_examinations/${customerId}?intermediateMedicalExaminationId=${intermediateMedicalExamId}`
        } else {
          window.location.href = `/thanks_web_interview`
        }
        return
      } else if (response.status >= 400) {
        setErrorMessage(`お客様は既に診療予約済みです。
        予約時間の変更は予約確定メールのURLもしくは、当院までお電話ください。
        03-6630-5214`)
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        return
      }
      const json = await response.json()
      handleErrorMessage(json.errors)
    })()
  }

  const handleErrorMessage = (message) => {
    setErrorMessage(message)
    setIsHideAlert(false)

    new Promise((resolve) => {
      setTimeout(() => {
        setIsHideAlert(true)
        resolve(null)
      }, 5000)
    }).then(() => {
      return setTimeout(() => {
        // isHideAlertがtrueのままだとtransitionが働いてfadeInしてしまうのでリセット
        // ↑ に伴ってerrorMessageをnullにすることで非表示にする
        // fadeOutに1秒かかるので1秒待ってからリセット
        setErrorMessage(null)
        setIsHideAlert(false)
      }, 1000)
    })
  }

  const TopDescription = (
    <>
      <h3 className="WebInterview__Title mb-5"> 経過観察WEB問診票</h3>
      <div className="row">
        <p className="mb-3 offset-md-3 col-md-6 col-sm-12">
          AGA治療のパートナーとして当院をお選びいただきありがとうございます。
        </p>
        <p className="mb-4 offset-md-3 col-md-6 col-sm-12">
          3ヶ月ごとに体調の変化がないかご確認させていただくため、ご記入をお願いいたします。
        </p>
      </div>
    </>
  )

  return (
    <>
      <IndexPresentation
        errorMessage={errorMessage}
        interviewItems={interviewItems}
        interviewChildItems={interviewChildItems}
        inputInterviewAnswers={inputInterviewAnswers}
        TopDescription={TopDescription}
        imageUploadPath={`/api/customers/${customerId}/test_result_images`}
        isHideAlert={isHideAlert}
        setErrorMessage={handleErrorMessage}
        setInputInterviewAnswers={setInputInterviewAnswers}
        setAddressInfo={setAddressInfo}
        setInterviewItems={setInterviewItems}
        onSubmit={() => onSubmit()}
        canAnswer={canAnswer}
      ></IndexPresentation>
    </>
  )
}

export default withRouter(WebInterviewPage)
