import React, { useState, useEffect } from "react"
import { Alert, Button, Form } from "reactstrap"
import { withRouter, RouteComponentProps } from "react-router-dom"
import moment from "moment"

import { createRequestHeadersForCors } from "../../utils/request_headers"
import { Reservation } from "./types"

type Props = {
  medicalExaminationId: string
  customerId: string
  startAt: Date
} & RouteComponentProps<{}>

const ReservationForm: React.FC<Props> = (props) => {
  const { medicalExaminationId, customerId, startAt, history } = props
  const [reservation, setReservation] = useState<Reservation>({
    startAt: new Date(),
  } as Reservation)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [examinationType, setExaminationType] = useState<String | null>(null)

  const overwriteMedicalExamination = () => {
    fetch(
      `/api/customers/${customerId}/medical_examinations/${medicalExaminationId}`,
      {
        method: "PATCH",
        body: JSON.stringify({
          reservation_at: reservation.startAt.toISOString(),
          examination_type: examinationType,
        }),
        headers: createRequestHeadersForCors(),
      }
    ).then((response) => {
      setSubmitting(false)
      if (response.ok) {
        history.push(
          `/thanks_medical_examination_appointment${
            window.location.search
          }/${moment(reservation.startAt.toISOString()).format("YYYYMMDDHHmm")}`
        )
        return
      }
      setErrorMessage(
        "予約の作成に失敗しました。時間枠を変更して再度予約をお願いします。"
      )
    })
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (submitting) {
      return
    }

    setSubmitting(true)
    overwriteMedicalExamination()
  }

  useEffect(() => {
    ;(async () => {
      const response = await fetch(
        `/api/medical_examinations/${medicalExaminationId}/medical_examination_infos`,
        {
          method: "GET",
          headers: createRequestHeadersForCors(),
        }
      )

      if (response.ok) {
        const json = await response.json()

        if (
          json.medical_examination_infos.find(
            (i) => i.title === "相談事項" && i.value === "あり"
          )
        ) {
          setExaminationType("examination")
        } else if (
          json.medical_examination_infos.find(
            (i) => i.title === "増量・変更希望" && i.value === "希望する"
          )
        ) {
          setExaminationType("increase_drug")
        } else setExaminationType("examination")
      } else {
        window.alert(
          "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
        )
      }
    })()
  }, [medicalExaminationId])

  useEffect(() => {
    setErrorMessage(null)
    setReservation({
      startAt: startAt,
    } as Reservation)
  }, [startAt])

  return (
    examinationType && (
      <Form className="mt-4" onSubmit={onSubmitHandler}>
        {errorMessage && <Alert color="warning">{errorMessage}</Alert>}
        <Button
          type="submit"
          color="main"
          block
          className="mt-4"
          disabled={submitting}
        >
          予約変更確定
        </Button>
      </Form>
    )
  )
}

export default withRouter(ReservationForm)
