import React from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  CardSubtitle,
  CardText,
  CardLink,
  Button,
} from "reactstrap"
import { RouteComponentProps } from "react-router-dom"
import { saveAs } from "file-saver"
const ics = require("ics")

type Props = {
  starttime: Date
} & RouteComponentProps<{}>

type Params = {
  starttime: string
}

const ThanksIntermediateMedicalExaminationAppointmentPage: React.FC<Props> = (
  props
) => {
  const params: Params = props.match.params as Params
  let time = params.starttime
  const YEAR = time.substring(0, 4)
  const MONTH = time.substring(4, 6)
  const DAY = time.substring(6, 8)
  const HOUR = time.substring(8, 10)
  const MINUTE = time.substring(10)
  const date = new Date(`${YEAR}/${MONTH}/${DAY} ${HOUR}:${MINUTE}`)
  const dayOfWeek = date.getDay()
  const dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek]
  let END_MINUTE: number | string = +MINUTE + 10
  let END_HOUR = +HOUR
  if (END_MINUTE === 60) {
    END_HOUR += 1
    END_MINUTE = "00"
  }
  // カレンダー機能
  const GOOGLE_CALENDAR_URL = `http://www.google.com/calendar/event?action=TEMPLATE&text=【AGAスマクリ】診療予約&dates=${YEAR}${MONTH}${DAY}T${HOUR}${MINUTE}00/${YEAR}${MONTH}${DAY}T${END_HOUR}${END_MINUTE}00`

  // icsファイル作成とダウンロード
  const createICSfile = () => {
    ics.createEvent(
      {
        title: "【AGAスマクリ】診療予約",
        description: "",
        busyStatus: "FREE",
        start: [+YEAR, +MONTH, +DAY, +HOUR, +MINUTE],
        duration: { minutes: 10 },
      },
      (error, value) => {
        if (error) {
          console.log(error)
        }
        const blob = new Blob([value])
        saveAs(blob, "calendar.ics")
      }
    )
  }

  return (
    <Row className="mt-4">
      <Col
        sx={12}
        sm={{ size: 10, offset: 1 }}
        md={{ size: 8, offset: 2 }}
        lg={{ size: 6, offset: 3 }}
      >
        <Card>
          <CardBody className="text-center">
            <CardTitle tag="h5" className="font-weight-bold mb-3">
              ご予約ありがとうございます
            </CardTitle>
            <CardSubtitle tag="h5" className="mb-2 font-weight-bold mb-3">
              【診療時間】
            </CardSubtitle>
            <CardText className="font-weight-bold mb-3 h5">
              {YEAR}年{MONTH}月{DAY}日 ({dayOfWeekStr}) {HOUR}:{MINUTE}~
            </CardText>
            <p className="p-1 mb-1 bg-warning mb-3"></p>
            <CardText>当日は何卒よろしくお願いいたします</CardText>
            <CardText>
              下記よりご自身のカレンダーに予約を登録することが可能です
            </CardText>
          </CardBody>
          <CardBody className="text-center mb-3">
            <CardLink
              href={GOOGLE_CALENDAR_URL}
              className="bg-warning text-white square rounded p-3"
            >
              Googleカレンダーに予定を追加する
            </CardLink>
          </CardBody>
          <CardBody className="text-center mb-3">
            <Button
              onClick={createICSfile}
              className="bg-warning text-white square rounded p-3"
            >
              iPhoneカレンダーに予定を追加する
            </Button>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ThanksIntermediateMedicalExaminationAppointmentPage
