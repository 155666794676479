import React, { useState, useEffect } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row,
} from "reactstrap"
import { withRouter, RouteComponentProps } from "react-router-dom"
import moment from "moment"
import { createRequestHeadersForCors } from "../../utils/request_headers"

import ReservationForm from "./ReservationForm"
import { Customer, ScheduleType } from "../../types"
import ScrollToTop from "../../../utils/ScrollToTop"

type Props = {} & RouteComponentProps<{
  medicalExaminationId: string
  customerId: string
  datetime: string
}>

const OverwriteMedicalExaminationConfirmPage: React.FC<Props> = (props) => {
  const {
    history,
    match: { params },
  } = props
  const [customer, setCustomer] = useState<Customer | null>(null)

  const startAt = moment(params.datetime, "YYYYMMDDHHmm")
  // FIXME: 本来 endAt は 前のページから props で渡したい
  const [endAt, setEndAt] = useState<Date | null>(null)

  useEffect(() => {
    ;(async () => {
      const response = await fetch(`/api/customers/${params.customerId}`)
      const customer = await response.json()
      setCustomer(customer)
    })()
  }, [params.customerId])

  useEffect(() => {
    ;(async () => {
      let scheduleType = ScheduleType.medicalExamination.toString()

      const response = await fetch(
        `/api/medical_examinations/${params.medicalExaminationId}/medical_examination_infos`,
        {
          method: "GET",
          headers: createRequestHeadersForCors(),
        }
      )

      if (response.ok) {
        const json = await response.json()

        if (
          json.medical_examination_infos.find(
            (i) => i.title === "相談事項" && i.value === "あり"
          )
        ) {
          scheduleType = ScheduleType.medicalExamination.toString()
        } else if (
          json.medical_examination_infos.find(
            (i) => i.title === "増量・変更希望" && i.value === "希望する"
          )
        ) {
          scheduleType = ScheduleType.increaseDrugExamination.toString()
        } else scheduleType = ScheduleType.medicalExamination.toString()
      } else {
        window.alert(
          "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
        )
      }

      const searchParams = new URLSearchParams({
        type: scheduleType,
        target_date: startAt.toISOString(),
        customer_id: params.customerId,
      })
      const res = await fetch(
        `/api/schedulable_times_one_days?${searchParams.toString()}`
      )
      const schedule = await res.json()
      const selectedTime = schedule.schedulable_times.times.find((time) => {
        return new Date(time.start_at).toISOString() === startAt.toISOString()
      })
      if (selectedTime) {
        setEndAt(new Date(selectedTime.end_at))
      }
    })()
  }, [])

  const backSelectSchedule = () => {
    history.goBack()
  }

  return (
    endAt &&
    customer && (
      <>
        <ScrollToTop />
        <h3 className="text-center">{customer.name}様 診療予約変更</h3>
        <Row className="mt-4">
          <Col
            sx={12}
            sm={{ size: 10, offset: 1 }}
            md={{ size: 8, offset: 2 }}
            lg={{ size: 6, offset: 3 }}
          >
            <Card>
              <CardBody>
                <CardTitle className="text-muted">
                  {startAt.format("YYYY/MM/DD")}
                </CardTitle>
                <CardText className="h6">
                  {`${startAt.format("HH:mm")} 〜 ${moment(endAt).format(
                    "HH:mm"
                  )}`}
                </CardText>
                <CardText className="h6">
                  <Button onClick={() => backSelectSchedule()}>
                    別の日時で予約する
                  </Button>
                </CardText>
              </CardBody>
            </Card>
            <ReservationForm
              medicalExaminationId={params.medicalExaminationId}
              customerId={params.customerId}
              startAt={startAt.toDate()}
            />
          </Col>
        </Row>
      </>
    )
  )
}

export default withRouter(OverwriteMedicalExaminationConfirmPage)
