import React, { useState, useEffect } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { Customer, ScheduleType } from "../../types"
import ScheduleCalendar from "../ScheduleCalendar"
import moment from "moment"

type Props = {} & RouteComponentProps<{
  unableToPrescribeMedicalExaminationId: string
  customerId: string
}>

const OverwriteUnableToPrescribeMedicalExaminationPage: React.FC<Props> = (
  props
) => {
  const {
    history,
    match: { params },
  } = props
  const [customer, setCustomer] = useState<Customer | null>(null)

  const onSelectTimeHandler = (date: Date) => {
    history.push(
      `/customer_schedule_un_medical_examinations/overwrite/${
        params.unableToPrescribeMedicalExaminationId
      }/${params.customerId}/${moment(date).format("YYYYMMDDHHmm")}${
        window.location.search
      }`
    )
  }

  useEffect(() => {
    ;(async () => {
      const response = await fetch(`/api/customers/${params.customerId}`)
      const customer = await response.json()
      setCustomer(customer)
    })()
  }, [params])

  if (customer === null) {
    return null
  }

  return (
    <>
      <div className="text-center reservation-title">予約フォーム</div>
      <div className="text-center">ご希望の日時をご選択ください</div>
      <ScheduleCalendar
        type={ScheduleType.UnableToPrescribe}
        onSelectTime={onSelectTimeHandler}
      />
    </>
  )
}

export default withRouter(OverwriteUnableToPrescribeMedicalExaminationPage)
