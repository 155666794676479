import React from "react"

import IntraDayHours from "./IntraDayHours"
import type { ScheduleType } from "../../types"
import { SchedulableDate as SchedulableDateModel } from "../../types"

type Props = {
  type: ScheduleType
  currentDay: Date
  times: SchedulableDateModel
  onSelectTime: (date: Date) => void
}

type SchedulableDateResponse = {
  schedulable_times: any[]
}

const ScheduleIntraDay: React.FC<Props> = (props) => {
  const { type, currentDay, times } = props

  let currentWeekDay = 0

  const currentYear = currentDay.getFullYear()
  const currentMonth = currentDay.getMonth()
  const currentDate = currentDay.getDate()

  const week = ["日", "月", "火", "水", "木", "金", "土"]

  const renderSchedulableTimes = () => {
    let count = 0
    let startDayOfWeek = new Date(currentYear, currentMonth, 1).getDay()
    let endDate = new Date(currentYear, currentMonth + 1, 0).getDate()
    let row = Math.ceil((startDayOfWeek + endDate) / week.length)

    // row
    for (let i = 0; i < row; i++) {
      // column
      for (let j = 0; j < week.length; j++) {
        if (i === 0 && j < startDayOfWeek) {
        } else if (count >= endDate) {
          count++
        } else {
          // 曜日に合わせて設定
          count++
          if (count === currentDate) {
            currentWeekDay = j
          } else {
          }
        }
      }
    }
    /*曜日の判定はクリック日時の「tdが何番目か」判別すればweekから設定可能 */
    return (
      <section className="intra-day-wrapper">
        <div id="intra-day-header">
          {currentMonth +
            1 +
            "月" +
            currentDate +
            "日" +
            week[currentWeekDay] +
            "曜日の予約可能時間"}
        </div>
        <div id="intra-day">
          <IntraDayHours
            key={1}
            times={times}
            currentDay={currentDay}
            onSelectTime={props.onSelectTime}
          ></IntraDayHours>
        </div>
      </section>
    )
  }

  return renderSchedulableTimes()
}

export default ScheduleIntraDay
