import React, { useState, useEffect } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row,
} from "reactstrap"
import { withRouter, RouteComponentProps } from "react-router-dom"
import moment from "moment"

import ReservationForm from "./ReservationForm"
import { Customer } from "../../types"

type Props = {} & RouteComponentProps<{
  intermediateMedicalExaminationId: string
  customerId: string
  datetime: string
}>

const OverwriteIntermediateMedicalExaminationConfirmPage: React.FC<Props> = (
  props
) => {
  const {
    history,
    match: { params },
  } = props
  const [customer, setCustomer] = useState<Customer | null>(null)

  const startAt = moment(params.datetime, "YYYYMMDDHHmm")
  const endAt = startAt.clone().add(15, "minutes")

  useEffect(() => {
    ;(async () => {
      const response = await fetch(`/api/customers/${params.customerId}`)
      const customer = await response.json()

      setCustomer(customer)
    })()
  }, [params])

  if (customer === null) {
    return null
  }

  const backSelectSchedule = () => {
    history.goBack()
  }

  return (
    <>
      <h3 className="text-center">{customer.name}様 診療予約変更</h3>
      <Row className="mt-4">
        <Col
          sx={12}
          sm={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          <Card>
            <CardBody>
              <CardTitle className="text-muted">
                {startAt.format("YYYY/MM/DD")}
              </CardTitle>
              <CardText className="h6">
                {startAt.format("HH:mm")} 〜 {endAt.format("HH:mm")}
              </CardText>
              <CardText className="h6">
                <Button onClick={() => backSelectSchedule()}>
                  別の日時で予約する
                </Button>
              </CardText>
            </CardBody>
          </Card>
          <ReservationForm
            intermediateMedicalExaminationId={
              params.intermediateMedicalExaminationId
            }
            customerId={params.customerId}
            startAt={startAt.toDate()}
          />
        </Col>
      </Row>
    </>
  )
}

export default withRouter(OverwriteIntermediateMedicalExaminationConfirmPage)
