import React from "react"
import { Col, Row } from "reactstrap"
import moment from "moment"

import SchedulableTime from "./SchedulableTime"
import type { ScheduleType } from "../../types"
import { SchedulableDate as SchedulableDateModel } from "../../types"

type Props = {
  type: ScheduleType
  schedulableDate: SchedulableDateModel
  onChange: () => void
  onSelectTime: (date: Date) => void
}

const SchedulableDate: React.FC<Props> = (props) => {
  const { type, schedulableDate } = props

  let noScheduleText: string
  if (type === "counseling" || type === "fci") {
    noScheduleText = "カウンセリング枠の空きはございません"
  } else {
    noScheduleText = "診療枠の空きはございません"
  }

  const renderTitle = (date: string) => {
    const md = moment(date)
    return (
      <h3 className="text-center d-none d-md-block">
        {md.format("D")}
        <small
          className={
            "h6 ml-1h6 ml-1 " +
            (md.format("dd") === "土" ? "color-blue" : "") +
            (md.format("dd") === "日" ? "color-red" : "")
          }
        >
          {md.format("dd")}
        </small>
      </h3>
    )
  }

  const renderSpTitle = (date: string) => {
    const md = moment(date)
    return (
      <h3 className="text-center d-md-none h5 text-black-50 border-bottom mt-2 pb-2">
        {md.format("YYYY/M/D")}
        <small
          className={
            "h6 ml-1 " +
            (md.format("dd") === "土" ? "color-blue" : "") +
            (md.format("dd") === "日" ? "color-red" : "")
          }
        >
          ({md.format("dd")})
        </small>
      </h3>
    )
  }

  const isAvailableTime = (times) => {
    var i = 0
    for (i; i < times.length; i++) {
      if (times[i]["isSchedulable"] === true) {
        return true
      }
    }
    return false
  }

  return (
    <div className="flex-fill px-3">
      {renderTitle(schedulableDate.date)}
      <div className="d-none d-md-flex flex-wrap">
        {schedulableDate.times.map((schedulableTime) => (
          <SchedulableTime
            key={schedulableTime.startAt.toISOString()}
            schedulableTime={schedulableTime}
            onSelectTime={() => props.onSelectTime(schedulableTime.startAt)}
          />
        ))}
      </div>

      {renderSpTitle(schedulableDate.date)}
      {isAvailableTime(schedulableDate.times) ? (
        <Row className="d-md-none">
          {schedulableDate.times.map((schedulableTime) => (
            <Col
              key={schedulableTime.startAt.toISOString()}
              xs={4}
              sm={3}
              className={`${!schedulableTime.isSchedulable}d-md-block`}
            >
              <SchedulableTime
                key={schedulableTime.startAt.toISOString()}
                schedulableTime={schedulableTime}
                onSelectTime={() => props.onSelectTime(schedulableTime.startAt)}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <p className="text-center d-md-none">{noScheduleText}</p>
      )}
    </div>
  )
}

export default SchedulableDate
