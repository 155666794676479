import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps, useLocation } from "react-router-dom"
import moment from "moment"

import ScheduleCalendar from "../ScheduleCalendar"
import { Customer, ScheduleType } from "../../types"

type Props = {} & RouteComponentProps<{
  customerId: string
  intermediateMedicalExaminationId: string
}>

const ScheduleCustomerIntermediateMedicalExaminationPage: React.FC<Props> = (
  props
) => {
  const {
    history,
    match: { params },
  } = props
  const [customer, setCustomer] = useState<Customer | null>(null)

  const search = useLocation().search
  const query = new URLSearchParams(search)
  const intermediateMedicalExaminationId = query.get(
    "intermediateMedicalExaminationId"
  )

  const onSelectTimeHandler = (date) => {
    history.push(
      `/customer_schedule_intermediate_medical_examinations/${
        params.customerId
      }/${moment(date).format("YYYYMMDDHHmm")}${
        window.location.search
      }?intermediateMedicalExaminationId=${intermediateMedicalExaminationId}`
    )
  }

  useEffect(() => {
    ;(async () => {
      const response = await fetch(`/api/customers/${params.customerId}`)
      const customer = await response.json()
      setCustomer(customer)
    })()
  }, [params])

  if (customer === null) {
    return null
  }

  return (
    <>
      <div className="text-center reservation-title">予約フォーム</div>
      <div className="text-center">ご希望の日時をご選択ください</div>
      <ScheduleCalendar
        type={ScheduleType.intermediateExamination}
        onSelectTime={onSelectTimeHandler}
      />
    </>
  )
}

export default withRouter(ScheduleCustomerIntermediateMedicalExaminationPage)
