import React, { useState, useEffect } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row,
} from "reactstrap"
import { withRouter, RouteComponentProps } from "react-router-dom"
import moment from "moment"

import ReservationForm from "./ReservationForm"
import { Customer } from "../../types"
import ScrollToTop from "../../../utils/ScrollToTop"

type Props = {} & RouteComponentProps<{
  counselingId: string
  customerId: string
  datetime: string
}>

const OverwriteCounselingConfirmPage: React.FC<Props> = (props) => {
  const {
    history,
    match: { params },
  } = props
  const [customer, setCustomer] = useState<Customer | null>(null)

  useEffect(() => {
    ;(async () => {
      const response = await fetch(`/api/customers/${params.customerId}`)
      const customer = await response.json()
      setCustomer(customer)
    })()
  }, [params.customerId])

  if (customer === null) {
    return null
  }

  let minutes: number
  let changeReservationLabel: string
  if (customer.course === "FCI") {
    minutes = 10
    changeReservationLabel = "カウンセリング予約変更"
  } else if (customer.course === "counseling_by_doctor") {
    minutes = 20
    changeReservationLabel = "診療予約変更"
  } else {
    minutes = 10
    changeReservationLabel = "カウンセリング予約変更"
  }

  const startAt = moment(params.datetime, "YYYYMMDDHHmm")
  const endAt = startAt.clone().add(minutes, "minutes")

  const backSelectSchedule = () => {
    history.goBack()
  }

  return (
    <>
      <ScrollToTop />
      <h2 className="text-center">
        {customer.name}様 {changeReservationLabel}
      </h2>
      <Row className="mt-4">
        <Col
          sx={12}
          sm={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          <Card>
            <CardBody>
              <CardTitle className="text-muted">
                {startAt.format("YYYY/MM/DD")}
              </CardTitle>
              <CardText className="h6">
                {startAt.format("HH:mm")} 〜 {endAt.format("HH:mm")}
              </CardText>
              <CardText className="h6">
                <Button onClick={() => backSelectSchedule()}>
                  別の日時で予約する
                </Button>
              </CardText>
            </CardBody>
          </Card>
          <ReservationForm
            startAt={startAt.toDate()}
            endAt={endAt.toDate()}
            customerId={params.customerId}
            customerCourse={customer.course}
            counselingId={params.counselingId}
          />
        </Col>
      </Row>
    </>
  )
}

export default withRouter(OverwriteCounselingConfirmPage)
