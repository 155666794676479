import React, { useState } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"

import { Customer } from "../../types"
import ReservationFormButton from "../../layout/ReservationFormButton"
import { createRequestHeadersForCors } from "../../utils/request_headers"

const LP_COMPLETE_URL = process.env.LP_COMPLETE_URL

type Props = {
  startAt: Date
  customerId: string
  customerCourse: Customer["course"]
  customerEmail: string
} & RouteComponentProps<{}>

const ReservationForm: React.FC<Props> = (props) => {
  const { startAt, customerId, customerCourse, customerEmail } = props

  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const create = async () => {
    if (customerCourse === "FCI") {
      // FCI顧客がこのページに来ることは想定されていない
      setErrorMessage(
        [
          "外用薬をご希望のお客様はこのページから申し込みを行うことはできません。",
          "ページ下部の電話番号かLINEにてお問い合わせください。",
        ].join("")
      )
      return
    }

    const headers = createRequestHeadersForCors()

    const createCounselingByDoctor = async () =>
      fetch(`/api/customers/${customerId}/counselings`, {
        method: "POST",
        body: JSON.stringify({
          reservation_at: startAt.toISOString(),
          type: "counseling_by_doctor",
        }),
        headers,
      })

    const createMedicalExamination = async () =>
      fetch(`/api/customers/${customerId}/medical_examinations`, {
        method: "POST",
        body: JSON.stringify({
          reservation_at: startAt.toISOString(),
          examination_type: "examination",
        }),
        headers,
      })

    let reservationId: string
    try {
      const response =
        customerCourse === "counseling_by_doctor"
          ? await createCounselingByDoctor()
          : await createMedicalExamination()
      const json = await response.json()

      if (!response.ok) {
        setErrorMessage(`予約の作成に失敗しました。${json.messages.join("")}`)
        setSubmitting(false)
        return
      }

      reservationId = json.id
    } catch (e) {
      setErrorMessage(
        "サーバー側での予期せぬエラーにより予約の作成に失敗しました。"
      )
      setSubmitting(false)
      return
    }

    // 歴史的経緯よりiframe内で表示される可能性があるためwindow.topを使用
    // NOTE: iframe内で表示されないことが確実であっても、windowをそのまま使用すると
    // NOTE: mswとjest.Mockの組み合わせがうまく動作しない可能性があるので修正時は要確認
    const topWindow = window.top
    if (!topWindow) return

    // FacebookのCV確認時に警告が出るため文字列を置き換え (mailを命名に使用しない)
    const cvTag = customerEmail.replace(/@/g, "__at__")

    const completeParams = {
      cv_tag: cvTag,
      reservation_id: reservationId,
      reservation_at: startAt.toISOString(),
      customerId,
      type:
        customerCourse === "counseling_by_doctor"
          ? "counseling_by_doctor"
          : "medical_examination",
    }
    const completeQuery = Object.entries(completeParams)
      .map(([k, v]) => `${k}=${v ? encodeURIComponent(v) : ""}`)
      .join("&")

    // LP予約完了ページへ遷移
    // NOTE: テストのしやすさのためlocatioin.hrefではなくlocation.assignを使用
    topWindow.location.assign(`${LP_COMPLETE_URL}?${completeQuery}`)
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (submitting) {
      return
    }

    setSubmitting(true)
    create()
  }

  return (
    <ReservationFormButton
      onSubmitHandler={onSubmitHandler}
      errorMessage={errorMessage}
      submitting={submitting}
    />
  )
}

export default withRouter(ReservationForm)
