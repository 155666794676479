import React, { useEffect, useState } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Table } from "reactstrap"

type Props = {} & RouteComponentProps<{ prescriptionKey: string }>

type Summary = {
  spec: string
  dosage: string
}

const PrescriptionReceiptPage: React.FC<Props> = (props) => {
  const {
    match: { params },
  } = props
  const [customerName, setCustomerName] = useState<string | null>()
  const [price, setPrice] = useState<string | null>()
  const [issueDate, setIssueDate] = useState<string | null>()
  const [summaries, setSummaries] = useState<Array<Summary>>([])

  useEffect(() => {
    ;(async () => {
      const response = await fetch(
        `/api/prescriptions/${params.prescriptionKey}`
      )
      const json = await response.json()
      setCustomerName(json["customer"]["name"])
      const price = json["amount"]
      setPrice(`${price}円`)
      const date = new Date(json["purchased_at"])
      setIssueDate(
        `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
      )
      setSummaries(json["summaries"])
    })()
  }, [params])

  return (
    <>
      <h2 className="text-center">領収書</h2>
      <p className="text-right">発行日: {issueDate}</p>
      <h3 className="text-right">{customerName}様</h3>
      <br />
      <p>但 お薬代として</p>
      <p>上記正に領収いたしました。</p>
      <br />
      <Table>
        <thead>
          <tr>
            <th>品名</th>
            <th>数量</th>
          </tr>
        </thead>
        <tbody>
          {summaries.map((summary: Summary, index) => {
            return (
              <tr key={index}>
                <th scope="row">{summary.spec}</th>
                <td>{summary.dosage}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <br />
      <h5 className="text-right">合計: {price}</h5>
      <br />
      <p className="font-weight-bold">AGAスマクリ</p>
      <p>〒150-0022</p>
      <p>東京都 渋谷区 恵比寿南 1-4-15 恵比寿銀座クロスビル3階</p>
      <p>TEL: 03-6630-5214</p>
      <p>Email: aga.smart.clinic@gmail.com</p>
    </>
  )
}

export default withRouter(PrescriptionReceiptPage)
