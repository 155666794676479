import React from "react"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { RouteComponentProps } from "react-router-dom"

type Props = {
  starttime: Date
} & RouteComponentProps<{}>

type Params = {
  starttime: string
}

const ThanksFollowupPage: React.FC<Props> = (props) => {
  return (
    <Row className="mt-4">
      <Col
        sx={12}
        sm={{ size: 10, offset: 1 }}
        md={{ size: 8, offset: 2 }}
        lg={{ size: 6, offset: 3 }}
      >
        <Card>
          <CardBody className="text-center">
            <CardTitle tag="h5" className="font-weight-bold text-blue mb-3">
              ご投稿いただきありがとうございます
            </CardTitle>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ThanksFollowupPage
