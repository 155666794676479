import React from "react"
import images from "../../helpers/ui-helpers/ImageSources"
import { Card, CardBody, CardHeader } from "reactstrap"

const LineAccountCard = () => (
  <Card className="card-line mb-4 mt-5">
    <CardHeader className="py-1">
      <div className="text-center h5 mb-0 py-1">＼公式LINEが便利／</div>
    </CardHeader>
    <CardBody>
      <p className="mb-0 text-center font-weight-bold">
        LINEを登録すると
        <br />
        予約変更やお問い合わせが
        <br />
        LINEから可能になります
      </p>
      <a href="https://lin.ee/QdPkRmI" className="line-link d-block mt-3">
        <div className="d-flex align-items-center">
          <img src={images.iconLine} alt="LINE" />
          <p className="ml-3 mb-0 font-weight-bold">LINEの登録はこちらから</p>
        </div>
      </a>
    </CardBody>
  </Card>
)

export default LineAccountCard
