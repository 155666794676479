import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Alert } from "reactstrap"

type Props = {} & RouteComponentProps<{}>

function validateLength(str, length) {
  if (str.length > length) {
    return false
  }
  return true
}

function sanitize(str) {
  return str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")
}

const FirstExaminationInputPage: React.FC<Props> = (props) => {
  const { history } = props
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  let radioType = ""

  const searchParams = new URLSearchParams(window.location.search)
  const allianceParam = searchParams.get("alliance_note") as string

  const createMedicalExamination = () => {
    const headers = new Headers()
    headers.set("Accept", "application/json")
    headers.set("Content-Type", "application/json")
    headers.set("X-Requested-With", "XMLHttpRequest")
    const csrfToken = (document
      .getElementsByName("csrf-token")
      .item(0) as HTMLMetaElement).content
    headers.set("Content-Type", "application/json")
    headers.set("X-CSRF-Token", csrfToken)

    let nameTmp = ""
    let phoneTmp = ""
    let emailTmp = ""
    let allianceTmp = ""
    let course = radioType === "examination" ? "immediate" : "counseling"
    nameTmp = (document.getElementById("input-name") as HTMLInputElement).value
    phoneTmp = (document.getElementById("input-phone") as HTMLInputElement)
      .value
    emailTmp = (document.getElementById("input-mail") as HTMLInputElement).value

    if (allianceParam) {
      allianceTmp = (document.getElementById(
        "input-alliance"
      ) as HTMLInputElement).value
    }
    nameTmp = sanitize(nameTmp)
    phoneTmp = sanitize(phoneTmp)
    emailTmp = sanitize(emailTmp)
    course = sanitize(course)

    if (
      !validateLength(nameTmp, 50) ||
      !validateLength(phoneTmp, 11) ||
      !validateLength(emailTmp, 100) ||
      !validateLength(course, 30)
    ) {
      setErrorMessage(
        "お名前５０文字以内/電話番号(半角)１１文字以内/メールアドレス(半角)１００文字以内で入力してください"
      )
      setSubmitting(false)
      return
    }

    if (!nameTmp || !phoneTmp || !emailTmp) {
      setErrorMessage(
        "お名前/電話番号(半角)/メールアドレス(半角)は全て入力してください"
      )
      setSubmitting(false)
      return
    }

    const searchParams = new URLSearchParams(window.location.search)
    const sourceVal = searchParams.get("source")
    if (radioType && radioType === "counseling") {
      fetch(`/api/customers`, {
        method: "POST",
        body: JSON.stringify({
          name: nameTmp,
          phone: phoneTmp,
          email: emailTmp,
          source: sourceVal,
          alliance_note: allianceTmp,
          type: "counseling",
          course: course,
        }),
        headers,
      })
        .then((response) => {
          setSubmitting(false)
          response
            .json()
            .then((res) => {
              if (res.status === 200) {
                if (res.env === "dev") {
                  history.push(
                    `/customer_schedule_first_immediate_counselings/${res.id}`
                  )
                } else {
                  window.location.href = `/customer_schedule_first_immediate_counselings/${res.id}`
                }
                return
              }

              if (res.status === 202) {
                if (res.env === "dev") {
                  history.push(
                    `/customer_schedule_first_immediate_counselings/${res.id}`
                  )
                } else {
                  window.location.href = `/customer_schedule_first_immediate_counselings/${res.id}`
                }
                return
              }
              if (res.status >= 400) {
                setErrorMessage(`予約の作成に失敗しました。${res.messages}`)
                return
              }
              setErrorMessage(
                "予約の作成に失敗しました。時間枠を変更して再度予約をお願いします。"
              )
            })
            .catch((e: Error) => {
              setSubmitting(false)
              setErrorMessage(e.message)
            })
        })
        .catch((e: Error) => {
          setSubmitting(false)
          setErrorMessage(e.message)
        })
    } else if (radioType && radioType === "examination") {
      // ユーザーが作成済みであれば、そのユーザー情報を元にWEB問診へ遷移する。
      fetch(`/api/customers`, {
        method: "POST",
        body: JSON.stringify({
          name: nameTmp,
          phone: phoneTmp,
          email: emailTmp,
          source: sourceVal,
          alliance_note: allianceTmp,
          type: "examination",
          course: course,
        }),
        headers,
      })
        .then((response) => {
          setSubmitting(false)
          response
            .json()
            .then((res) => {
              if (res.status === 200) {
                if (res.env === "dev") {
                  history.push(
                    `/customer_schedule_first_immediate_medical_examinations/${res.id}`
                  )
                } else {
                  window.location.href = `/customer_schedule_first_immediate_medical_examinations/${res.id}`
                }
                return
              }

              if (res.status === 202) {
                if (res.env === "dev") {
                  history.push(
                    `/customer_schedule_first_immediate_medical_examinations/${res.id}`
                  )
                } else {
                  window.location.href = `/customer_schedule_first_immediate_medical_examinations/${res.id}`
                }
                return
              }
              if (res.status >= 400) {
                setErrorMessage(`予約の作成に失敗しました。${res.messages}`)
                return
              }
              setErrorMessage(
                "予約の作成に失敗しました。時間枠を変更して再度予約をお願いします。"
              )
            })
            .catch((e: Error) => {
              setSubmitting(false)
              setErrorMessage(e.message)
            })
        })
        .catch((e: Error) => {
          setSubmitting(false)
          setErrorMessage(e.message)
        })
    }
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (submitting) {
      return
    }
    const radios = document.getElementsByName("examination-select")

    let flag = false // 選択されているか否かを判定するフラグ

    for (var i = 0; i < radios.length; i++) {
      const tmpRadio = radios[i] as HTMLInputElement
      if (tmpRadio.checked) {
        flag = true
        console.log(tmpRadio.value + "が選択されました。")
        radioType = tmpRadio.value
        break
      }
    }
    if (!flag) {
      console.log("項目が選択されていません。")
    }

    setSubmitting(true)
    createMedicalExamination()
  }

  const selectClickHandler = (e) => {
    const target = e.currentTarget
    target.children[0].children[0].checked = true
  }

  useEffect(() => {
    if (allianceParam) {
      let alliance: HTMLElement = document.getElementById(
        "input-alliance-label"
      ) as HTMLElement
      alliance.textContent = allianceParam
    }
    setErrorMessage(null)
    window.addEventListener("message", (event) => {
      if (event.data === "get-height" && event.source !== null) {
        event.source.postMessage(
          window.document.body.scrollHeight,
          // @ts-ignore
          event.origin
        )
      }
    })
  }, [])

  let submitText = "無料予約する"
  const sourceVal = searchParams.get("source") as string
  const sourceUrl = new URL(sourceVal)
  if (sourceUrl.pathname === "/ali/vit/") {
    submitText = "日程確認に進む"
  }

  return (
    <div className="background-first-examination-input">
      <form id="form-LP" onSubmit={onSubmitHandler}>
        <div className="term container-first-examination" id="app-term">
          <div className="m-2">
            <label className="mr-3 display-block">お名前</label>
            <input
              type="text"
              id="input-name"
              className="form-control input-text-style"
              placeholder="佐藤 太郎"
              size={50}
              maxLength={50}
              required
            ></input>
          </div>
          <div className="m-2">
            <label className="mr-3 display-block">電話番号(半角)</label>
            <input
              type="tel"
              id="input-phone"
              className="form-control input-text-style"
              placeholder="08012345678"
              size={50}
              maxLength={20}
              required
            ></input>
          </div>
          <div className="m-2">
            <label className="mr-3 display-block">メールアドレス(半角)</label>
            <input
              type="email"
              id="input-mail"
              className="form-control input-text-style"
              placeholder="agasumakuri@gmail.com"
              size={50}
              maxLength={100}
              required
            ></input>
          </div>
          {allianceParam && (
            <div className="m-2">
              <label
                className="mr-3 display-block"
                id="input-alliance-label"
              ></label>
              <input
                type="text"
                id="input-alliance"
                className="form-control input-text-style"
                size={50}
                maxLength={300}
                required
              ></input>
            </div>
          )}
          <div className="text-center m-2">
            お客様のご希望に合うほうをお選びください。
          </div>
          <div className="check-border" onClick={selectClickHandler}>
            <div className="m-2 ml-5 display-radio-sentense-LP">
              <input
                type="radio"
                name="examination-select"
                className="radio-LP"
                value="counseling"
                defaultChecked={true}
              ></input>
              <label className="ml-4 display-inline">
                AGA治療は初めてなのでしっかり説明を聞きたい
              </label>
            </div>
            <div className="m-2">
              <div className="explanation">
                薬でのAGA治療がはじめての方、より詳しく治療内容を知りたい方におすすめです
              </div>
            </div>
          </div>
          <div className="check-border" onClick={selectClickHandler}>
            <div className="m-2 ml-5 display-radio-sentense-LP">
              <input
                type="radio"
                name="examination-select"
                className="radio-LP"
                value="examination"
              ></input>
              <label className="ml-4 display-inline">
                AGA治療や薬については十分に理解しているので最短最速で薬が欲しい
              </label>
            </div>
            <div className="m-2">
              <div className="explanation">
                すでに他社への通院経験がある方や薬についてよく調べている方におすすめです
              </div>
            </div>
          </div>
          {errorMessage && <Alert color="warning">{errorMessage}</Alert>}
          <div className="m-2 text-center height-50">
            <button
              type="submit"
              className="submit-button-LP height-50"
              name="action"
              value="無料予約する"
            >
              {submitText}
            </button>
          </div>
        </div>
      </form>
      <br />
      <br />
    </div>
  )
}

export default withRouter(FirstExaminationInputPage)
