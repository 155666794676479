import React, { useState, useEffect } from "react"
import { Col, Row } from "reactstrap"
import moment from "moment"

import SelectDate from "./SelectDate"
import SchedulableDate from "./SchedulableDate"
import type { ScheduleType } from "../../types"
import {
  SchedulableDate as SchedulableDateModel,
  SchedulableTime,
} from "../../types"

type Props = {
  type: ScheduleType
  onSelectTime: (date: Date) => void
}

type SchedulableDateResponse = {
  schedulable_times: any[]
}

const SelectSchedule: React.FC<Props> = (props) => {
  const { type } = props
  const [currentDate, setCurrentDate] = useState<Date>(
    moment().startOf("day").toDate()
  )
  const [schedulableDates, setSchedulableDates] = useState<
    SchedulableDateModel[]
  >([])

  const refresh = () => {
    ;(async () => {
      const res = await fetch(
        `/api/schedulable_times?type=${type}&date=${moment(currentDate).format(
          "YYYY-MM-DD"
        )}`
      )
      const json = (await res.json()) as SchedulableDateResponse
      const newSchedulableDates = json.schedulable_times.map(
        buildSchedulableDate
      )
      setSchedulableDates(newSchedulableDates)
    })()
  }

  const buildSchedulableDate = (data: any) => {
    const times = data.times.map((time) => buildSchedulableTime(time))
    return {
      date: data.date,
      times: times,
    } as SchedulableDateModel
  }

  const buildSchedulableTime = (data: any) => {
    const startAt = moment(data.start_at).toDate()
    const endAt = moment(data.end_at).toDate()
    return {
      startAt: startAt,
      endAt: endAt,
      isSchedulable: data.is_schedulable,
    } as SchedulableTime
  }

  const onChangeDate = (date) => {
    setCurrentDate(date)
  }

  const renderTitle = () => {
    const startDate = moment(currentDate)
    const endDate = moment(currentDate).add(1, "week")

    if (startDate.year() !== endDate.year()) {
      return `${startDate.format("YYYY年M月")} - ${endDate.format(
        "YYYY年M月"
      )} `
    }
    if (startDate.month() !== endDate.month()) {
      return `${startDate.format("YYYY年M月")} - ${endDate.format("M月")} `
    }
    return startDate.format("YYYY年M月")
  }

  useEffect(() => {
    refresh()
  }, [currentDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderSchedulableTimes = () => {
    const schedules = schedulableDates.map((schedulableDate) => {
      return (
        <SchedulableDate
          key={schedulableDate.date}
          type={type}
          schedulableDate={schedulableDate}
          onChange={refresh}
          onSelectTime={props.onSelectTime}
        />
      )
    })

    return (
      <Row>
        <Col
          md={{ size: 6, offset: 3 }}
          className="d-none d-md-block text-center"
        >
          <h4>{renderTitle()}</h4>
        </Col>
        <br />
        <Col md={12} className="mt-4">
          <div className="d-md-flex">{schedules}</div>
        </Col>
        <Col xs={12} className="date-picker-fix">
          <SelectDate date={currentDate} onChangeDate={onChangeDate} />
        </Col>
      </Row>
    )
  }

  return renderSchedulableTimes()
}

export default SelectSchedule
