import React from "react"

import { SchedulableDate as SchedulableDateModel } from "../../types"
import DateUnable from "./DateUnable"
import DateVacant from "./DateVacant"

type Props = {
  schedulableDate: SchedulableDateModel
  onChange: () => void
  onSelectTime: (date: Date) => void
}

const SchedulableDateCounseling: React.FC<Props> = (props) => {
  const { schedulableDate } = props

  return (
    <div className="text-center font-weight-bold">
      {schedulableDate.times?.map((schedulableTime) => (
        <div key={schedulableTime.startAt.toISOString()}>
          {schedulableTime.isSchedulable ? (
            <DateVacant
              schedulableTime={schedulableTime}
              onSelectTime={props.onSelectTime}
            />
          ) : (
            <DateUnable />
          )}
        </div>
      ))}
    </div>
  )
}

export default SchedulableDateCounseling
