import React, { useState, useEffect } from "react"
import { Alert, Button, Form } from "reactstrap"
import { withRouter, RouteComponentProps, useLocation } from "react-router-dom"
import moment from "moment"

import { Reservation } from "./types"

type Props = {
  customerId: string
  startAt: Date
} & RouteComponentProps<{}>

const ReservationForm: React.FC<Props> = (props) => {
  const { customerId, startAt, history } = props
  const [reservation, setReservation] = useState<Reservation>({
    startAt: new Date(),
  } as Reservation)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const search = useLocation().search
  const query = new URLSearchParams(search)
  const intermediateMedicalExaminationId = query.get(
    "intermediateMedicalExaminationId"
  )
  const createMedicalExamination = () => {
    const headers = new Headers()
    headers.set("Accept", "application/json")
    headers.set("Content-Type", "application/json")
    headers.set("X-Requested-With", "XMLHttpRequest")

    fetch(
      `/api/customers/${customerId}/intermediate_medical_examinations/${intermediateMedicalExaminationId}`,
      {
        method: "PUT",
        body: JSON.stringify({
          reservation_at: reservation.startAt.toISOString(),
          examination_type: "examination",
        }),
        headers,
      }
    ).then((response) => {
      setSubmitting(false)
      if (response.ok) {
        history.push(
          `/thanks_intermediate_medical_examination_appointment/${moment(
            reservation.startAt.toISOString()
          ).format("YYYYMMDDHHmm")}`
        )
        return
      }
      if (response.status === 422) {
        response.json().then((json) => {
          setErrorMessage(`予約の作成に失敗しました。${json.messages.join("")}`)
        })
        return
      }
      setErrorMessage(
        "予約の作成に失敗しました。時間枠を変更して再度予約をお願いします。"
      )
    })
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (submitting) {
      return
    }

    setSubmitting(true)
    createMedicalExamination()
  }

  useEffect(() => {
    setErrorMessage(null)
    setReservation({
      startAt: startAt,
    } as Reservation)
  }, [startAt])

  return (
    <Form className="mt-4" onSubmit={onSubmitHandler}>
      {errorMessage && <Alert color="warning">{errorMessage}</Alert>}
      <Button
        type="submit"
        color="main"
        block
        className="mt-4"
        disabled={submitting}
      >
        予約確定
      </Button>
    </Form>
  )
}

export default withRouter(ReservationForm)
