// cf. https://v5.reactrouter.com/web/guides/scroll-restoration/scroll-to-top

import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default ScrollToTop
