import React, { useState, useEffect } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import SelectSchedule from "../SelectSchedule"
import { Customer, ScheduleType } from "../../types"
import moment from "moment"

type Props = {} & RouteComponentProps<{
  counselingId: string
  customerId: string
}>

const OverwriteCounselingPage: React.FC<Props> = (props) => {
  const {
    history,
    match: { params },
  } = props

  const [customer, setCustomer] = useState<Customer | null>(null)

  const onSelectTimeHandler = (date: Date) => {
    history.push(
      `/customer_schedule_counselings/overwrite/${params.counselingId}/${
        params.customerId
      }/${moment(date).format("YYYYMMDDHHmm")}${window.location.search}`
    )
  }

  useEffect(() => {
    ;(async () => {
      const response = await fetch(`/api/customers/${params.customerId}`)
      const customer = await response.json()
      setCustomer(customer)
    })()
  }, [params.customerId])

  if (customer === null) {
    return null
  }

  let type: ScheduleType
  let leadParagraphText: string
  if (customer.course === "FCI") {
    type = ScheduleType.fci
    leadParagraphText =
      "ご希望のカウンセリング開始時刻をクリックすると、お客様情報の入力画面に切り替わります。"
  } else if (customer.course === "counseling_by_doctor") {
    type = ScheduleType.counselingByDoctor
    leadParagraphText =
      "ご希望の診療開始時刻をクリックすると、お客様情報の入力画面に切り替わります。"
  } else {
    type = ScheduleType.counseling
    leadParagraphText =
      "ご希望のカウンセリング開始時刻をクリックすると、お客様情報の入力画面に切り替わります。"
  }

  return (
    <>
      <div className="term" id="app-term">
        <div className="text-center mt-4">{leadParagraphText}</div>
      </div>
      <br />
      <br />
      <SelectSchedule type={type} onSelectTime={onSelectTimeHandler} />
    </>
  )
}

export default withRouter(OverwriteCounselingPage)
