import { Reservation, ValidationError } from "./types"

const reservationValidation = (reservation: Reservation) => {
  let errMessages: ValidationError = {}
  if (reservation.name.length === 0) {
    errMessages["reservation.name"] = "名前を入力してください"
  }
  if (reservation.email.length === 0) {
    errMessages["reservation.email"] = "メールアドレスを入力してください"
  } else if (
    !/^([a-zA-Z0-9])+([a-zA-Z0-9._+-])*@((?:[-a-z0-9]+\.)+[a-z]{2,})$/.test(
      reservation.email
    )
  ) {
    errMessages["reservation.email"] = "メールアドレスのフォーマットが不正です"
  }
  if (reservation.phone.length === 0) {
    errMessages["reservation.phone"] = "電話番号を入力してください"
  } else if (!/^0[0-9]{9,10}$/.test(reservation.phone)) {
    errMessages["reservation.phone"] = "電話番号のフォーマットが不正です"
  }
  if (!reservation.agreeTerms) {
    errMessages["reservation.agreeTerms"] =
      "利用規約及びプライバシーポリシーに同意して下さい"
  }

  return errMessages
}

const validation = (reservation: Reservation) => {
  return {
    ...reservationValidation(reservation),
  } as ValidationError
}

export default validation
